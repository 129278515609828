import React, { useCallback, useEffect, useState } from 'react'
import { Backdrop, Box, Button, CircularProgress, Grid, Modal, Tooltip, Typography } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AttachMoney, Info } from '@mui/icons-material';
import { GetApi, GetApiParam, postApi } from '../../Api/Api.js';
import { OFFER_STATUS, INVESTMENT_TYPE, TRANSACTION_STATUS, INVESTMENT_TYPE_ID, TRANSACTION_TYPE, CAPITAL_TYPE_ID, CATEGORY_TYPE_ID, SUB_CATEGORY_TYPE_ID, CONTRACT_NAME } from '../../constants.js'
import { calculateEMI } from '../../Components/SmartContract/calculateEMI.js';
import { useSnackbar } from '../../Contexts/SnackbarContext.jsx';
import Sale from './PaymentTypeComponents/Sale.jsx'
import Collateral from "./PaymentTypeComponents/Collateral.jsx"
import Fractionalize from "./PaymentTypeComponents/Fractionalize.jsx"
import Parcel from "./PaymentTypeComponents/Parcel.jsx"
import Documents from '../../ProjectDetails/Documents.jsx';
import Terms from './MicroComponents/Terms'
import FaqsAccordions from './MicroComponents/FaqsAccordions'
import PaymentMethod from './MicroComponents/PaymentMethod'
import IndentityKyc from './MicroComponents/IndentityKyc.jsx';
import ParcelEditPage from './Parceling/ParcelEditPage.jsx'
import CapitalInfoInvestor from './MicroComponents/CapitalInfoInvestor.jsx';
import SignDocuments from './SignDocuments.jsx';
import TransactionStatusModal from './TransactionStatusModal';
import InvestorRelatedModal from './InvestorRelatedModal.jsx';
import LoanInformation from './MicroComponents/LoanInformation.jsx';
import TransactionProgressModal from '../../Components/SmartContract/TransactionProgressModal.jsx';
import CreateTokenImage from '../../Components/Common/CreateTokenImage.jsx';
import SaleInformation from './MicroComponents/SaleInformation.jsx';
import { formatNumber } from '../../Components/Common/USFormat.js';
import DemoSignDocuments from './DemoSignDocuments.jsx';
import "./Investor.css"
import { GetContractAddress } from '../../Components/Common/GetContractAddress.js';
import convertFromDecimal from '../../Components/Common/convertFromDecimal.js';
import { getDecimalFromAddress } from '../../Components/Common/getDecimalFromAddress.js';

const PaymentPage = () => {
    const query = useParams();
    const navigate = useNavigate();
    const PaymentComponentRef = React.useRef(null);
    const { state } = useLocation();
    const { showSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [assetData, setAssetData] = useState(null)
    const [amountToPay, setAmountToPay] = useState(0)
    const [loanDurationIn, setLoanDurationIn] = useState(0)
    const [interestRateIn, setInterestRateIn] = useState(0)
    const [signDocs, setSignDocs] = useState({ open: false, documentsData: [], navigateBack: true, isFromOffer: false });
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [feeTokenType, setFeeTokenType] = useState('USDT');
    const [paymentMethod, setPaymentMethod] = useState('crypto');
    const [uploadedDocumentFiat, setUploadedDocumentFiat] = useState([]);
    const [signedDoc, setSignedDoc] = useState([]);
    const [selectedCapitalId, setSelectedCapitalId] = useState(1) //for the use when Fractionalize is there
    const [showIndication, setShowIndication] = useState(false);
    const [alreadyFundedAmounts, setAlreadyFundedAmounts] = useState(0);
    const [remainingFundedAmounts, setRemainingFundedAmounts] = useState(0);

    // hold state of transaction modal
    const [openTransactionModalOffer, setOpenTransactionModalOffer] = useState(false);
    const [openModalFullPayment, setOpenModalFullPayment] = useState(false);
    const [openModalWithdrawalSale, setOpenModalWithdrawalSale] = useState(false);
    const [openWithdrawAmountParcel, setOpenWithdrawAmountParcel] = useState(false);
    const [openWithdrawAmountSPV, setOpenWithdrawAmountSPV] = useState(false);
    const [openWithdrawAmountSPVDebt, setOpenWithdrawAmountSPVDebt] = useState(false);
    const [openWithdrawAmountFunds, setOpenWithdrawAmountFunds] = useState(false);
    const [openModalRemainingPayment, setOpenModalRemainingPayment] = useState(false);
    const [openModalBuyParcel, setOpenModalBuyParcel] = useState(false);
    const [openModalCreateEMI, setOpenModalCreateEMI] = useState(false);
    const [openTransactionModalChanging, setOpenTransactionModalChanging] = useState(false);
    const [makeOfferPayload, setMakeOfferPayload] = useState(null);
    const [openAcceptModal, setOpenAcceptModal] = useState(null);
    const [openGiveFundToken, setOpenGiveFundToken] = useState(null);
    const [transactionPayloadData, setTransactionPayloadData] = useState(null);
    const [tokenImageData, setTokenImageData] = useState(null);
    const [emiData, setEmiData] = useState(null);
    const [openBackdropModal, setOpenBackdropModal] = useState(false);
    const [isInvestmentAllow, setIsInvestmentAllow] = useState(true);
    const [isKycDone, setIsKycDone] = useState(false);
    const [disableCondition, setDisableCondition] = useState(false);

    /**
     * This state is used to know that the state of user is offer or not
     */
    const [isOffer, setIsOffer] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (!signDocs?.open) {
            setFeeTokenType('USDT')
            if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS)
                setPaymentMethod('fiat')
        }
    }, [signDocs?.open])

    useEffect(() => {
        if (signedDoc?.length > 0 && signedDoc?.some((elmts) => elmts.status === 2)) {
            setDisableCondition(true);
        }
    }, [signedDoc])

    useEffect(() => {
        async function getIsInvestmentAllowData(asset_id, user_id) {
            try {
                setOpenBackdropModal(true)
                const res = await GetApiParam("/invest/isInvestmentAllow", { asset_id, user_id });
                if (res?.data?.code == 200) {
                    setIsInvestmentAllow(res.data?.status);
                }
                setOpenBackdropModal(false);
            } catch (error) {
                setOpenBackdropModal(false)
                showSnackbar('Error in fetching asset details', 'error')
            }
        }

        async function checkKyc() {
            try {
                let u = localStorage.getItem('user_data')
                u = JSON.parse(u)
                const res = await GetApi(`user/getUser/${u?.id}`);
                // kyc status
                if (res?.data && !res?.data?.data?.kyc_status) {
                    setIsKycDone(false)
                    return false;
                } else {
                    setIsKycDone(true)
                    return true;
                }

            } catch (error) {
                return false;
            }
        }

        async function getSingleAssetData(asset_id) {
            try {
                setOpenBackdropModal(true)
                const res = await postApi("/proposer/assetsDetails", { asset_id });
                if (res?.data?.data) {
                    setAssetData(res.data.data[0]);
                    setOpenBackdropModal(false)
                    let category_id = res.data.data[0]?.category_id
                    let investment_type_id = res.data.data[0]?.investment_type_id
                    let asset_sub_category = res.data.data[0]?.asset_sub_category
                    if (category_id === CATEGORY_TYPE_ID.FUNDS || category_id === CATEGORY_TYPE_ID.REAL_ESTATE || investment_type_id === INVESTMENT_TYPE_ID.LOAN || investment_type_id === INVESTMENT_TYPE_ID.PARCEL || investment_type_id === INVESTMENT_TYPE_ID.SALE) {
                        if (state?.from === 'offer-page') {
                            setSignDocs({ ...signDocs, navigateBack: false })
                        } else if (!state?.from) {
                            setSignDocs({ ...signDocs, navigateBack: false, fundsBeforeSinging: true })
                            if (asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
                                setPaymentMethod('fiat')
                            }
                        }
                    }

                    if (!state?.from && category_id === CATEGORY_TYPE_ID.FUNDS) {
                        getIsInvestmentAllowData(state.assetId, user_data.id)
                    }
                }
            } catch (error) {
                setOpenBackdropModal(false)
                showSnackbar('Error in fetching asset details', 'error')
            }
        }

        // local user data
        let user_data = localStorage.getItem('user_data')
        user_data = JSON.parse(user_data)

        if (state?.assetId && user_data?.id) {
            setTimeout(() => {
                getSingleAssetData(state.assetId)
                checkKyc();
            }, 100);
        } else {
            // if state is not present then navigate to home page because this page is not independently loaded
            navigate('/')
        }

    }, [state])

    useEffect(() => {
        if (state && assetData) {
            setOpenBackdropModal(false)
            // if state is present and the page navigated from Offers Panel then show sign docs page
            if (state?.from === 'offer_accept') {
                if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
                    // setMakeOfferPayload({ ...assetData, blockchainOfferId: state?.blockchainOfferId });
                    setOpenBackdropModal(true);
                    setSignDocs({ ...signDocs, open: true, documentsData: state?.documentsData, isFromOffer: true, navigateBack: false })
                } else {
                    setOpenBackdropModal(true);
                    setSignDocs({ ...signDocs, open: true, documentsData: state?.documentsData, isFromOffer: true, navigateBack: false })
                }
            } else if (state?.from === 'pending_documents') {
                setSignDocs({ ...signDocs, open: true, documentsData: state?.documentsData, isFromOffer: true, navigateBack: false })
            } else if (state?.from === 'offer-page') {
                setOpenBackdropModal(true);
                setMakeOfferPayload({ ...assetData, val: state?.val, offer_status: OFFER_STATUS.ACCEPTED, tokenImageData })
                setSignDocs({ ...signDocs, open: true, documentsData: state?.documentsData, isFromOffer: true, navigateBack: false, from: 'offer-page' })
                if (state?.val && state?.val?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
                    calculateEMIData(state?.val);
                }
            } else if (state?.from === 'direct-buy') {
                setMakeOfferPayload({ ...assetData });
                setSignDocs({ ...signDocs, open: true, documentsData: state?.documentsData, isFromOffer: false, navigateBack: false, is_direct_buy: true })
            } else if (state?.from === 'parcel-buy') {
                setMakeOfferPayload({ ...assetData });
                setSignDocs({ ...signDocs, open: true, documentsData: state?.documentsData, isFromOffer: false, navigateBack: false, parcel_buy: true })
            } else if (state?.from === 'edit-offer') {
                if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
                    setSignDocs({ ...signDocs, open: true, documentsData: state?.documentsData, isEditOffer: true, navigateBack: false })
                    setMakeOfferPayload({ ...assetData, editedData: state?.editedData, offerDetails: state?.offerDetails, feeTokenType: state?.feeTokenType })
                } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
                    setSignDocs({ ...signDocs, open: true, documentsData: state?.documentsData, isEditOffer: true, navigateBack: false })
                    setMakeOfferPayload({ ...assetData, editedData: state?.editedData, offerDetails: state?.offerDetails, feeTokenType: state?.feeTokenType })
                }
            } else if (state?.from === "equity-amount") {
                setMakeOfferPayload({ capital_type_id: CAPITAL_TYPE_ID.EQUITY, ...assetData });
                setSignDocs({ ...signDocs, open: true, documentsData: state?.documentsData, isFromOffer: false, navigateBack: false, equity: true, capital_type_id: CAPITAL_TYPE_ID.EQUITY })
            } else if (state?.from === "junior-amount") {
                setMakeOfferPayload({ capital_type_id: CAPITAL_TYPE_ID.JUNIOR_DEBT, ...assetData });
                setSignDocs({ ...signDocs, open: true, documentsData: state?.documentsData, isFromOffer: false, navigateBack: false, equity: true, capital_type_id: CAPITAL_TYPE_ID.JUNIOR_DEBT })
            } else if (state?.from === "senior-amount") {
                setMakeOfferPayload({ capital_type_id: CAPITAL_TYPE_ID.SENIOR_DEBT, ...assetData });
                setSignDocs({ ...signDocs, open: true, documentsData: state?.documentsData, isFromOffer: false, navigateBack: false, equity: true, capital_type_id: CAPITAL_TYPE_ID.SENIOR_DEBT })
            } else if (state?.from === "class-a" || state?.from === "class-b") {
                setMakeOfferPayload({ ...assetData });
                setSignDocs({ ...signDocs, open: true, documentsData: state?.documentsData, isFromOffer: false, navigateBack: false, class: state?.class })
            }

            // set isOffer state based on listing type coz some listing type does not have offer
            if (query.listing) {
                switch (query.listing) {
                    case INVESTMENT_TYPE.LOAN:
                        setIsOffer(true)
                        break;
                    case INVESTMENT_TYPE.SALE:
                        setIsOffer(false)
                        break;
                    case INVESTMENT_TYPE.PARCEL:
                        setIsOffer(false)
                        break;
                    case INVESTMENT_TYPE.FRACTION:
                        setIsOffer(true)
                        break;
                    default:
                        setIsOffer(true);
                        break
                }
            }

            if (state?.offerAmount) {
                getPayableAmount().then((a) => {
                    setAmountToPay(a)
                })
            }

            // if (assetData?.capital_info?.length > 0) {
            //     setSelectedCapitalId(assetData?.capital_info[0]?.capital_type?.id)
            // }
            if (state?.capital_type_id) {
                setSelectedCapitalId(parseInt(state?.capital_type_id))
            }
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, assetData])

    //parcels selection logic
    const [selectedParcelsArr, setSelectedParcelsArr] = useState([])
    const [showParcelSelectionPage, setShowParcelSelectionPage] = useState(false)

    const handleShowParcelSelectionPage = () => {
        setShowParcelSelectionPage(true)
    }

    const handleHideParcelSelectionPage = () => {
        setShowParcelSelectionPage(false)
    }

    const setSelections = (parcels) => {
        setSelectedParcelsArr(parcels)
    }

    // parcel selection logic end


    const changeAmount = useCallback((newValue) => {
        setAmountToPay(newValue);
        setMakeOfferPayload(pre => ({ ...pre, amount: newValue }));
    }, []);

    const changeDuration = useCallback((newValue) => {
        setLoanDurationIn(newValue);
        setMakeOfferPayload(pre => ({ ...pre, loan_duration: newValue }));
    }, []);

    const changeInterestRateLoan = useCallback((newValue) => {
        setInterestRateIn(newValue);
        setMakeOfferPayload(pre => ({ ...pre, loan_roi: newValue }));
    }, []);

    const changeClassType = useCallback((newValue) => {
        let data = { "class": newValue }
        setSignDocs(pre => ({ ...pre, data }))
    }, []);

    const changeSpvType = useCallback((newValue) => {
        let data = { capital_type_id: newValue }
        setSignDocs(pre => ({ ...pre, data }))
    }, []);

    /**
     * changes offer State 
     * @param {boolean} newvalue 
     */
    const changeOfferState = useCallback((newValue) => {
        setIsOffer(newValue)
    }, []);

    const allyProps = () => {
        return {
            assetTitle: assetData?.asset_title,
            ref: PaymentComponentRef,
            changeOfferState: changeOfferState,
            changeAmount: changeAmount,
            changeDuration: changeDuration,
            changeInterestRateLoan: changeInterestRateLoan,
            changeClassType: changeClassType,
            changeSpvType: changeSpvType,
        };
    };

    const PaymentComponents = {
        Sale: <Sale  {...allyProps()} assetId={assetData?.id} salePrice={assetData?.sale_price} depositAmount={assetData?.sale_deposit_amount} state={state} disableCondition={disableCondition} />,
        Loan: <Collateral  {...allyProps()} assetId={assetData?.id} loanPrice={assetData?.loan_amount} loanROI={assetData?.loan_roi} loanIop={assetData?.loan_iop} loanDuration={assetData?.loan_duration_month} minimum_investment={assetData?.loan_minimum_investment} state={state} disableCondition={disableCondition} />,
        Parcelling: <Parcel  {...allyProps()} assetId={assetData?.id} assetData={assetData} selectedParcelsArr={selectedParcelsArr} showParcelSelectionPage={handleShowParcelSelectionPage} state={state} disableCondition={disableCondition} />,
        Fractionalize: <Fractionalize  {...allyProps()} assetId={assetData?.id} assetData={assetData} setSelectedCapitalId={setSelectedCapitalId} selectedCapitalId={selectedCapitalId} capitalData={assetData?.capital_info} totalPrice={assetData?.fractionalize_total_price} state={state} disableCondition={disableCondition} paymentMethod={paymentMethod} />
    }

    const checkAllValidations = async () => {
        const data = await PaymentComponentRef.current?.getData();
        if (!data) { return false }
        return true;
    }
    /**
     * function to handle submit offer button
     * @param {string} checkCondition 
     * @returns void
     */
    const handleSubmitOffer = async (checkCondition) => {
        let payloadData = {};

        // get data from child component
        const data = await PaymentComponentRef.current?.getData();
        if (!data) {
            window.scrollTo({ //scrolled so that user knows that there is an error in a field
                top: 0,
                behavior: 'smooth'
            });
            showSnackbar('Ensure all fields are present and validated.', 'error')

            return
        }

        const checkKyc = async (url) => {
            try {
                let u = localStorage.getItem('user_data')
                u = JSON.parse(u)
                const res = await GetApi(`user/getUser/${u.id}`);
                // kyc status
                return !(res?.data && !res?.data?.data?.kyc_status);

            } catch (error) {
                return false;
            }
        }

        let isKycDone = await checkKyc();
        if (!isKycDone) {
            const element = document.getElementById('KYC-Check');

            if (element) {
                const offsetTop = element.offsetTop - 68;
                window.scrollTo({
                    top: offsetTop,
                    behavior: 'smooth',
                });
            }
            showSnackbar('Please complete your KYC first.', 'error')
            return;
        }

        if (paymentMethod == 'fiat' && uploadedDocumentFiat?.length == 0) {
            showSnackbar('Please upload investment proof documents', 'error')
            return;
        }

        if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS || assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE || assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN || assetData?.investment_type_id === INVESTMENT_TYPE_ID.PARCEL || assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
            if (signedDoc?.length > 0 && signedDoc?.some((elmts) => elmts.status != 2)) {
                showSnackbar('Please sign all the required document', 'error')
                return;
            }
        }

        if (!termsAccepted) {
            showSnackbar('Please accept Investment Terms', 'error')
            return;
        }

        if (checkCondition === 'confirm') {
            if ((assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE) && !state.offerId) {
                let amount = await getPayableAmount();
                setOpenModalFullPayment(true);
                setMakeOfferPayload({ ...assetData, feeTokenType, amount });
                // setSignDocs({ ...signDocs, open: true, data: data, isFullPayment: true, feeTokenType })
            } else if ((assetData?.investment_type_id === INVESTMENT_TYPE_ID.PARCEL) && !state.offerId) {
                let payment_type = getPaymentType();
                let amount = await getPayableAmount();
                let u = localStorage.getItem('user_data');
                u = JSON.parse(u);

                let transactionPayload = {
                    asset_id: assetData?.id,
                    add_asset_owner_id: assetData?.user_id,
                    contract_version: assetData?.contract_version,
                    user_id: u.id,
                    status: TRANSACTION_STATUS.SUCCESS,
                    amount,
                    listing_id: assetData?.listing_id,
                    offer_id: state.offerId ? state.offerId : null,
                    blockchainOfferId: state.blockchainOfferId ? state.blockchainOfferId : null,
                    transaction_type: payment_type,
                    investment_type_id: assetData?.investment_type_id,
                    parcel_ids: data?.parcel_id ? data?.parcel_id : [], // sending parcel ids 
                    priceArray: data?.priceArray ? data?.priceArray : [] // sending parcel ids 
                }
                setOpenModalBuyParcel(true);
                setMakeOfferPayload({ ...transactionPayload, feeTokenType });

                // setSignDocs({ ...signDocs, open: true, data: data, parcelBuy: true, feeTokenType })
            } else {
                setSignDocs({ ...signDocs, open: true, data: data, feeTokenType });
            }
        } else {
            // adding input data to payload
            Object.assign(payloadData, data)

            let u = localStorage.getItem('user_data');
            u = JSON.parse(u);
            // info to send to payload
            let info = {
                asset_id: assetData?.id,
                proposer_id: assetData?.user_id,
                investor_id: u.id,
                status: OFFER_STATUS.PENDING,
                listing_id: assetData?.listing_id,
                investment_type_id: assetData?.investment_type_id // investment type id to payload Data
            }
            Object.assign(payloadData, info)
            try {
                // getting payment type
                let payment_type = getPaymentType();

                if (payment_type === TRANSACTION_TYPE.DEPOSIT) {
                    // creating payload for create transaction api
                    let transactionPayload = {
                        asset_id: assetData?.id,
                        add_asset_owner_id: assetData?.user_id,
                        user_id: u.id,
                        status: TRANSACTION_STATUS.SUCCESS,
                        amount: data.depositAmount,//
                        offer_id: state.offerId ? state.offerId : null,
                        transaction_type: payment_type
                    }

                    payloadData.feeTokenType = feeTokenType;

                    setTransactionPayloadData(transactionPayload);
                    setMakeOfferPayload({ ...assetData, ...payloadData });
                    setOpenTransactionModalOffer(true);
                    // setSignDocs({ ...signDocs, open: true, data: data, isFullPayment: false });

                    // creating transaction for deposit amount
                    // const res = await postApi('/transaction/createTransaction', transactionPayload);
                    // if (res?.data?.code === 200) {
                    //     // after creating transaction , now creating offer
                    //     // adding transaction id to payload data 
                    //     if (res?.data?.data?.id) payloadData.transaction_id = res?.data?.data?.id;
                    //     const response = await postApi('/invest/offer', payloadData);
                    //     if (response?.data?.code === 200) {
                    //         setOpen(true);
                    //     }
                    // }
                } else {
                    if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
                        // setSignDocs({ ...signDocs, open: true, data: data });
                        // setMakeOfferPayload({ ...payloadData, feeTokenType });
                        let loanPayload = { ...assetData, ...payloadData, category_id: assetData?.category_id, feeTokenType, asset_sub_category: assetData?.asset_sub_category }
                        let isAllDocSigned = signedDoc?.length > 0 && signedDoc?.every((elmts) => elmts.status === 2);
                        if (isAllDocSigned) {
                            setMakeOfferPayload(loanPayload);
                            setOpenTransactionModalOffer(true);
                        }
                    } else if (assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                        // setMakeOfferPayload({ ...assetData, ...payloadData, category_id: assetData?.category_id, feeTokenType });
                        // setSignDocs({ ...signDocs, open: true, data: data });
                        let loanPayload = { ...assetData, ...payloadData, category_id: assetData?.category_id, feeTokenType }
                        let isAllDocSigned = signedDoc?.length > 0 && signedDoc?.every((elmts) => elmts.status === 2);
                        if (isAllDocSigned) {
                            setMakeOfferPayload(loanPayload);
                            setOpenTransactionModalOffer(true);
                        }
                    } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS) {
                        let fundsPayload = { ...assetData, ...payloadData, category_id: assetData?.category_id, feeTokenType, asset_sub_category: assetData?.asset_sub_category }
                        let isAllDocSigned = signedDoc?.length > 0 && signedDoc?.every((elmts) => elmts.status === 2);
                        if (isAllDocSigned) {
                            fundsPayload.payment_method = paymentMethod
                            // if (fundsPayload?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS && paymentMethod == 'fiat') {
                            //     const response = await postApi('/invest/offer', fundsPayload);
                            //     if (response?.data?.code === 200) {
                            //         setOpen(true);
                            //         let offerData = response?.data?.data
                            //         let formDataDocs = new FormData();
                            //         formDataDocs.append("document", uploadedDocumentFiat[0]);
                            //         formDataDocs.append("asset_id", offerData?.asset_id);
                            //         formDataDocs.append("user_id", offerData?.investor_id);
                            //         formDataDocs.append("offer_id", offerData?.id);
                            //         await postApi('invest/investmentApproval', formDataDocs);
                            //     }
                            // } else {
                            setMakeOfferPayload(fundsPayload);
                            setOpenTransactionModalOffer(true);
                            // setSignDocs({ ...signDocs, data: data });
                            // }
                        }
                    } else {
                        // const response = await postApi('/invest/offer', payloadData);
                        // if (response?.data?.code === 200) {
                        //     setOpen(true);
                        // }
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    const calculateEMIData = async (offerData) => {
        if (offerData?.offer_amount) {
            try {
                const response = await GetContractAddress({}, offerData?.asset_data?.contract_version, CONTRACT_NAME.CALCULATE_EMI);
                let contractAddress = null;
                let contractAbi = null;
                if (response && response.length) {
                    contractAddress = response[0].address
                    contractAbi = response[0].abi
                }
                const emi = await calculateEMI(contractAddress, contractAbi, offerData?.offer_amount, offerData?.loan_duration, offerData?.loan_roi, offerData?.asset_data?.loan_iop)
                setEmiData(emi)
            } catch (error) {
                showSnackbar("Error is creating token", 'error')
                navigate(`/project-details/${state?.assetId}`, { state: { from: 'payment-page' } })
            }
        }
    }
    /**
     * calculates payment type based on listing type and if its Offer
     * @returns {'Full' | 'Deposit'} payment type
     */
    const getPaymentType = () => {
        let payment_type = TRANSACTION_TYPE.FULL_PAYMENT
        switch (query.listing) {
            case INVESTMENT_TYPE.LOAN:
                payment_type = TRANSACTION_TYPE.LOAN_ALLOCATION;
                break;
            case INVESTMENT_TYPE.SALE:
                isOffer === false ? payment_type = TRANSACTION_TYPE.FULL_PAYMENT : payment_type = TRANSACTION_TYPE.DEPOSIT;
                break;
            case INVESTMENT_TYPE.PARCEL:
                payment_type = TRANSACTION_TYPE.FULL_PAYMENT;
                break;
            case INVESTMENT_TYPE.FRACTION:
                payment_type = TRANSACTION_TYPE.FULL_PAYMENT;
                break;
            default:
                payment_type = TRANSACTION_TYPE.FULL_PAYMENT;
                break
        }
        return payment_type;
    }

    /**
     * function to get payable amount
     * calculates payable amount based on asset type and listing type + any Offers which already funded the project
     * @return {Promise<number>} payable amount
     */
    const getPayableAmount = async () => {

        let amount = amountToPay;

        if (state?.from === 'offer_accept' || state?.from === 'pending_documents' || state?.from === "offer-page"
        ) {
            // if user is coming from offer accept page then calculate payable amount based on offer amount and asset type

            if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
                amount = state.offerAmount - parseFloat(assetData.sale_deposit_amount);
            } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
                let alreadyFundedAmount = 0;
                await GetApiParam('/invest/getAssetFundedAmount', { "asset_id": assetData?.id }).then((res) => {
                    if (res?.data?.code === 200) {
                        alreadyFundedAmount = res?.data?.totalAmount;
                    }
                })
                amount = assetData?.loan_amount - alreadyFundedAmount;
                amount = state.offerAmount > amount ? amount : state.offerAmount
                if (state.offerAmount > amount) {
                    setAlreadyFundedAmounts(alreadyFundedAmount);
                    setRemainingFundedAmounts(amount);
                    setShowIndication(true);
                }
            } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.FRACTION) {
                let alreadyFundedAmount = 0;
                await GetApiParam('/invest/getAssetFundedAmount', { "asset_id": assetData?.id }).then((res) => {
                    if (res?.data?.code === 200) {
                        alreadyFundedAmount = res?.data?.totalAmount;
                    }
                })
                amount = formatNumber(assetData?.fractionalize_total_price - alreadyFundedAmount);
                amount = state.offerAmount > amount ? amount : state.offerAmount
                if (state.offerAmount > amount) {
                    setAlreadyFundedAmounts(alreadyFundedAmount);
                    setRemainingFundedAmounts(amount);
                    setShowIndication(true);
                }
            }
            else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.FRACTION) {
                let fundedData = {};
                await GetApiParam('/invest/getAssetFundedAmount', { "asset_id": assetData?.id }).then((res) => {
                    if (res?.data?.code === 200) {
                        fundedData = res?.data?.data;
                    }
                })
                let capital = assetData?.capital_info?.find((elmts) => elmts?.capital_type?.id === state.capital_type_id);
                let alreadyFundedAmount = fundedData[`capital_${state.capital_type_id}_totalAmount`] ? fundedData[`capital_${state.capital_type_id}_totalAmount`] : 0;
                amount = capital?.total_raise - alreadyFundedAmount;
                amount = state.offerAmount > amount ? amount : state.offerAmount
                if (state.offerAmount > amount) {
                    setAlreadyFundedAmounts(alreadyFundedAmount);
                    setRemainingFundedAmounts(amount);
                    setShowIndication(true);
                }
            }
            else {
                amount = state.offerAmount;
            }

        }

        if (state?.from === 'edit-offer') {
            amount = state?.offerAmount;
        }

        return amount;
    }

    /**
     * function to handle complete transaction button
     */
    const handleCompleteTransaction = async () => {
        let isAllDocSigned = signedDoc?.length > 0 && signedDoc?.every((elmts) => elmts.status === 2);
        if (isAllDocSigned) {
            let u = localStorage.getItem('user_data');
            u = JSON.parse(u);
            // setting payment type based on asset type and listing type


            let payment_type = getPaymentType();
            let amount = await getPayableAmount();

            let transactionPayload = {
                asset_id: assetData?.id,
                add_asset_owner_id: assetData?.user_id,
                contract_version: assetData?.contract_version,
                user_id: u.id,
                status: TRANSACTION_STATUS.SUCCESS,
                amount,
                listing_id: assetData?.listing_id,
                offer_id: state.offerId ? state.offerId : null,
                blockchainOfferId: state.blockchainOfferId ? state.blockchainOfferId : null,
                transaction_type: payment_type,
                investment_type_id: assetData?.investment_type_id,
                parcel_ids: signDocs?.data?.parcel_id ? signDocs?.data?.parcel_id : [], // sending parcel ids 
                priceArray: signDocs?.data?.priceArray ? signDocs?.data?.priceArray : [] // sending parcel ids 
            }

            if (state?.from === 'direct-buy' && assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
                setOpenModalWithdrawalSale(true);
                setMakeOfferPayload({ ...transactionPayload });
            } else if (state?.from === 'offer-page' && assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
                setOpenAcceptModal(true);
                setMakeOfferPayload({ ...makeOfferPayload });
            } else if (state?.from === 'edit-offer' && assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
                setOpenTransactionModalChanging(true);
                setMakeOfferPayload({ ...makeOfferPayload, listing_id: assetData?.listing_id, offer_id: state.offerId });
            } else if (signDocs?.isFullPayment && assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
                setOpenModalFullPayment(true);
                setMakeOfferPayload({ ...transactionPayload, feeTokenType });
            } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
                setOpenTransactionModalOffer(true);
            } else if (state?.from === 'offer-page' && assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
                setOpenAcceptModal(true);
                setMakeOfferPayload({ ...makeOfferPayload, tokenImageData: tokenImageData });
            } else if (state?.from === 'edit-offer' && assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
                setOpenTransactionModalChanging(true);
                setMakeOfferPayload({ ...makeOfferPayload, listing_id: assetData?.listing_id, offer_id: state.offerId });
            } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
                setOpenTransactionModalOffer(true);
                setMakeOfferPayload({ ...makeOfferPayload, feeTokenType });
            } else if (assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE && state?.from === 'equity-amount') {
                // setOpenModalClaimToken(true);
                setMakeOfferPayload({ ...makeOfferPayload, ...signDocs?.data, category_id: CATEGORY_TYPE_ID.REAL_ESTATE });
                setOpenWithdrawAmountSPV(true);
            } else if (assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE && (state?.from === 'junior-amount' || state?.from === 'senior-amount')) {
                // setOpenModalClaimToken(true);
                setMakeOfferPayload({ ...makeOfferPayload, ...signDocs?.data, category_id: CATEGORY_TYPE_ID.REAL_ESTATE, capital_type_id: state?.capital_type_id });
                setOpenWithdrawAmountSPVDebt(true);
            } else if (assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                // setOpenModalClaimToken(true);
                setMakeOfferPayload({ ...makeOfferPayload, ...signDocs?.data, category_id: CATEGORY_TYPE_ID.REAL_ESTATE });
                setOpenTransactionModalOffer(true);
            } else if (state?.from === 'offer-page' && assetData?.category_id === CATEGORY_TYPE_ID.FUNDS) {
                setOpenAcceptModal(true);
                setMakeOfferPayload({ ...makeOfferPayload, category_id: CATEGORY_TYPE_ID.FUNDS, asset_sub_category: assetData?.asset_sub_category });
            } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS && (state?.from === 'class-a' || state?.from === 'class-b')) {
                setMakeOfferPayload({ ...makeOfferPayload, ...signDocs?.data, category_id: CATEGORY_TYPE_ID.FUNDS, class: state?.class });
                setOpenWithdrawAmountFunds(true);
            } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS) {
                if (makeOfferPayload?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS && paymentMethod == 'fiat') {
                    makeOfferPayload.payment_method = paymentMethod
                    const response = await postApi('/invest/offer', makeOfferPayload);
                    if (response?.data?.code === 200) {
                        setOpen(true);
                        let offerData = response?.data?.data
                        let formDataDocs = new FormData();
                        formDataDocs.append("document", uploadedDocumentFiat[0]);
                        formDataDocs.append("asset_id", offerData?.asset_id);
                        formDataDocs.append("user_id", offerData?.investor_id);
                        formDataDocs.append("offer_id", offerData?.id);
                        await postApi('invest/investmentApproval', formDataDocs);
                    }
                } else {
                    // setOpenModalClaimToken(true);
                    setMakeOfferPayload({ ...makeOfferPayload, ...signDocs?.data, category_id: CATEGORY_TYPE_ID.FUNDS, feeTokenType });
                    setOpenTransactionModalOffer(true);
                }
            }
            else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.PARCEL && state?.from === 'parcel-buy') {
                setOpenWithdrawAmountParcel(true);
                setMakeOfferPayload(transactionPayload);
            } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.PARCEL) {
                setOpenModalBuyParcel(true);
                setMakeOfferPayload({ ...transactionPayload, feeTokenType });
            } else {
                // const res = await postApi('/transaction/createTransaction', transactionPayload);
                // if (res?.data?.code === 200) {
                //     setOpen(true);
                // }
            }

        } else {
            window.scrollTo({ //scrolled so that user knows that there is an error on sign documents
                top: 0,
                behavior: 'smooth'
            });
            showSnackbar('Please sign all the Documents.', "error")
        }
    }

    /**
     * Function to handle signed documents
     * @param {Object} signDoc 
     */
    const handleSignedDoc = (signDoc) => {
        setSignedDoc(signDoc)
    }

    /**
    * Function to handle modal close
    */
    const handleModalCloseOffer = () => {
        setOpenTransactionModalOffer(false);
    }

    /**
    * Function to handle modal close
    */
    const handleModalCloseFullPayment = () => {
        setOpenModalFullPayment(false);
    }

    /**
    * Function to handle modal close
    */
    const handleModalBuyParcel = () => {
        setOpenModalBuyParcel(false);
    }

    /**
    * Function to handle modal close
    */
    const handleModalCloseWithdrawalSale = () => {
        setOpenModalWithdrawalSale(false);
    }

    /**
    * Function to handle modal close
    */
    const handleModalCloseWithdrawalParcel = () => {
        setOpenWithdrawAmountParcel(false);

    }
    /**
    * Function to handle modal close
    */
    const handleModalWithdrawalAmountSPV = () => {
        setOpenWithdrawAmountSPV(false);
    }

    /**
    * Function to handle modal close
    */
    const handleModalWithdrawalAmountSPVDebt = () => {
        setOpenWithdrawAmountSPVDebt(false);
    }

    /**
    * Function to handle modal close
    */
    const handleModalWithdrawalAmountFunds = () => {
        setOpenWithdrawAmountFunds(false);
    }

    /**
    * Function to handle modal close
    */
    const handleModalCloseRemainingPayment = (data) => {
        if (data?.warning) {
            navigate('/user/transactions', { replace: true })
        }
        setOpenModalRemainingPayment(false);
    }

    /**
   * Function to handle modal close
   */
    const handleModalCloseChanging = () => {
        setOpenTransactionModalChanging(false);
    }
    /**
   * Function to handle modal close
   */
    const handleModalCloseCreateEMI = () => {
        setOpenModalCreateEMI(false);
    }

    /**
   * Function to handle modal close
   */
    const handleModalCloseGiveFundToken = () => {
        setOpenGiveFundToken(false);
    }

    /**
   * Function to handle modal close
   */
    const handleModalCloseAccept = () => {
        setOpenAcceptModal(false);
    }

    /**
     * Function call after transaction confirm
     */
    const confirmStake = async (data, amount, asset_id, assetData) => {
        setOpen(true);
        if (paymentMethod == 'fiat' && data?.logs && data?.logs[0]?.data) {
            let hexDataNew = data?.logs[0]?.data.startsWith("0x") ? data.logs[0].data.slice(2) : data.logs[0].data;

            // Split the hex data into 64-character segments
            const segments = hexDataNew.match(/.{1,64}/g);

            // Convert to a decimal number
            const blockChainOfferId = Number.parseInt(segments[1], 16);

            let formDataDocs = new FormData();
            formDataDocs.append("document", uploadedDocumentFiat[0]);
            formDataDocs.append("asset_id", asset_id);
            formDataDocs.append("blockChainOfferId", blockChainOfferId);
            await postApi('invest/investmentApproval', formDataDocs);
        }

        if (assetData?.category_id != CATEGORY_TYPE_ID.REAL_ESTATE && (assetData?.capital_type_id != CAPITAL_TYPE_ID.SENIOR_DEBT || assetData?.capital_type_id != CAPITAL_TYPE_ID.JUNIOR_DEBT || assetData?.capital_type_id != CAPITAL_TYPE_ID.EQUITY)) {
            try {
                let userData = localStorage.getItem('user_data')
                userData = JSON.parse(userData);
                const gasFees = Number(data.gasUsed)
                let body = {
                    amount: amount,
                    sender_address: data.from,
                    contract_address: data.to,
                    transaction_hash: data.transactionHash,
                    method_name: "makeOffer",
                    proposer_id: userData?.id,
                    asset_id: asset_id,
                    gas_fees: gasFees

                }
                const transactionCreate = await postApi("/user/createBlockchainTransaction", body);
            } catch (error) {
                console.log('error', error);
            }
        }
    }

    /**
     * Function call after transaction confirm
     */
    const confirmStakeFull = async (data, amount, asset_id) => {

        setOpen(true);
        try {
            let userData = localStorage.getItem('user_data')
            userData = JSON.parse(userData);
            const gasFees = Number(data.gasUsed)
            let body = {
                amount: amount,
                sender_address: data.from,
                contract_address: data.to,
                transaction_hash: data.transactionHash,
                method_name: "directBuy",
                proposer_id: userData?.id,
                asset_id: asset_id,
                gas_fees: gasFees

            }
            const transactionCreate = await postApi("/user/createBlockchainTransaction", body);
        } catch (error) {
            console.log('error', error);
        }
    }

    /**
     * Function call after transaction confirm
     */
    const confirmWithdrawalSale = async (data, asset_id) => {
        navigate(`/project-details/${state?.assetId}`, { replace: true, state: { from: 'payment-page' } })
    }

    /**
     * Function call after transaction confirm
     */
    const confirmWithdrawalParcel = async (data, asset_id) => {
        navigate(`/project-details/${state?.assetId}`, { replace: true, state: { from: 'payment-page' } })
    }

    /**
     * Function call after transaction confirm
     */
    const confirmRemainingPayment = async (data, amount, asset_id) => {

        try {
            let userData = localStorage.getItem('user_data')
            userData = JSON.parse(userData);
            const gasFees = Number(data.gasUsed)
            let body = {
                amount: amount,
                sender_address: data.from,
                contract_address: data.to,
                transaction_hash: data.transactionHash,
                method_name: "confirmRemainingPayment",
                proposer_id: userData?.id,
                asset_id: asset_id,
                gas_fees: gasFees

            }

            const transactionCreate = await postApi("/user/createBlockchainTransaction", body);
        } catch (error) {
            console.log('error', error);
        }
    }

    /**
     * Function call after transaction confirm
     */
    const confirmCreateEMI = async (data, asset_id) => {
        setOpen(true);
    }

    /**
     * Function call after transaction confirm
     */
    const confirmStakeAccept = async () => {
        navigate(`/project-details/${state?.assetId}`, { replace: true, state: { from: 'payment-page' } })
    }

    /**
     * Function call after transaction confirm
     */
    const confirmWithdrawAmountSPV = async (data, asset_id) => {
        navigate(`/project-details/${state?.assetId}`, { replace: true, state: { from: 'payment-page' } })
    }

    /**
     * Function call after transaction confirm
     */
    const confirmWithdrawAmountSPVDebt = async (data, asset_id) => {
        navigate(`/project-details/${state?.assetId}`, { replace: true, state: { from: 'payment-page' } })
    }
    /**
     * Function call after transaction confirm
     */
    const confirmWithdrawAmountFund = async (data, asset_id) => {
        navigate(`/project-details/${state?.assetId}`, { replace: true, state: { from: 'payment-page' } })
    }

    /**
     * Function call after transaction confirm
     */
    const confirmStakeChanging = async () => {
        navigate(`/user/transactions`, { replace: true })
    }

    /**
     * Function call after transaction confirm
     */
    const confirmBuyParcel = async (data, amount, asset_id) => {

        setOpen(true);
        try {
            let userData = localStorage.getItem('user_data')
            userData = JSON.parse(userData);
            const gasFees = Number(data.gasUsed)
            let body = {
                amount: amount,
                sender_address: data.from,
                contract_address: data.to,
                transaction_hash: data.transactionHash,
                method_name: "buyParcel",
                proposer_id: userData?.id,
                asset_id: asset_id,
                gas_fees: gasFees

            }

            const transactionCreate = await postApi("/user/createBlockchainTransaction", body);
        } catch (error) {
            console.log('error', error);
        }
    }

    /**
     * getImage
     */
    const getImage = async (data, offerData, emiData) => {
        if (data) {
            try {
                let formData = new FormData();
                formData.append('image', data)
                formData.append('asset_id', state?.assetId)
                formData.append("principal", offerData?.offer_amount)
                formData.append("interest", offerData?.loan_roi)
                formData.append("months", offerData?.loan_duration)
                formData.append("emi_moths", convertFromDecimal(Number(emiData[0])))
                formData.append("total_interest_amount", convertFromDecimal(Number(emiData[1])))
                formData.append("total_principal_interest_amount", convertFromDecimal(Number(emiData[2])))
                formData.append("lender_address", offerData?.investor_details?.wallet_address)
                formData.append("borrower_address", offerData?.proposer_details?.wallet_address)

                let res = await postApi(`/proposer/getTokenImageIpfs`, formData);
                if (res.data.code === 200) {
                    setTokenImageData(res.data.data);
                }
                setTimeout(() => {
                    setOpenBackdropModal(false);
                }, 2000);
            } catch (error) {
                showSnackbar(error, 'error');
                setOpenBackdropModal(false);
            }
        }
    }

    return (
        <>
            {
                // Only in case of parceling show parcel selection page first
                showParcelSelectionPage && assetData?.parcelData && state?.from !== "parcel-buy" ?
                    <ParcelEditPage
                        assetData={assetData}
                        selectedParcelsArr={selectedParcelsArr}
                        setSelections={setSelections}
                        hideParcelSelectionPage={handleHideParcelSelectionPage}
                        state={state}
                    />
                    :
                    <Box mt={6} className='payment-sale-investor'>
                        <Box className="container">
                            <Grid container spacing={2}>
                                <Grid item md={8} sm={12} xs={12}>
                                    {
                                        (state?.from === 'offer_accept' || state?.from === 'offer-page') && !signDocs.open ?
                                            <DemoSignDocuments />
                                            :
                                            signDocs.open ?
                                                // show sign docs page
                                                <SignDocuments amountToPay={amountToPay} loanDuration={state?.loan_duration} loanROI={state?.loan_roi} setSignDocs={setSignDocs} signDocs={signDocs} assetData={assetData} handleSignedDoc={handleSignedDoc} offerId={state?.offerId} parcel_ids={selectedParcelsArr} offerData={makeOfferPayload} isKycDone={isKycDone} />
                                                :
                                                // main page of payment page
                                                <>
                                                    <Box>
                                                        {
                                                            assetData && !showParcelSelectionPage && PaymentComponents[query?.listing]
                                                        }
                                                    </Box>
                                                    <Box mt={2.5}>
                                                        <IndentityKyc />
                                                    </Box>
                                                    <Box mt={2.5}>
                                                        <PaymentMethod assetCategoryName={assetData?.assets_category?.url} asset_sub_category={assetData?.asset_sub_category} currencyOption={true} feeTokenType={feeTokenType} setFeeTokenType={setFeeTokenType} setPaymentMethod={setPaymentMethod} setUploadedDocumentFiat={setUploadedDocumentFiat} />
                                                    </Box>
                                                    <Box mt={2.5}>
                                                        {assetData && !state?.from && (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS || assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE || assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN || assetData?.investment_type_id === INVESTMENT_TYPE_ID.PARCEL || assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE) ?
                                                            <SignDocuments amountToPay={amountToPay} loanDuration={loanDurationIn} loanROI={interestRateIn} setSignDocs={setSignDocs} signDocs={signDocs} assetData={assetData} handleSignedDoc={handleSignedDoc} offerId={state?.offerId} parcel_ids={selectedParcelsArr} offerData={makeOfferPayload} paymentMethod={paymentMethod} isInvestmentNotAllow={isInvestmentAllow === true ? false : true} isKycDone={isKycDone} checkAllValidations={checkAllValidations} />
                                                            : null
                                                        }
                                                    </Box>
                                                    <Box mt={2.5}>
                                                        <Terms assetData={assetData} termsAccepted={termsAccepted} setTermsAccepted={setTermsAccepted} />
                                                    </Box>
                                                </>
                                    }
                                    {
                                        signDocs.open ? <>
                                            {state?.from === 'direct-buy' || state?.from === 'parcel-buy' || state?.from === 'equity-amount' || state?.from === 'senior-amount' || state?.from === 'junior-amount' || state?.from === 'class-b' || state?.from === 'class-a' ?
                                                <Box mt={3} mb={4} maxWidth={'404px'}>
                                                    <Button disabled={Boolean(signedDoc?.length > 0 && signedDoc?.every((elmts) => elmts.status === 2)) ? false : true} onClick={handleCompleteTransaction}
                                                        style={{ backgroundColor: Boolean(signedDoc?.length > 0 && signedDoc?.every((elmts) => elmts.status === 2)) ? '' : 'gray' }}
                                                        className='btn-rounded btn-large btn-blue-500 btn-text-white w-100'>Withdraw Amount</Button>
                                                </Box>
                                                :
                                                <>
                                                    <Box mt={3} mb={4} maxWidth={'404px'}>
                                                        <Button disabled={Boolean(signedDoc?.length > 0 && signedDoc?.every((elmts) => elmts.status === 2)) ? false : true} onClick={handleCompleteTransaction} startIcon={<AttachMoney />}
                                                            style={{ backgroundColor: Boolean(signedDoc?.length > 0 && signedDoc?.every((elmts) => elmts.status === 2)) ? '' : 'gray' }}
                                                            className='btn-rounded btn-large btn-blue-500 btn-text-white w-100'>{state?.from === 'offer-page' ? (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS ? "Accept Commitment" : "Accept Offer") : `Confirm investment for $${formatNumber(parseFloat((amountToPay > 0 ? amountToPay : 0)))}`}</Button>
                                                    </Box>
                                                    {showIndication && <Typography>*This project has already received a {assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? 'investment' : assetData?.investment_type_id === INVESTMENT_TYPE_ID.FRACTION && state?.capital_type_id === CAPITAL_TYPE_ID.EQUITY ? 'amount' : 'loan'} of ${alreadyFundedAmounts}, so you can only provide {assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? 'investment' : assetData?.investment_type_id === INVESTMENT_TYPE_ID.FRACTION && state?.capital_type_id === CAPITAL_TYPE_ID.EQUITY ? 'amount' : 'loan'} of ${remainingFundedAmounts}.</Typography>
                                                    }
                                                </>
                                            }
                                        </>
                                            : isOffer === false ?
                                                <Box mt={3} mb={5} maxWidth={'404px'}>
                                                    <Button onClick={() => handleSubmitOffer('confirm')} startIcon={<AttachMoney />} className='btn-rounded btn-large btn-blue-500 btn-text-white w-100'>Confirm Investment of ${formatNumber(parseFloat((amountToPay > 0 ? amountToPay : 0)))}</Button>
                                                </Box> :
                                                <Box mt={3} mb={5} maxWidth={'404px'}>
                                                    <Button onClick={() => handleSubmitOffer('offer')} startIcon={<AttachMoney />} className='btn-rounded btn-large btn-blue-500 btn-text-white w-100'>{(assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE || assetData?.category_id === CATEGORY_TYPE_ID.FUNDS) ? `Confirm Investment of $${formatNumber(parseFloat((amountToPay > 0 ? amountToPay : 0)))}` : `Submit Offer for $${formatNumber(parseFloat(amountToPay > 0 ? amountToPay : 0))}`} <Tooltip classes={{ tooltip: 'custom-tooltip', arrow: 'custom-arrow', }} PopperProps={{ style: { maxWidth: '427px', maxHeight: '266px' } }} title={<>
                                                        <Typography className='font-14'>
                                                            <ul>
                                                                <li>Review Project Details</li>
                                                                <li>Ensure you understand the terms of the project.</li>
                                                                {assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN ? <>
                                                                    <li>Click the "Lend" button to submit your offer to Project”.</li>
                                                                    <li>The loan offer is locked in an escrow contract</li>
                                                                    <li>If the Asset Owner chooses to accept your bid you’ll receive notification to sign the contract and fund the loan</li>
                                                                    <li>If he rejects or does not accept your offer your funds are returned to your wallet</li>
                                                                </>
                                                                    : null
                                                                }
                                                            </ul>
                                                        </Typography>
                                                    </>} placement='right' arrow><Info style={{ color: 'white', marginLeft: '8px' }} /></Tooltip> </Button>
                                                </Box>
                                    }
                                </Grid >
                                <Grid item md={4} sm={12} xs={12}>
                                    <Box className={'rc-project-details-right'}>
                                        {assetData?.investment_type_id === INVESTMENT_TYPE_ID.FRACTION && <>
                                            <CapitalInfoInvestor assetId={assetData?.id} assetData={assetData} capitalInfo={assetData?.capital_info} selectedCapitalId={selectedCapitalId} from={state?.from} isFromPayment={true} />
                                            <Box component={'hr'} className='rc-hr' mb={2} />
                                        </>
                                        }
                                        {assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN && <>
                                            <LoanInformation loan_amount={assetData?.loan_amount} loan_iop={assetData?.loan_iop} loan_repay_day={assetData?.loan_repay_day} assetId={assetData?.id} minimum_investment={assetData?.loan_minimum_investment} loan_roi={assetData?.loan_roi} loan_duration={assetData?.loan_duration_month} assetData={assetData}
                                            />
                                            <Box component={'hr'} className='rc-hr' />
                                        </>
                                        }

                                        {assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE && <>
                                            <SaleInformation sale_price={assetData?.sale_price} deposite_amount={assetData.sale_deposit_amount} assetId={assetData?.id} />
                                            <Box component={'hr'} className='rc-hr' />
                                        </>
                                        }
                                        <Documents documentsData={assetData?.asset_document} descriptionData={assetData?.description_document} />
                                        <Box component={'hr'} className='rc-hr' />
                                        <FaqsAccordions assetData={assetData} />
                                    </Box>
                                </Grid>
                            </Grid >

                        </Box >
                    </Box >
            }
            {
                (signDocs.open && ((assetData?.investment_type_id !== INVESTMENT_TYPE_ID.LOAN && assetData?.asset_sub_category !== SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS || state?.offerId))) ?
                    <TransactionStatusModal open={open} setOpen={setOpen} />
                    :
                    <InvestorRelatedModal open={open} setOpen={setOpen} paymentMethod={paymentMethod === 'fiat' || assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.OPEN_FUNDS} isFunds={assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS || assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.OPEN_FUNDS || assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE || assetData?.investment_type_id === INVESTMENT_TYPE_ID.PARCEL || (assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE && getPaymentType() === TRANSACTION_TYPE.FULL_PAYMENT) ? true : false} isSale={assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE && getPaymentType() === TRANSACTION_TYPE.DEPOSIT} />
            }

            {/* load to get token image for senior or junior type investment */}
            {
                ((state?.from === 'offer_accept' || state?.from === 'offer-page') && emiData && assetData && openBackdropModal && !tokenImageData && (state?.capital_type_id == CAPITAL_TYPE_ID.SENIOR_DEBT || state?.capital_type_id == CAPITAL_TYPE_ID.JUNIOR_DEBT || assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN)) ? <Box ><CreateTokenImage getImage={getImage} assetData={assetData} offerData={state?.val} emiData={emiData} /></Box> : null
            }

            {/* wait for */}
            {
                ((state?.from === 'offer_accept' || state?.from === 'offer-page') && assetData && openBackdropModal && (state?.capital_type_id == CAPITAL_TYPE_ID.SENIOR_DEBT || state?.capital_type_id == CAPITAL_TYPE_ID.JUNIOR_DEBT || assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN)) ?
                    <Modal
                        open={openBackdropModal}
                        className="kyc-modal wallet-transaction-flow"
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        BackdropComponent={Backdrop}
                        BackdropProps={{ open: false }}
                        sx={{ backdropFilter: 'blur(2px)' }}
                    >
                        <Box className="set-stack-rts common-modal-design" style={{ width: '180px' }} >
                            <Box className='waiting-for-ipfs-center'>
                                <CircularProgress />
                            </Box>
                        </Box>
                    </Modal >
                    : null
            }

            {/* {((state?.from === 'offer_accept' || state?.from === 'offer-page') && !signDocs.open) ?
                <Modal
                    open={openBackdropModal}
                    className="kyc-modal wallet-transaction-flow"
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    BackdropComponent={Backdrop}
                    BackdropProps={{ open: false }}
                    sx={{ backdropFilter: 'blur(20px)' }}
                >
                    <Box className="set-stack-rts common-modal-design" style={{ width: '180px' }} >
                        <Box className='waiting-for-ipfs-center'>
                            <CircularProgress />
                        </Box>
                    </Box>
                </Modal >
                : null
            } */}


            {/* code for make offer smart contract modal */}
            {openTransactionModalOffer ? <TransactionProgressModal identity={'make-offer'} confirmStake={confirmStake} propData={makeOfferPayload} handleModalClose={handleModalCloseOffer} openTransactionModal={openTransactionModalOffer} /> : null}

            {/* code for full payment sale smart contract modal */}
            {openModalFullPayment ? <TransactionProgressModal identity={'direct-buy'} confirmStake={confirmStakeFull} propData={makeOfferPayload} handleModalClose={handleModalCloseFullPayment} openTransactionModal={openModalFullPayment} /> : null}

            {/* code for withdrawal of asset smart contract modal */}
            {openModalWithdrawalSale ? <TransactionProgressModal identity={'withdrawal-asset-sale'} confirmStake={confirmWithdrawalSale} propData={makeOfferPayload} handleModalClose={handleModalCloseWithdrawalSale} openTransactionModal={openModalWithdrawalSale} /> : null}

            {/* code for withdrawal of asset smart contract modal */}
            {openWithdrawAmountParcel ? <TransactionProgressModal identity={'withdrawal-parcel'} confirmStake={confirmWithdrawalParcel} propData={makeOfferPayload} handleModalClose={handleModalCloseWithdrawalParcel} openTransactionModal={openWithdrawAmountParcel} /> : null}

            {/* code for pay remaining payment smart contract modal */}
            {openModalRemainingPayment ? <TransactionProgressModal identity={'remaining-payment'} confirmStake={confirmRemainingPayment} propData={makeOfferPayload} handleModalClose={handleModalCloseRemainingPayment} openTransactionModal={openModalRemainingPayment} /> : null}

            {/* code for payment confirmation for offer loan smart contract modal */}
            {openModalCreateEMI ? <TransactionProgressModal identity={'create-emi'} confirmStake={confirmCreateEMI} propData={makeOfferPayload} handleModalClose={handleModalCloseCreateEMI} openTransactionModal={openModalCreateEMI} /> : null}

            {/* code for accept offer */}
            {openAcceptModal ? <TransactionProgressModal identity={'accept-reject-offer'} confirmStake={confirmStakeAccept} propData={makeOfferPayload} handleModalClose={handleModalCloseAccept} openTransactionModal={openAcceptModal} /> : null}

            {/* code for accept offer */}
            {openGiveFundToken ? <TransactionProgressModal identity={'give-space-x-fund-token'} confirmStake={confirmStakeAccept} propData={makeOfferPayload} handleModalClose={handleModalCloseGiveFundToken} openTransactionModal={openGiveFundToken} /> : null}

            {/* code for buy parcel */}
            {openModalBuyParcel ? <TransactionProgressModal identity={'buy-parcel'} confirmStake={confirmBuyParcel} propData={makeOfferPayload} handleModalClose={handleModalBuyParcel} openTransactionModal={openModalBuyParcel} /> : null}

            {/* code for Edit offer */}
            {
                openTransactionModalChanging ?
                    <TransactionProgressModal identity={'changing-offer'} confirmStake={confirmStakeChanging} propData={makeOfferPayload} handleModalClose={handleModalCloseChanging} openTransactionModal={openTransactionModalChanging} /> : null
            }

            {/* code for spv equity */}
            {
                openWithdrawAmountSPV ?
                    <TransactionProgressModal identity={'withdraw-spv'} confirmStake={confirmWithdrawAmountSPV} propData={makeOfferPayload} handleModalClose={handleModalWithdrawalAmountSPV} openTransactionModal={openWithdrawAmountSPV} /> : null
            }

            {/* code for spv debt  */}
            {
                openWithdrawAmountSPVDebt ?
                    <TransactionProgressModal identity={'withdraw-spv-debt'} confirmStake={confirmWithdrawAmountSPVDebt} propData={makeOfferPayload} handleModalClose={handleModalWithdrawalAmountSPVDebt} openTransactionModal={openWithdrawAmountSPVDebt} /> : null
            }

            {/* code funds */}
            {
                openWithdrawAmountFunds ?
                    <TransactionProgressModal identity={'withdraw-funds'} confirmStake={confirmWithdrawAmountFund} propData={makeOfferPayload} handleModalClose={handleModalWithdrawalAmountFunds} openTransactionModal={openWithdrawAmountFunds} /> : null
            }
        </>
    )
}
export default PaymentPage