import React, { useEffect, useState } from 'react';
import ReactImageGallery from 'react-image-gallery';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import VideoThumbnail from 'react-video-thumbnail';
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { GetApiParam, updateApi } from "../Api/Api";
import DropdownBox from "../InvestorOnly/Component/DropdownBox";
import { updateStep } from "../features/auth/tooltipSlice";
import { Box, Typography, useMediaQuery, Backdrop, Button, CircularProgress } from '@mui/material';
import { GetContractAddress } from '../Components/Common/GetContractAddress';
import { CONTRACT_NAME } from '../constants';
import { Download } from '@mui/icons-material';

export default function GallerySlider({ images, coverPhoto, nft_id, isOnboard, isLoan, isParcel, isSale, isFraction, isFundFraction, assetData, isTokenize }) {
    const [data, setData] = useState([]);
    const [tolPosition, setTolPosition] = useState(0);
    const [nftAddress, setNftAddress] = useState('');
    const [processedImage, setProcessedImage] = useState(null);
    const [processedImageDownload, setProcessedImageDownload] = useState(null);
    const [investorPurchased, setInvestorPurchased] = useState(false);
    const [alphanumericNftID, setAlphanumericNftID] = useState('');

    const step = useSelector((state) => {
        if (isLoan) {
            return state.tooltipSlice.loan_step_number
        } else if (isParcel) {
            return state.tooltipSlice.parcel_step_number
        } else if (isSale) {
            return state.tooltipSlice.sale_step_number
        } else if (isFraction) {
            return state.tooltipSlice.re_step_number
        } else if (isFundFraction) {
            return state.tooltipSlice.funds_step_number
        }
    }
    );
    const isActive = useSelector((state) => state.tooltipSlice.active_how_it_work);

    const [loadingTooltip, setLoadingToolTip] = useState(false);
    const [drawParcelLoader, setDrawParcelLoader] = useState(false);
    const [step2, setStep2] = useState(false);
    const isXs = useMediaQuery('(max-width:600px)');

    const navigate = useNavigate()
    const dispatch = useDispatch()

    // const localData 
    const localData = localStorage.getItem("user_data")
        ? JSON.parse(localStorage.getItem("user_data"))
        : ""; //Getting  user data from local storage

    useEffect(() => {
        // Combine images and cover photo
        let combinedData = [];
        images?.forEach((val, index) => {
            combinedData.push({
                original: `${process.env.REACT_APP_IMAGE_URL}${val.images.path}`,
                thumbnail: val?.images?.type === 'mp4' ? `${process.env.REACT_APP_IMAGE_URL}${images[0]?.images.path}` : `${process.env.REACT_APP_IMAGE_URL}${val.images.path}`,
                isVideo: val?.images?.type === 'mp4' ? true : false,
                index
            });
        });

        if (coverPhoto) {
            combinedData.unshift({
                original: `${process.env.REACT_APP_IMAGE_URL}${coverPhoto.path}`,
                thumbnail: `${process.env.REACT_APP_IMAGE_URL}${coverPhoto.path}`,
                isVideo: false,
            });
        }

        setData(combinedData);
    }, [images, coverPhoto]);

    useEffect(() => {
        if (!images?.length || !isParcel || !assetData) return;

        let purchasedParcels = assetData?.parcelData
            ?.filter(parcel => (parcel.is_sold && localData?.
                id === parcel.investor_id))
            ?.map(parcel => parseInt(parcel.title.split("#")[1] - 1)) || [];
        let soldParcels = assetData?.parcelData
            ?.filter(parcel => (parcel.is_sold && localData?.id === assetData.user_id))
            ?.map(parcel => parseInt(parcel.title.split("#")[1] - 1)) || [];
        let otherParcels = assetData?.parcelData
            ?.filter(parcel => !(parcel.is_sold && localData?.
                id === parcel.investor_id || parcel.is_sold && localData?.
                    id === assetData.user_id))
            ?.map(parcel => parseInt(parcel.title.split("#")[1] - 1)) || [];

        const imageUrl = `${process.env.REACT_APP_IMAGE_URL}${images[0]?.images?.path}`;

        const processImage = async () => {
            try {
                setDrawParcelLoader(true)
                // Attempt to fetch the image and handle CORS
                const response = await fetch(imageUrl, {
                    cache: 'no-store'
                });

                if (!response.ok) {
                    throw new Error("Failed to fetch image from server.");
                }

                const imgBlob = await response.blob();
                const img = new Image();
                img.src = URL.createObjectURL(imgBlob);

                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");

                    // Set canvas dimensions
                    canvas.width = img.width;
                    canvas.height = img.height;

                    // Draw the image onto the canvas
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                    // Calculate grid size
                    const cols = parseInt(assetData?.number_of_columns);
                    const rows = parseInt(assetData?.number_of_rows);
                    const parcelWidth = canvas.width / cols;
                    const parcelHeight = canvas.height / rows;

                    // Loop through sold parcels and draw them with red borders
                    purchasedParcels.forEach((index) => {
                        const col = index % cols;
                        const row = Math.floor(index / cols);
                        const x = col * parcelWidth;
                        const y = row * parcelHeight;

                        // Draw semi-transparent red background for sold parcels
                        if (localData?.id === assetData.user_id) {
                            ctx.fillStyle = "rgba(255, 0, 0, 0.5)"; // Red background with opacity
                            ctx.fillRect(x, y, parcelWidth, parcelHeight);
                        }

                        // Draw red border
                        ctx.strokeStyle = "grey";
                        ctx.lineWidth = 0.1;
                        ctx.strokeRect(x, y, parcelWidth, parcelHeight);
                    });

                    // Loop through sold parcels and draw them with red borders
                    otherParcels.forEach((index) => {
                        const col = index % cols;
                        const row = Math.floor(index / cols);
                        const x = col * parcelWidth;
                        const y = row * parcelHeight;

                        // Draw semi-transparent red background for sold parcels
                        // ctx.fillStyle = "rgba(128, 128, 128, 0.5)"; // Gray with 50% opacity
                        if (localData?.id !== assetData.user_id) {
                            ctx.filter = "blur(3px)";
                            ctx.fillStyle = "rgba(180, 180, 180, 0.3)";
                            ctx.fillRect(x, y, parcelWidth, parcelHeight);
                            ctx.filter = "none"; // Reset filter after drawing  
                        }

                        // Draw red border
                        ctx.strokeStyle = "grey";
                        ctx.lineWidth = 0.1;
                        ctx.strokeRect(x, y, parcelWidth, parcelHeight);
                    });
                    // Loop through sold parcels and draw them with red borders
                    soldParcels.forEach((index) => {
                        const col = index % cols;
                        const row = Math.floor(index / cols);
                        const x = col * parcelWidth;
                        const y = row * parcelHeight;

                        // Draw semi-transparent red background for sold parcels
                        // ctx.fillStyle = "rgba(128, 128, 128, 0.5)"; // Gray with 50% opacity
                        // ctx.filter = "blur(10px)";
                        // ctx.fillStyle = "rgba(255, 0, 0, 0.3)"; // Red with 50% opacity
                        // ctx.fillRect(x, y, parcelWidth, parcelHeight);
                        // ctx.filter = "none"; // Reset filter after drawing  

                        ctx.filter = "blur(3px)";
                        ctx.fillStyle = "rgba(180, 180, 180, 0.3)";
                        ctx.fillRect(x, y, parcelWidth, parcelHeight);
                        ctx.filter = "none"; // Reset filter after drawing  

                        // Draw red border
                        ctx.strokeStyle = "grey";
                        ctx.lineWidth = 0.1;
                        ctx.strokeRect(x, y, parcelWidth, parcelHeight);
                    });

                    // Convert canvas to image and update the state
                    const processedImage = canvas.toDataURL("image/png");
                    setProcessedImage(processedImage);



                    /**
                     * image for download
                     */
                    const canvasDownload = document.createElement("canvas");
                    const ctxDownload = canvasDownload.getContext("2d");

                    // Set canvas dimensions
                    canvasDownload.width = img.width;
                    canvasDownload.height = img.height;

                    // Draw the image onto the canvas
                    ctxDownload.drawImage(img, 0, 0, canvasDownload.width, canvasDownload.height);

                    // Calculate grid size
                    const colsDownload = parseInt(assetData?.number_of_columns);
                    const rowsDownload = parseInt(assetData?.number_of_rows);
                    const parcelWidthDownload = canvasDownload.width / colsDownload;
                    const parcelHeightDownload = canvasDownload.height / rowsDownload;

                    // Loop through sold parcels and draw them with red borders
                    purchasedParcels.forEach((index) => {
                        const colDownload = index % colsDownload;
                        const rowDownload = Math.floor(index / colsDownload);
                        const x = colDownload * parcelWidthDownload;
                        const y = rowDownload * parcelHeightDownload;

                        // Draw semi-transparent red background for sold parcels
                        if (localData?.id === assetData.user_id) {
                            ctxDownload.fillStyle = "rgba(255, 0, 0, 0.5)"; // Red background with opacity
                            ctxDownload.fillRect(x, y, parcelWidthDownload, parcelHeightDownload);
                        }

                        // Draw red border
                        // ctxDownload.strokeStyle = "grey";
                        // ctxDownload.lineWidth = 0.1;
                        // ctxDownload.strokeRect(x, y, parcelWidthDownload, parcelHeightDownload);
                    });

                    // Loop through sold parcels and draw them with red borders
                    otherParcels.forEach((index) => {
                        const colDownload = index % colsDownload;
                        const rowDownload = Math.floor(index / colsDownload);
                        const x = colDownload * parcelWidthDownload;
                        const y = rowDownload * parcelHeightDownload;

                        // Draw semi-transparent red background for sold parcels
                        // ctx.fillStyle = "rgba(128, 128, 128, 0.5)"; // Gray with 50% opacity
                        if (localData?.id !== assetData.user_id) {
                            ctxDownload.filter = "blur(3px)";
                            ctxDownload.fillStyle = "rgba(180, 180, 180, 0.3)";
                            ctxDownload.fillRect(x, y, parcelWidthDownload, parcelHeightDownload);
                            ctxDownload.filter = "none"; // Reset filter after drawing  
                        }

                        // Draw red border
                        // ctxDownload.strokeStyle = "grey";
                        // ctxDownload.lineWidth = 0.1;
                        // ctxDownload.strokeRect(x, y, parcelWidthDownload, parcelHeightDownload);
                    });
                    // Loop through sold parcels and draw them with red borders
                    soldParcels.forEach((index) => {
                        const colDownload = index % colsDownload;
                        const rowDownload = Math.floor(index / colsDownload);
                        const x = colDownload * parcelWidthDownload;
                        const y = rowDownload * parcelHeightDownload;

                        // Draw semi-transparent red background for sold parcels
                        // ctx.fillStyle = "rgba(128, 128, 128, 0.5)"; // Gray with 50% opacity
                        // ctx.filter = "blur(10px)";
                        // ctx.fillStyle = "rgba(255, 0, 0, 0.3)"; // Red with 50% opacity
                        // ctx.fillRect(x, y, parcelWidth, parcelHeight);
                        // ctx.filter = "none"; // Reset filter after drawing  

                        ctxDownload.filter = "blur(3px)";
                        ctxDownload.fillStyle = "rgba(180, 180, 180, 0.3)";
                        ctxDownload.fillRect(x, y, parcelWidthDownload, parcelHeightDownload);
                        ctxDownload.filter = "none"; // Reset filter after drawing  

                        // Draw red border
                        // ctxDownload.strokeStyle = "grey";
                        // ctxDownload.lineWidth = 0.1;
                        // ctxDownload.strokeRect(x, y, parcelWidthDownload, parcelHeightDownload);
                    });

                    // Convert canvas to image and update the state
                    const processedImageDownload = canvasDownload.toDataURL("image/png");
                    setProcessedImageDownload(processedImageDownload)

                    // Cleanup
                    URL.revokeObjectURL(img.src);
                };

                setTimeout(() => {
                    setDrawParcelLoader(false)
                }, 1000);
            } catch (error) {
                console.error("Error processing image:", error);
                setTimeout(() => {
                    setDrawParcelLoader(false)
                }, 1000);
            }
        };

        if (assetData?.parcelData?.find(parcel => (parcel.is_sold && localData?.id === parcel.investor_id))) {
            setInvestorPurchased(true)
            processImage();
        } else if (assetData?.user_id !== localData?.id) {
            purchasedParcels = assetData?.parcelData?.map(parcel => parseInt(parcel.title.split("#")[1] - 1)) || [];
            otherParcels = []
        } else {
            processImage();
        }
    }, [images, isParcel, assetData]);

    const renderThumbnail = (item) => {
        if (item.isVideo) {
            return (
                <div className="video-thumbnail">
                    <VideoThumbnail
                        videoUrl={item.original}
                        style={{ width: '100%', height: '100%' }}
                        snapshotAtTime={2} // Adjust the time as needed
                    />
                    <div className="play-icon-overlay">
                        <PlayCircleOutlineIcon fontSize="large" />
                    </div>
                </div>
            );
        }

        return <img src={item.thumbnail} alt="" />;
    };

    const renderItem = (item) => {
        if (item.isVideo) {
            return (
                <div className="video-wrapper">
                    <video controls width="100%" height="auto" style={{ marginBottom: '40px' }} className='image-gallery-image'>
                        <source src={item.original} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            );
        }
        if (isParcel && processedImage && item?.index === 0) {
            return (
                <div className="parcel-grid">
                    {/* Show Processed Image with Borders */}
                    {processedImage ? (
                        <div className="image-container-parcel-sold">
                            <img src={processedImage} alt="Processed Parcel" className="image-gallery-image" />
                            {investorPurchased ? <button onClick={handleDownload} className="download-button"><Download /></button> : null}
                        </div>
                    ) : (
                        <p>Loading...</p> // Show while processing
                    )}
                </div>
            );
        }

        return (
            <div className="image-wrapper">
                <img src={item.original} alt="" height='auto' width="100%" className='image-gallery-image' />
            </div>
        );
    };

    // Download button handler
    const handleDownload = () => {
        if (processedImageDownload) {
            const link = document.createElement("a");
            link.href = processedImageDownload;
            link.download = `${assetData?.asset_title || 'art'}_parcels.png`;
            link.click();
        }
    };

    useEffect(() => {
        if ((tolPosition > 18 || tolPosition < 17) && isLoan) {
            setLoadingToolTip(false)
            setStep2(false)
        } else if ((tolPosition > 13 || tolPosition < 12) && isParcel) {
            setLoadingToolTip(false)
            setStep2(false)
        } else if ((tolPosition > 12 || tolPosition < 11) && isSale) {
            setLoadingToolTip(false)
            setStep2(false)
        } else if ((tolPosition > 15 || tolPosition < 14) && isFraction) {
            setLoadingToolTip(false)
            setStep2(false)
        } else if ((tolPosition > 13 || tolPosition < 12) && isFundFraction) {
            setLoadingToolTip(false)
            setStep2(false)
        }
    }, [tolPosition])

    useEffect(() => {
        async function getStep() {
            try {
                const data = await GetApiParam("/user/currentTooltipPosition", { "user_id": localData.id });
                if (data?.data?.data && isActive) {
                    if (data?.data?.data) {
                        if (isLoan) {
                            if (data.data?.data?.loan_step_number == 17) {
                                setLoadingToolTip(true)
                                setTolPosition(data.data?.data?.loan_step_number == null ? 0 : data.data.data.loan_step_number)
                            }
                            else if (data.data?.data?.loan_step_number == 18) {
                                setStep2(true)
                                setTolPosition(data.data?.data?.loan_step_number == null ? 0 : data.data.data.loan_step_number)
                            }
                        } else if (isParcel) {
                            if (data.data?.data?.parcel_step_number == 12) {
                                setLoadingToolTip(true)
                                setTolPosition(data.data?.data?.parcel_step_number == null ? 0 : data.data.data.parcel_step_number)
                            }
                            else if (data.data?.data?.parcel_step_number == 13) {
                                setStep2(true)
                                setTolPosition(data.data?.data?.parcel_step_number == null ? 0 : data.data.data.parcel_step_number)
                            }
                        }
                        else if (isSale) {
                            if (data.data?.data?.sale_step_number == 11) {
                                setLoadingToolTip(true)
                                setTolPosition(data.data?.data?.sale_step_number == null ? 0 : data.data.data.sale_step_number)
                            }
                            else if (data.data?.data?.sale_step_number == 12) {
                                setStep2(true)
                                setTolPosition(data.data?.data?.sale_step_number == null ? 0 : data.data.data.sale_step_number)
                            }
                        }
                        else if (isFraction) {
                            if (data.data?.data?.re_step_number == 14) {
                                setLoadingToolTip(true)
                                setTolPosition(data.data?.data?.re_step_number == null ? 0 : data.data.data.re_step_number)
                            }
                            else if (data.data?.data?.re_step_number == 15) {
                                setStep2(true)
                                setTolPosition(data.data?.data?.re_step_number == null ? 0 : data.data.data.re_step_number)
                            }
                        } else if (isFundFraction) {
                            if (data.data?.data?.funds_step_number == 12) {
                                setLoadingToolTip(true)
                                setTolPosition(data.data?.data?.funds_step_number == null ? 0 : data.data.data.funds_step_number)
                            }
                            else if (data.data?.data?.funds_step_number == 13) {
                                setStep2(true)
                                setTolPosition(data.data?.data?.funds_step_number == null ? 0 : data.data.data.funds_step_number)
                            }
                        }
                    }
                }
            } catch (error) {
                console.log('error', error)
            }
        }
        // Call getStep initially
        const timer = setTimeout(() => {
            if (localData && localData?.id) {
                getStep();
            }
        }, 100);
        return () => clearTimeout(timer)
    }, [step, isActive])

    async function setStep(tolPositionData) {
        try {
            let body = { "loan_step_number": tolPositionData };
            if (isParcel) {
                body = { "parcel_step_number": tolPositionData };
            } else if (isSale) {
                body = { "sale_step_number": tolPositionData };
            } else if (isFraction) {
                body = { "re_step_number": tolPositionData };
            } else if (isFundFraction) {
                body = { "funds_step_number": tolPositionData };
            }
            const data = await updateApi("/user/updateUser/" + localData?.id, body);
            if (data?.data?.data) {
                if (isLoan) {
                    if (data.data?.data?.loan_step_number == 17) {
                        setLoadingToolTip(true)
                        setTolPosition(data.data?.data?.loan_step_number == null ? 0 : data.data.data.loan_step_number)
                    }
                    else if (data.data?.data?.loan_step_number == 18) {
                        setStep2(true)
                        setTolPosition(data.data?.data?.loan_step_number == null ? 0 : data.data.data.loan_step_number)
                    }
                } else if (isParcel) {
                    if (data.data?.data?.parcel_step_number == 12) {
                        setLoadingToolTip(true)
                        setTolPosition(data.data?.data?.parcel_step_number == null ? 0 : data.data.data.parcel_step_number)
                    }
                    else if (data.data?.data?.parcel_step_number == 13) {
                        setStep2(true)
                        setTolPosition(data.data?.data?.parcel_step_number == null ? 0 : data.data.data.parcel_step_number)
                    }
                }
                else if (isSale) {
                    if (data.data?.data?.sale_step_number == 11) {
                        setLoadingToolTip(true)
                        setTolPosition(data.data?.data?.sale_step_number == null ? 0 : data.data.data.sale_step_number)
                    }
                    else if (data.data?.data?.sale_step_number == 12) {
                        setStep2(true)
                        setTolPosition(data.data?.data?.sale_step_number == null ? 0 : data.data.data.sale_step_number)
                    }
                }
                else if (isFraction) {
                    if (data.data?.data?.re_step_number == 14) {
                        setLoadingToolTip(true)
                        setTolPosition(data.data?.data?.re_step_number == null ? 0 : data.data.data.re_step_number)
                    }
                    else if (data.data?.data?.re_step_number == 15) {
                        setStep2(true)
                        setTolPosition(data.data?.data?.re_step_number == null ? 0 : data.data.data.re_step_number)
                    }
                } else if (isFundFraction) {
                    if (data.data?.data?.funds_step_number == 12) {
                        setLoadingToolTip(true)
                        setTolPosition(data.data?.data?.funds_step_number == null ? 0 : data.data.data.funds_step_number)
                    }
                    else if (data.data?.data?.funds_step_number == 13) {
                        setStep2(true)
                        setTolPosition(data.data?.data?.funds_step_number == null ? 0 : data.data.data.funds_step_number)
                    }
                }
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    const handleTolPosition = (data) => {

        if (isLoan)
            dispatch(updateStep({ key: 'loan_step_number', value: tolPosition + data }));
        if (isSale)
            dispatch(updateStep({ key: 'sale_step_number', value: tolPosition + data }));
        if (isParcel)
            dispatch(updateStep({ key: 'parcel_step_number', value: tolPosition + data }));
        if (isFraction)
            dispatch(updateStep({ key: 're_step_number', value: tolPosition + data }));
        if (isFundFraction)
            dispatch(updateStep({ key: 'funds_step_number', value: tolPosition + data }));

        if (data < 0) {
            if (step2) {
                setStep2(false)
            } else {
                setLoadingToolTip(false)
            }
        } else {
            if ((tolPosition >= 18 && isLoan) || (tolPosition >= 13 && isParcel) || (tolPosition >= 12 && isSale) || (tolPosition >= 15 && isFraction) || (tolPosition >= 13 && isFundFraction)) {
                dispatch(updateStep({ key: 'active_how_it_work', value: false }));
            }
        }
        if (step2 && data > 0) {
            setTolPosition(6)
        } else {
            setTolPosition(pre => pre + data)
        }
        if (step2 && data > 0) {
            setStep(6)
            setStep2(false);
            setLoadingToolTip(false)
        } else {
            setStep(tolPosition + data)
        }
    }

    useEffect(() => {
        // Function to convert a number to an 8-character alphanumeric string
        const convertToAlphanumeric = (num) => {
            // Convert the number to a string
            let numStr = num.toString();

            // Pad the string with leading zeros if necessary to make it 8 characters long
            numStr = numStr.padStart(8, '0');

            // Define a list of alphanumeric characters
            const alphanumeric = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

            // Function to get a random alphanumeric character
            const getRandomAlphanumeric = () => {
                return alphanumeric[Math.floor(Math.random() * alphanumeric.length)];
            };

            // Convert the padded number string to an alphanumeric string
            let alphaStr = '';
            for (let i = 0; i < numStr.length; i++) {
                // Get a random alphanumeric character based on the digit
                alphaStr += getRandomAlphanumeric();
            }

            setAlphanumericNftID(alphaStr)
        }
        if (nft_id) {
            convertToAlphanumeric(nft_id)
        }
    }, [nft_id])

    useEffect(() => {
        async function getNftAddress() {
            try {
                const response = await GetContractAddress(assetData, assetData?.contract_version, isTokenize ? CONTRACT_NAME.TOKENIZE_NFT : CONTRACT_NAME.NFT);
                if (response && response?.length) {
                    setNftAddress(response[0].address)
                }
            } catch (error) {
                console.log(error?.message)
            }
        }
        if (nft_id && assetData) {
            getNftAddress()
        }
    }, [nft_id, assetData])

    return (
        <Box className="details-thumbnails-slider" id="scroll-to-gallery" sx={{
            zIndex: isOnboard && (loadingTooltip || step2) ? 98 : 1
            , position: 'relative',
            color: "white",
        }}>
            {
                isOnboard && step2 ?
                    <Box>
                        <Typography style={{ marginLeft: "8px", marginBottom: "10px", cursor: "pointer", wordBreak: "break-all" }}>
                            <DropdownBox render={<span style={{ marginLeft: "8px", marginBottom: "10px", cursor: "pointer", zIndex: 102, position: 'relative' }}> <Link to={`${process.env.REACT_APP_NFT_URL}token/${nftAddress}/instance/${nft_id}?tab=metadata`} target="_blank">NFT Id: {alphanumericNftID}</Link></span>
                            }
                                setState={handleTolPosition} description={"The unique ID assigned to each tokenized asset"} position={"bottom"}
                                last={true}
                            />
                            <span style={{ marginLeft: "8px", marginBottom: "10px", cursor: "pointer" }}> <Link to={`${process.env.REACT_APP_NFT_URL}token/${nftAddress}/instance/${nft_id}?tab=metadata`} target="_blank">NFT Contract Address: {nftAddress}</Link>
                            </span>
                        </Typography>
                    </Box> :
                    isOnboard && loadingTooltip ?
                        <Box>
                            <Typography style={{ marginLeft: "8px", marginBottom: "10px", cursor: "pointer", wordBreak: "break-all", }}><Link to={`${process.env.REACT_APP_NFT_URL}token/${nftAddress}/instance/${nft_id}?tab=metadata`} target="_blank">NFT Id: {alphanumericNftID}</Link>
                                <DropdownBox render={
                                    <span style={{ marginLeft: "8px", marginBottom: "10px", cursor: "pointer", zIndex: 102, position: 'relative' }}><Link to={`${process.env.REACT_APP_NFT_URL}token/${nftAddress}/instance/${nft_id}?tab=metadata`} target="_blank">NFT Contract Address: {nftAddress}</Link></span>

                                }
                                    setState={handleTolPosition} description={"Unique Address of the tokenized asset on the RACE chain, you can check the provenance of the asset through it’s address"} position={"bottom"}
                                />
                            </Typography>
                        </Box>
                        :
                        isOnboard ?
                            <Typography style={{ marginLeft: "8px", marginBottom: "10px", cursor: "pointer", wordBreak: "break-all", }}><Link to={`${process.env.REACT_APP_NFT_URL}token/${nftAddress}/instance/${nft_id}?tab=metadata`} target="_blank">NFT Id: {alphanumericNftID}</Link>&emsp; <Link to={`${process.env.REACT_APP_NFT_URL}token/${nftAddress}/instance/${nft_id}?tab=metadata`} target="_blank">NFT Contract Address: {nftAddress}</Link></Typography>
                            : null
            }

            {isTokenize ? <Typography style={{ marginLeft: "8px", marginBottom: "10px", cursor: "pointer", wordBreak: "break-all", }}><Link to={`${process.env.REACT_APP_NFT_URL}token/${nftAddress}/instance/${nft_id}?tab=metadata`} target="_blank">NFT Id: {alphanumericNftID}</Link>&emsp; <Link to={`${process.env.REACT_APP_NFT_URL}token/${nftAddress}/instance/${nft_id}?tab=metadata`} target="_blank">NFT Contract Address: {nftAddress}</Link></Typography> : null}

            {
                data && data.length > 0 ? (
                    <ReactImageGallery
                        items={data}
                        autoPlay={false}
                        showThumbnails={true}
                        thumbnailPosition={isXs ? 'bottom' : 'left'} // Set the position based on screen size
                        infinite={false}
                        showPlayButton={false}
                        lazyLoad={true}
                        useBrowserFullscreen={true}
                        originalHeight="426px"
                        originalWidth="200px"
                        showIndex={true}
                        disableThumbnailScroll={false}
                        showNav={false}
                        showBullets={true}
                        showFullscreenButton={true}
                        slideOnThumbnailOver={false}
                        disableThumbnailSwipe={false}
                        renderItem={renderItem}
                        renderThumbInner={renderThumbnail} // Use custom render for thumbnails
                    />
                ) : (
                    <Box>
                        <Typography variant="h6" sx={{ textAlign: 'center' }}>
                            No Images available
                        </Typography>
                    </Box>
                )
            }

            {
                (loadingTooltip || step2) ?
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => 97 }}
                        open={loadingTooltip || step2}
                    >
                    </Backdrop> : null
            }

            {
                (drawParcelLoader) ?
                    <Backdrop
                        sx={{ color: '#fff', zIndex: () => 97 }}
                        open={drawParcelLoader}
                    >
                        <CircularProgress />
                    </Backdrop> : null
            }
        </Box >
    );
}
