import React, { useState, useCallback, useEffect } from "react";
import { Box, Button, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { Upload, Error, Message, RuleFolder, Update, ContactSupport, SupportAgent, ChevronRight } from '@mui/icons-material';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { useDropzone } from "react-dropzone";
import { useSnackbar } from "../../../Contexts/SnackbarContext";
import PropTypes from "prop-types";
import audiList from "../../../Assets/Images/audi-img.png";
import DetailsJob from "./DetailsJob";
import JobActivity from "./JobActivity";
import TimeTracking from "./TimeTracking";
import DeliveryJob from "./DeliveryJob";
import RequirementsJob from "./RequirementsJob";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import config from '../../../Config/Config';
import CustomModal from "../../../Components/Modal/Custom/CustomModal";
import DeliveryModal from "./deliveryModal/DeliveryModal";
import ContactSupportModal from "./ContactSupportModal/ContactSupportModal";
import SpTransactionProgressModal from "../../SmartContracts/SpTransactionProgressModal";
import { GetApi, updateApi, postApi } from "../../../Api/Api";
import { getUserDataFromLocalStorage } from "../../../Services/localstorage.service";
import "./IndividualJobs.css";
import CommonBackdropLoader from "../../../Components/Common/CommonBackdropLoader";

const IndividualJobActivity = () => {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get('tab');

  const [contractData, setContractData] = useState({});
  const [openReqModal, setOpenReqModal] = useState(false);
  const [openDeliveryModal, setOpenDeliveryModal] = useState(false);
  const [openDateTimePicker, setOpenDateTimePicker] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [deliveryDateError, setDeliveryDateError] = useState('');
  const [isOpenContactSupport, setIsOpenContactSupport] = useState(false);
  const [deliveryMessage, setDeliveryMessage] = useState(null);
  const [deliveryDocuments, setDeliveryDocuments] = useState([]);
  const [delLoading, setDelLoading] = useState(false);
  const [compLoading, setCompLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState(tab ? Number(tab) : 0);
  const localData = getUserDataFromLocalStorage();
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [openTransactionModalMilestone, setOpenTransactionModalMilestone] = useState(false);
  const [openTransactionModalDelDtExtReq, setOpenTransactionModalDelDtExtReq] = useState(false);
  const [openTransactionModalDelDtExtReqMilestone, setOpenTransactionModalDelDtExtReqMilestone] = useState(false);

  const { showSnackbar } = useSnackbar();

  // Contact Support Modal
  const handleContactSupport = () => {
    handleOpenContactModal();
  }

  /**
    * Function to open Contact Support Modal
    */
  const handleOpenContactModal = () => {
    setIsOpenContactSupport(true);
  };

  /**
 * Function to close Contact Support Modal
 */
  const handleCloseContactModal = () => {
    setIsOpenContactSupport(false);
  };


  /**
   * Function for confirm staking
   * @param {*} data 
   */
  const confirmStake = (data) => {
    createDelivery(data)
    // setOpenDeliveryModal(false);
    // setCompLoading(false);
    // setDelLoading(false);
  }

  /**
   * Function for confirm staking
   * @param {*} data 
   */
  const confirmStakeMilestone = (data) => {
    createDelivery(data)
    // setOpenDeliveryModal(false);
    // setCompLoading(false);
    // setDelLoading(false);
  }

  const confirmStakeDeliveryExtReq = (data) => {
    setOpenTransactionModalDelDtExtReq(false);
    setOpenReqModal(false);
    setDeliveryDate(null);
    setDeliveryDateError('');
    setLoading(true);
    setTimeout(() => {
      fetchData();
    }, 500);
  }

  const confirmStakeDeliveryExtReqMilestone = (data) => {
    setOpenTransactionModalDelDtExtReqMilestone(false);
    setOpenReqModal(false);
    setDeliveryDate(null);
    setDeliveryDateError('');
    setLoading(true);
    setTimeout(() => {
      fetchData();
    }, 500);
  }

  /**
     * Function to close Transection Modal
     */
  const handleModalClose = (data) => {
    setOpenTransactionModal(false);
    // setCompLoading(false);
    // setDelLoading(false);
  }

  /**
     * Function to close Transection Modal
     */
  const handleModalCloseMilestone = (data) => {
    setOpenTransactionModalMilestone(false);
    // setCompLoading(false);
    // setDelLoading(false);
  }

  const handleModalCloseDeliveryExtReq = () => {
    setOpenTransactionModalDelDtExtReq(false);
  }

  const handleModalCloseDeliveryExtReqMilestone = () => {
    setOpenTransactionModalDelDtExtReqMilestone(false);
  }


  const fetchData = useCallback(async () => {
    setLoading(true);
    let url = `/services/getContract?id=${jobId}`;
    GetApi(url).then((res) => {
      if (res?.status === 200) {
        if (res.data && res.data.data && res.data.data.length > 0) {
          setContractData(res.data.data[0]);
        } else {
          setContractData({});
        }
      }
      setLoading(false);
    })
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const handleDateChange = (newDate) => {
    if (newDate) {
      setDeliveryDate(newDate);
      setDeliveryDateError('');
    }
  }

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3, pl: 0 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //upload files in contract
  const uploadContractFiles = useCallback(async (files) => {
    let formData = new FormData();
    files?.forEach((val) => {
      formData.append("document", val);
    });
    let url = `/services/spvContract/${jobId}`;
    updateApi(url, formData).then((res) => {
      if (res?.status === 200) {
        if (res.data && res?.data?.code === 200) {
          fetchData();
          showSnackbar(res?.data?.message, 'success');
        } else {
          fetchData();
          showSnackbar(res?.data?.message, 'error');
        }
      }
    });
  }, []);

  // handle uploaded docs
  const onDrop = useCallback(
    (acceptedFiles) => {
      const pdfFilesArray = acceptedFiles.filter(
        (file) => file.type === "application/pdf"
      );
      uploadContractFiles(pdfFilesArray);
    },
    []
  );

  const { getRootProps, open, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: { "application/pdf": [".pdf"] },
    onDrop,
  });

  const sendDeliveryDateExtentionReq = () => {
    if (contractData?.project_term === 'project') setOpenTransactionModalDelDtExtReq(true);  //project delivery date ext req
    if (contractData?.project_term === 'milestone') setOpenTransactionModalDelDtExtReqMilestone(true);  // milestone delivery date ext req
    // let url = `/services/deliveryDateUpdateRequest/${jobId}`;
    // updateApi(url, { requested_delivery_date: deliveryDate }).then((res) => {
    //   if (res?.status === 200) {
    //     if (res?.data?.code === 200) {
    //       fetchData();
    //       showSnackbar(res?.data?.message, 'success');
    //       setOpenReqModal(false);
    //       setDeliveryDate(null);
    //       setDeliveryDateError('');
    //     } else if (res?.data?.code === 202) {
    //       showSnackbar(res?.data?.message, 'warning');
    //       setOpenReqModal(false);
    //     } else {
    //       showSnackbar(res?.data?.message, 'error');
    //       setOpenReqModal(false);
    //     }
    //   }
    // });
  }

  /**
   * Calling Api For the Delivery of the project
   */
  const createDelivery = async (data) => {
    let formData = new FormData();
    setCompLoading(true);
    setDelLoading(true);
    formData.append("spv_id", contractData?.serviceProviderData?.id);
    formData.append("message", deliveryMessage);
    deliveryDocuments?.forEach((val) => {
      formData.append("document", val);
    });
    postApi(`/services/createContractDelivery/${jobId}`, formData).then((res) => {
      if (res?.status === 200) {
        if (res.data && res.data.code === 200) {
          showSnackbar(res?.data?.message, 'success');
          setOpenDeliveryModal(false);
          setCompLoading(false);
          setDelLoading(false);
          fetchData();
        } else {
          showSnackbar(res?.data?.message, 'error');
          fetchData();
        }
      }
    })
  }

  const submitDelivery = (currentMilestone) => {
    if (contractData?.project_term === 'project') setOpenTransactionModal(true);  //submitContractDelivery

    if (contractData?.project_term === 'milestone') setOpenTransactionModalMilestone(true);  // submitMileStone
  }

  const handleChat = async () => {
    try {
      const payload = {
        userID: localData?.id,
        toUserID: contractData?.client_id,
        contract_id: jobId,
        chatName: contractData?.title
      }
      const res = await postApi(`/chat/spAddChat`, payload);
      if (res?.data && res?.data?.data) {
        navigate("/service-provider-dashboard/chats", {
          state: {
            user_id: contractData?.client_id,
            chat_id: res?.data?.data?.id
          },
        });
      }
    } catch (error) {
      console.log("dddd", error);
    }
  };

  return (
    <><Box className="individual-job-activity-container">
      <Box>
        <CommonBackdropLoader loading={loading}/>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} sm={12}>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Activity" {...a11yProps(0)} className={value === 0 ? "st-active" : "st-inactive"} />
                  <Tab label="Details" {...a11yProps(1)} className={value === 1 ? "st-active" : "st-inactive"} />
                  <Tab label="Requirements" {...a11yProps(2)} className={value === 2 ? "st-active" : "st-inactive"} />
                  <Tab label="Delivery" {...a11yProps(3)} className={value === 3 ? "st-active" : "st-inactive"} />
                  {/* <Tab label="Time Tracking" {...a11yProps(4)} className={value === 4 ? "st-active" : "st-inactive"} /> */}
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <JobActivity contractData={contractData} />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <DetailsJob contractData={contractData} />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <RequirementsJob contractData={contractData} fetchData={fetchData} />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <DeliveryJob contractData={contractData} />
              </CustomTabPanel>
              {/* <CustomTabPanel value={value} index={4}>
                <TimeTracking contractData={contractData} />
              </CustomTabPanel> */}
            </Box>
          </Grid>
          <Grid itme xs={12} md={4} sm={12}>
            <Box className="activity-right-section">
              <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography className="font-16 fw-500">Order Details</Typography>
                <Box className="text-blueGray-300 cursor-pointer">
                  <MoreVertIcon />
                </Box>
              </Stack>
              <Box className="order-list-box">
                <Box>
                  <img src={audiList} />
                </Box>
                <Box className="right-section">
                  <Typography className="font-16">
                    Auditing for {contractData?.title}
                  </Typography>
                  <Typography className="font-12 text-blueGray-300">
                    {config.CONTRACT_STATUS[contractData?.status]}
                  </Typography>
                </Box>
              </Box>
              <Box className="service-box">
                <Box className="service-container">
                  <Typography className="font-12">Service To</Typography>
                  <Typography className="font-12 text-blueGray-300">
                    {contractData?.clientData?.first_name} {contractData?.clientData?.last_name}
                  </Typography>
                </Box>
                <Box className="service-container">
                  <Typography className="font-12">Total Price</Typography>
                  <Typography className="font-12 text-blueGray-300">
                    $ {contractData?.project_fees}
                  </Typography>
                </Box>
                <Box className="service-container">
                  <Typography className="font-12">Order Number</Typography>
                  <Typography className="font-12 text-blueGray-300">
                    {contractData?.contract_id}
                  </Typography>
                </Box>
                <Box className="service-container">
                  <Typography className="font-12">Project Term</Typography>
                  <Typography className="font-12 text-blueGray-300">
                    {contractData?.project_term === 'milestone' && 'By milestone'}
                    {contractData?.project_term === 'project' && 'By project'}
                    {contractData?.project_term === 'hourly' && 'Hourly'}
                  </Typography>
                </Box>
              </Box>
              <Box className="action-div">
                <Typography className="font-16">Actions</Typography>
                <Box className="text-blueGray-300">
                  <Error />
                </Box>
              </Box>
              <Box className="deliver-order-box cursor-pointer" onClick={() => {
                if (contractData?.status === 3) {
                  setDeliveryMessage('');
                  setDeliveryDocuments([]);
                  setOpenDeliveryModal(true);
                } else {
                  if (contractData?.status === 5) {
                    showSnackbar('You already delivered this job', 'warning');
                  }
                  if (contractData?.status === 6) {
                    showSnackbar('This job is already completed', 'warning');
                  }
                  if (contractData?.status === 7) {
                    showSnackbar('This job is already cancelled', 'warning');
                  }
                }
              }}>
                <Typography className="font-14">
                  {contractData?.status === 3 ? 'Deliver Order' : 'Delivered' }
                </Typography>
                <RuleFolder />
              </Box>
              {/* Modal for create Delivery */}
              <DeliveryModal
                openDeliveryModal={openDeliveryModal}
                setOpenDeliveryModal={setOpenDeliveryModal}
                deliveryMessage={deliveryMessage}
                setDeliveryMessage={setDeliveryMessage}
                deliveryDocuments={deliveryDocuments}
                setDeliveryDocuments={setDeliveryDocuments}
                submitDelivery={submitDelivery}
                projectTerm={contractData?.project_term}
                delLoading={delLoading}
                compLoading={compLoading}
                milestoneData={contractData?.milestoneData}
              />
              <Box
                {...getRootProps({
                  className: "dropzone",
                })}
                className="upload-files-box cursor-pointer"
                onClick={() => {
                  if (contractData?.status === 3 || contractData?.status === 4 || contractData?.status === 5 || contractData?.status === 8) {
                    open();
                  } else {
                    if (contractData?.status === 5) {
                      showSnackbar('You already delivered this job', 'warning');
                    }
                    if (contractData?.status === 6) {
                      showSnackbar('This job is already completed', 'warning');
                    }
                    if (contractData?.status === 7) {
                      showSnackbar('This job is already cancelled', 'warning');
                    }
                  }
                }}
              >
                <input {...getInputProps()} />
                <Typography className="font-14">Upload files</Typography>
                <Upload />
              </Box>
              <Box className="upload-files-box cursor-pointer"
                onClick={() => {
                  if (contractData?.status === 3 || contractData?.status === 4 || contractData?.status === 5 || contractData?.status === 8) {
                    setOpenReqModal(true);
                  } else {
                    if (contractData?.status === 5) {
                      showSnackbar('You already delivered this job', 'warning');
                    }
                    if (contractData?.status === 6) {
                      showSnackbar('This job is already completed', 'warning');
                    }
                    if (contractData?.status === 7) {
                      showSnackbar('This job is already cancelled', 'warning');
                    }
                  }
                }}
              >
                <Typography className="font-14">Request Delivery Extension</Typography>
                <Update />
              </Box>
              {/* Modal for Delivery Extension request */}
              <CustomModal
                title={'Request Delivery Extension'}
                component={
                  <>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Box className="DatePicker SPAddEducation">
                        <DatePicker
                          open={openDateTimePicker}
                          onOpen={() => setOpenDateTimePicker(true)}
                          onClose={() => setOpenDateTimePicker(false)}
                          label="Delivery Date"
                          value={deliveryDate}
                          minDate={new Date(contractData?.delivery_date).setDate(new Date(contractData?.delivery_date).getDate() + 1)}
                          onChange={(e) => handleDateChange(e)}
                          onError={(newError) => setDeliveryDateError(newError)}
                          slotProps={{
                            textField: {
                              helperText: deliveryDateError,
                              onClick: (e) => setOpenDateTimePicker(true),
                              sx: {
                                '& .MuiFormHelperText-root': {
                                  color: 'red', // Change helper text color to red (or any desired color)
                                },
                              },
                            },
                          }}
                          disablePast
                        />
                      </Box>
                      <Box className="footer" mt={3}>
                        <Button onClick={() => {
                          if (deliveryDate) sendDeliveryDateExtentionReq();
                          else setDeliveryDateError('Please select date');
                        }} variant="contained">
                          Send
                        </Button>&nbsp;
                        <Button onClick={() => {
                          setOpenReqModal(false);
                        }} variant="contained">
                          Cancel
                        </Button>
                      </Box>
                    </LocalizationProvider>
                  </>
                }
                isOpen={openReqModal}
                setIsOpenModal={setOpenReqModal}
              />
              <Box className="upload-files-box cursor-pointer" onClick={() => handleChat()} style={{ cursor: "pointer" }}>
                <Typography className="font-14">Messages</Typography>
                <Message />
              </Box>
              <Box className="action-div cursor-pointer">
                <Typography className="font-16">Support</Typography>
              </Box>
              <Link to={`/service-provider-dashboard/faq`}>
                <Box className="faq-support-box cursor-pointer">
                  <Stack direction={'row'}>
                    <ContactSupport />&nbsp;&nbsp;&nbsp;
                    <Stack direction={'row'}>
                      <Box>
                        <Typography className="font-14">FAQ</Typography>
                        <Typography className="font-12">Have a Question?</Typography>
                      </Box>
                    </Stack>
                  </Stack>
                  <ChevronRight />
                </Box>
              </Link>

              <Box className="upload-files-box cursor-pointer" onClick={handleContactSupport}>
                <Stack direction={'row'}>
                  <SupportAgent />&nbsp;&nbsp;&nbsp;
                  <Box>
                    <Typography className="font-14">Contact Support </Typography>
                    <Typography className="font-12">Resolve Order issues?</Typography>
                  </Box>
                </Stack>
                <ChevronRight />
              </Box>
              {isOpenContactSupport && <ContactSupportModal onOpen={isOpenContactSupport} handleClose={handleCloseContactModal} contractId={contractData} />}

              {openTransactionModal ? <SpTransactionProgressModal identity={'submit-contract-delivery'} confirmStake={confirmStake} propData={contractData} handleModalClose={handleModalClose} openTransactionModal={openTransactionModal} /> : null}

              {openTransactionModalMilestone ? <SpTransactionProgressModal identity={'submit-milestone'} confirmStake={confirmStakeMilestone} propData={contractData} handleModalClose={handleModalCloseMilestone} openTransactionModal={openTransactionModalMilestone} /> : null}
              
              {openTransactionModalDelDtExtReq ? <SpTransactionProgressModal identity={'delivery-date-ext-req'} confirmStake={confirmStakeDeliveryExtReq} propData={{contract_blockchain_id: contractData?.blockchain_id, deliveryDate: new Date(deliveryDate).getTime(), contract_version: contractData?.contract_version }} handleModalClose={handleModalCloseDeliveryExtReq} openTransactionModal={openTransactionModalDelDtExtReq} /> : null}
              
              {openTransactionModalDelDtExtReqMilestone ? <SpTransactionProgressModal identity={'delivery-date-ext-req-milestone'} confirmStake={confirmStakeDeliveryExtReqMilestone} propData={{contract_blockchain_id: contractData?.blockchain_id, deliveryDate: new Date(deliveryDate).getTime(), contract_version: contractData?.contract_version, milestoneData: contractData?.milestoneData }} handleModalClose={handleModalCloseDeliveryExtReqMilestone} openTransactionModal={openTransactionModalDelDtExtReq} /> : null}

            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
    </>
  );
};

export default IndividualJobActivity;
