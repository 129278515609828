import React, { useState, useCallback, useEffect } from 'react';
import { Box, Button, Grid, Stack, TextField, Tooltip, Typography, Avatar, MenuItem, Select, InputLabel, FormControl, Badge, InputAdornment } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { GetApi } from '../../../Api/Api';
import AddPortfolioPopup from '../AddPortfolioPopup/AddPortfolioPopup';
import AddEducationPopup from '../AddEducationPopup/AddEducationPopup';
import { Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { Cancel } from '@mui/icons-material';
import { CircleCheckIcon } from "../../../Components/Images/Images"

const OnboardingProfessionalLeft = ({ onNext, onBack, onDataUpdate, stepTwoPreviousData, setSelectedCategoryGlobal }) => {
    const [title, setTitle] = useState('');
    const [stepSkills, setStepSkills] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedCategorySkillData, setSelectedCategorySkillData] = useState([]);
    const [selectedServicesData, setSelectedServicesData] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [details, setDetails] = useState([]);
    const [educationDetails, setEducationDetails] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupOpenEducation, setIsPopupOpenEducation] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const [showAllEducation, setShowAllEducation] = useState(false);
    const [languagesData, setLanguagesData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [finishButtonClicked, setFinishButtonClicked] = useState(false); // State to track "Finish" 
    const [organizationValue, setOrganizationValue] = useState(''); // State to store input value
    const [items, setItems] = useState([]); // State to store list itemsbutton click
    const [isUploadedDocument, setIsUploadedDocument] = useState(false);
    const [uploadedImage, setUploadedImage] = useState([]);
    const [deletedDoc, setDeletedDoc] = useState([]);
    const [docFiles, setDocFiles] = useState([]);

    /**
     * States for the Languages
     */
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [showProficiencyDropdown, setShowProficiencyDropdown] = useState(true); // Initially set to false
    const [previousPageData, setPreviousPageData] = useState([])
    const proficiencyOptions = ['Native / Bilingual', 'Intermediate', 'Proficient'];

    /**
     * Setting Skill sets according to the previous stape when user comes back from the step three
     */
    useEffect(() => {
        if (stepTwoPreviousData.length > 0 || stepTwoPreviousData !== undefined) {
            setPreviousPageData(stepTwoPreviousData)
        }
    }, [stepTwoPreviousData, stepTwoPreviousData?.selectedSkills])

    /**
     * Setting Data into the field in case when user comes back from the step three 
     */
    useEffect(() => {
        const selectedCategoryObject = categories?.find(category => category.id === previousPageData?.selectedCategory);

        if (previousPageData?.title && categories.length > 0) {
            setTitle(previousPageData?.title)
            setSelectedLanguages(previousPageData?.selectedLanguages)
            setDetails(previousPageData?.details)
            setEducationDetails(previousPageData?.educationDetails)
            setSelectedCategory(previousPageData?.selectedCategory)
            setSelectedSkills(previousPageData?.selectedSkills)
            // setStepSkills(
            //     previousPageData?.selectedSkills?.length > 0
            //         ? previousPageData?.selectedSkills?.map((skill) => skill.skill_name).join(', ')
            //         : previousPageData?.stepSkill
            // );
            setSelectedCategorySkillData(selectedCategoryObject?.skillData)
            setSelectedServicesData(previousPageData?.serviceData)
            setItems(previousPageData.organizationValue)
            setOrganizationValue(previousPageData.organizationValue)
            setUploadedImage(previousPageData.documents)
        }
    }, [previousPageData, categories])


    useEffect(() => {
        // Check for validation errors when form data changes
        if (finishButtonClicked) {
            validateForm();
        }
    }, [selectedLanguages, selectedCategory, educationDetails, selectedSkills, title, finishButtonClicked]);

    // Validation effect
    useEffect(() => {
        if (items?.length === 0) {
            setOrganizationValue(''); // Clear input value if the list is empty
        }
    }, [items]);

    useEffect(() => {
        if (title) {
            const dataCollection = {
                "title": title,
                "details": details,
                "educationDetails": educationDetails,
                "selectedLanguages": selectedLanguages,
                "category": categories,
                "selectedCategory": selectedCategory,
                "selectedSkills": selectedSkills,
                "stepSkill": stepSkills,
                "serviceData": selectedServicesData,
                "associated_organization": items,
                "Documents": uploadedImage
            }
            onDataUpdate(dataCollection)
        }
    }, [title])

    /**
     * Validation for the form
     * @returns validations
     */
    const validateForm = () => {
        const errors = {};

        // Title
        if (!title.trim()) {
            errors.title = "Title is required.";
        }

        // Education
        if (educationDetails?.length === 0) {
            errors.educationDetails = "Education details are required.";
        }

        // Languages
        if (selectedLanguages?.length === 0) {
            errors.selectedLanguages = "Languages are required.";
        }

        // Category
        if (!selectedCategory) {
            errors.selectedCategory = "Category is required.";
        }

        // Skills
        if (
            (selectedSkills?.length === 0 && (typeof stepSkills !== 'string' || !stepSkills.trim())) ||
            (!selectedSkills?.length === 0 && typeof stepSkills === 'string' && stepSkills.trim())
        ) {
            errors.stepSkills = "Skills are required.";
        }

        // if (selectedSkills?.length === 0 ) {
        //     errors.stepSkills = "Skills are required.";
        // }

        // Uploaded Document
        if (uploadedImage?.length === 0) {
            errors.uploadedImage = "Documents are required.";
        }

        // Portfolio
        if (details?.length === 0) {
            errors.details = "Portfolio details are required.";
        }

        setValidationErrors(errors);

        return Object.keys(errors).length === 0; // Return true if there are no validation errors
    };


    /**
     * callback function for the uploaded docs
     */
    const onDrop = useCallback(acceptedFiles => {
        const pdfFilesArray = acceptedFiles.filter(file => file.type === 'application/pdf');
        let dataDetail = [...uploadedImage, ...pdfFilesArray];
        setUploadedImage(dataDetail);

    }, [uploadedImage]);

    const { getRootProps, open, getInputProps } = useDropzone({
        noClick: true,
        noKeyboard: true,
        accept: { "image/jpeg": [".pdf"] },
        onDrop
    });


    /**
     * Function to delete Uploaded Document
     * @param {*} file 
     */
    const handleDeleteImage = async (file) => {
        if (file) {
            const newDocFile = docFiles.filter((docFile) => {
                setDeletedDoc([...deletedDoc, docFile.id]);
                return docFile.image !== file;
            });
            setDocFiles(newDocFile);
        }
        const newUploadedImages = uploadedImage.filter((image) => image.path !== file.path);
        setUploadedImage(newUploadedImages);
    };


    // preparing to show uploaded docs
    const files = uploadedImage?.map((file) => (
        <Box className="showUploadedDocuments">
            <Box key={file.path} className="showUploadedDocuments-wrap-onbording" display={'flex'} alignItems={"center"}>
                <Box className="fileName" mr={1}>
                    {file?.path?.includes("services/uploads/") ? file.original_name : file.name}
                </Box>
                <Cancel onClick={() => handleDeleteImage(file)} className="removeIcon" />
            </Box>
        </Box>
    ));

    const isUploadDocuments = files?.length > 0;


    /**
     * Function to call Api on onsubmit button
     */
    const handleNext = async () => {

        // Set the finish button clicked state
        setFinishButtonClicked(true);

        // Only finish if there are no validation errors
        if (validateForm()) {
            onNext()

            const dataCollection = {
                "title": title,
                "details": details,
                "educationDetails": educationDetails,
                "selectedLanguages": selectedLanguages,
                "category": categories,
                "selectedCategory": selectedCategory,
                "selectedSkills": selectedSkills,
                "stepSkill": stepSkills,
                "serviceData": selectedServicesData,
                "associated_organization": items,
                "Documents": uploadedImage
            }

            onDataUpdate(dataCollection)
        }
    };

    /**
     * Calling Api for the Languages
     */
    useEffect(() => {
        // Api to get categoryData
        async function getLanguagesData() {
            try {
                const res = await GetApi("/admin/language");
                if(res?.data?.data && res?.data?.data.length > 0){
                    const updatedData = res?.data?.data.map((item) => ({ ...item, isShow: true}));
                    setLanguagesData(updatedData);
                }
            } catch (error) {
                console.log(error);
            }
        }
        getLanguagesData();
    }, []);

    useEffect(() => {
        setShowProficiencyDropdown(new Array(selectedLanguages?.length).fill(true));
        if(languagesData.length > 0){
            if(selectedLanguages && selectedLanguages.length > 0){
                const tempArr = [...languagesData];
                const updatedArr = tempArr.map((item) => {
                    const check = selectedLanguages.find((itm) => itm?.name === item?.language_name);
                    if(check){
                        return { ...item, isShow: false };
                    }
                    return { ...item, isShow: true };
                });
                setLanguagesData(updatedArr);
            }else{
                const tempArr = [...languagesData];
                setLanguagesData(tempArr.map((item) => ({ ...item, isShow: true})));
            }
        }
    }, [selectedLanguages]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSelectLanguage = (language) => {
        setSelectedLanguages([...selectedLanguages, { name: language, proficiency: 'Native / Bilingual' }]);
        setSearchTerm('');
    };

    const handleDeleteLanguage = (index) => {
        const newLanguages = [...selectedLanguages];
        newLanguages.splice(index, 1);
        setSelectedLanguages(newLanguages);
    };

    const handleSelectProficiency = (index, proficiency) => {
        const newLanguages = [...selectedLanguages];
        newLanguages[index].proficiency = proficiency;
        setSelectedLanguages(newLanguages);
    };

    /**
    * Function to handle Edit Button functionality
     * @param {*} index 
     */
    const handleEditProficiency = (index) => {
        setShowProficiencyDropdown((prev) => {
            const newState = [...prev];
            newState[index] = !newState[index]; // Toggle the state
            return newState;
        });
    };

    // Function to handle input change
    const handleChange = (event) => {
        setOrganizationValue(event.target.value); // Update input value state

    };

    // Function to handle adding item to the list
    const addItem = () => {
        if (organizationValue?.trim() !== '') {
            setItems([...items, organizationValue]); // Add item to the list
            setOrganizationValue(''); // Clear input value
            // Clear validation errors for the list
            setValidationErrors(prevErrors => {
                const { items: itemsError, ...otherErrors } = prevErrors;
                return otherErrors;
            });
        }
    };

    // Function to handle deleting an item from the list
    const deleteItem = (index) => {
        const updatedItems = [...items];
        updatedItems.splice(index, 1); // Remove item at the given index
        setItems(updatedItems); // Update the list
    };

    // Function to handle key press event (Enter key)
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            addItem(); // Call addItem function when Enter key is pressed
        }
    };


    /**
     * Rendring Language row list
     */
    const LanguageRow = React.memo(({ language, index }) => {
        return (
            <Box className="list-data">
                <Box className="data">
                    <Typography className='lang-name'>{language?.name}</Typography>
                    <Box className="select-lang">
                        {showProficiencyDropdown[index] ? (
                            <Select
                                value={language.proficiency}
                                onChange={(e) => handleSelectProficiency(index, e.target.value)}
                                className="proficiency-select"
                                displayEmpty
                            >
                                <MenuItem value="" disabled>Select Proficiency</MenuItem>
                                {proficiencyOptions.map((proficiency, index) => (
                                    <MenuItem key={index} value={proficiency}>
                                        {proficiency}
                                    </MenuItem>
                                ))}
                            </Select>
                        ) : (
                            <Typography className={language?.proficiency ? "edit-lang" : ""}>{language.proficiency}</Typography>
                        )}
                    </Box>
                    <Box className="icons">
                        <Box sx={{ width: "100%" }}>
                            <span className='data-icon' />
                            <DeleteIcon className='data-icon' onClick={() => handleDeleteLanguage(index)} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        );
    }, (prevProps, nextProps) => {
        return prevProps.language === nextProps.language;
    });

    useEffect(() => {
        // Api to get categoryData
        async function getCategoryData() {
            try {
                const res = await GetApi("/services/spCategory");
                setCategories(res?.data?.data);
            } catch (error) {
                console.log(error);
            }
        }
        getCategoryData();
    }, [])

    // Api to get skills
    async function getSkillsData() {
        try {
        if(selectedCategory){
            const res = await GetApi(`/services/spSkills?sp_category_id=${selectedCategory}&searchString=${stepSkills}`);
            setSelectedCategorySkillData(res?.data?.data);
        }
        } catch (error) {
        console.log(error);
        }
    }
    useEffect(() => {
        getSkillsData();
    }, [selectedCategory, stepSkills]);

    const handleSaveDetailsExprience = (newDetails) => {
        // Add the new details to the existing list
        setDetails([...details, newDetails]);
    };

    const handleSaveDetailsEducation = (newDetails) => {
        // Add the new details to the existing list
        setEducationDetails([...educationDetails, newDetails]);
    };

    /**
       * FUnction to select Multiple Skills 
       * @param {*} skill 
       */
    const handleSkillSelection = (skill) => {
        const skillId = skill.id;
        const skillName = skill.skill_name;

        // Check if the skill is already selected
        if (!selectedSkills.some((selectedSkill) => selectedSkill.skill_name === skillName)) {
            setSelectedSkills([...selectedSkills, { id: skillId, skill_name: skillName }]);
            // setStepSkills((prevSkills) => {
            //     const newSkills = prevSkills && prevSkills.length > 0 ? `${prevSkills}, ${skillName} ` : skillName;
            //     return newSkills;
            // });
        }else{
            // If skill is already selected, remove it from the array
            setSelectedSkills(
                selectedSkills.filter((selectedSkill) => selectedSkill.id !== skillId)
            );
            // setStepSkills((prevSkills) => {
            //     // Remove the skill from the comma-separated string
            //     const skillArray = prevSkills.split(', ').filter(skill => skill.trim() !== skillName.trim());
            //     return skillArray.join(', ');
            // });
        }
        setStepSkills('')
    };

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        setStepSkills(inputValue);

        // Remove only the deselected skill from selectedSkills
        if (inputValue.trim() !== '') {

            setValidationErrors(prevErrors => {
                const { stepSkills: stepSkillsError, ...otherErrors } = prevErrors;
                return otherErrors;
            });
            const inputSkills = inputValue.split(',').map(skill => skill.trim());
            const removedSkill = selectedSkills.find(skill => !inputSkills.includes(skill.skill_name));
            if (removedSkill) {
                const updatedSelectedSkills = selectedSkills.filter(skill => skill.id !== removedSkill.id);
                setSelectedSkills(updatedSelectedSkills);
            }
        }
    };

    /**
     * Function to extract skill data from category and handlechange.
     * @param {*} event 
     */
    const handleCategoryChange = async (event) => {
        const categoryTitle = event.target.value;
        setSelectedCategory(categoryTitle);
        setStepSkills([])
        setSelectedSkills([])
        // Find the selected category object
        const selectedCategoryObject = categories?.find(category => category.id === categoryTitle);
        setSelectedCategoryGlobal(selectedCategoryObject);
        // Extract skillData from the selected category object
        if (selectedCategoryObject) {
            setSelectedCategorySkillData(selectedCategoryObject.skillData);
            setSelectedServicesData(selectedCategoryObject.serviceData)
        }
    };

    /**
     * Function to open AddExperience Modal
     */
    const handleOpenPopupExperience = () => {
        setIsPopupOpen(true);
    };

    /**
     * Function to open AddEducation Modal
     */
    const handleOpenPopupEducation = () => {
        setIsPopupOpenEducation(true);
    };

    /**
   * Function to close AddExperience Modal
   */
    const handleClosePopupExperience = () => {
        setIsPopupOpen(false);
    };

    /**
   * Function to close AddExperience Modal
   */
    const handleClosePopupEducation = () => {
        setIsPopupOpenEducation(false);
    };

    /**
     * Onclick function for Add Experience
     */
    const handleExperience = () => {
        handleOpenPopupExperience()
    }

    /**
     * Onclick function for Add Experience
     */
    const handleEducation = () => {
        handleOpenPopupEducation()
    }


    return (
        <Box className='professional-info-left-container'>
            <Typography className='font-36 heading'>Professional Information</Typography>
            <Typography className='font-18 sub-heading'>We need to get a sense of your education, experience and skills. It’s quickest to import your information — you can edit it before your profile goes live.</Typography>

            {/* Title Section */}
            <Box className="proff-info-box" mt={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3} sm={12}>
                        <Typography className='font-22 title'>
                            Your Title
                        </Typography>
                        <Typography className='font-12 sub-title'>Stand out by describing your expertise in your own words.</Typography>
                    </Grid>
                    <Grid item xs={12} md={9} sm={12}>
                        <TextField
                            id="outlined-basic"
                            label="Your Professional Role"
                            variant="outlined"
                            placeholder='Software Engineer | Javascript | iOS'
                            className='input-text'
                            value={title || ''} // Use empty string as default if title is undefined
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        {validationErrors.title && <Typography variant="body2" color="error">{validationErrors.title}</Typography>}
                    </Grid>
                </Grid>
            </Box>

            {/* Work experience section */}
            <Box className="proff-info-box" mt={3}>
                <Grid container spacing={2}>
                    <Grid item   xs={12} md={3}>
                        <Typography className='font-22 title'>
                            Portfolio
                        </Typography>
                        <Typography className='font-12 sub-title'>Service Providers who add their portfolio are twice as likely to get work.</Typography>
                    </Grid>
                    <Grid item  xs={12} md={9} sm={12}>
                        {(details && details.length > 0) && (
                            <>
                                <Box display={"flex"} flexWrap="wrap">
                                    {details.slice(0, showAll ? details.length : 3).map((experience, index) => (
                                        <Box key={index} className='work-exper-box work-ex-box' >
                                            {/* {experience.image &&
                                                <img
                                                    src={URL.createObjectURL(experience.image)}
                                                    alt="Uploaded"
                                                    className="uploaded-image"
                                                />}
                                            <Typography className='font-14' pt={2}>{experience.title}</Typography> */}

                                            <Box className="pf-card-wrap">
                                                <Box className="pf-image-wrap" >
                                                    <Avatar className="pf-image" src={URL.createObjectURL(experience.portfolio_image)} alt={title} />
                                                </Box>
                                                <Box className="pf-title-wrap">
                                                    <Typography variant="subtitle1" className="pf-title font-18" mb={1.5}>{experience.portfolio_title}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    ))}
                                    {/* Add Experience section */}
                                    <Box className='work-exper-box' onClick={handleExperience} ml={2} mb={2} >
                                        <Box display='flex' justifyContent='end'>
                                            <Box className='add-icon-box'>
                                                <AddIcon className='icon' />
                                            </Box>
                                        </Box>
                                        <Typography className='title font-16'>Add Portfolio</Typography>
                                    </Box>
                                    {details.length > 3 && (
                                        <Box className='' onClick={() => setShowAll(!showAll)} ml={2} >
                                            <Box >
                                                <Link>
                                                    <Typography onClick={() => setShowAll(!showAll)} className='title font-16'>{showAll ? 'View Less' : 'Click here to view more Details..'}</Typography>
                                                </Link>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            </>
                        )}
                        {!details || details.length === 0 && (
                            <Box className='work-exper-box' onClick={handleExperience}>
                                <Box display='flex' justifyContent='end'>
                                    <Box className='add-icon-box'>
                                        <AddIcon className='icon' />
                                    </Box>
                                </Box>
                                <Typography className='title font-16'>Add Portfolio/Case Study</Typography>
                            </Box>
                        )}
                    </Grid>
                </Grid>
                {validationErrors.details && <Typography variant="body2" color="error">{validationErrors.details}</Typography>}
            </Box>

            {/* Education Section */}
            <Box className='proff-info-box' mt={3}>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12}>
                        <Typography className='font-22 title'>
                            Education
                        </Typography>
                        <Typography className='font-12 sub-title'>You don’t have to have a degree. Adding any relevant education helps make your profile more visible.</Typography>
                    </Grid>
                    <Grid item xs={12} md={9} sm={12}>
                        {(educationDetails && educationDetails.length > 0) && (
                            <>
                                <Box display={"flex"} flexWrap="wrap">
                                    {educationDetails.slice(0, showAllEducation ? educationDetails.length : 3).map((education, index) => (
                                        <Box key={index} className='work-exper-box'>
                                            <Typography className='font-14' pt={2}>School : {education.school}</Typography>
                                            <Typography className='font-14' pt={2}>Field: {education.fieldOfStudy}</Typography>
                                            <Typography className='font-14' pt={2}>Start Date : {education.startDate}</Typography>
                                            <Typography className='font-14' pt={2}>End Date : {education.endDate}</Typography>
                                        </Box>
                                    ))}

                                    {/* Add Education section */}
                                    <Box className='work-exper-box' onClick={() => handleEducation()} ml={2} mb={2} >
                                        <Box display='flex' justifyContent='end'>
                                            <Box className='add-icon-box'>
                                                <AddIcon className='icon' />
                                            </Box>
                                        </Box>
                                        <Typography className='title font-16'>Add Education</Typography>
                                    </Box>
                                    {educationDetails.length > 3 && (
                                        <Box className='' onClick={() => setShowAllEducation(!showAllEducation)} ml={2} >
                                            <Box >
                                                <Link>
                                                    <Typography onClick={() => setShowAllEducation(!showAllEducation)} className='title font-16'>{showAllEducation ? 'View Less' : 'Click here to view more Details..'}</Typography>
                                                </Link>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            </>
                        )}
                        {(!educationDetails || educationDetails.length === 0) && (
                            <Box className='work-exper-box' onClick={() => handleEducation()}>
                                <Box display='flex' justifyContent='end'>
                                    <Box className='add-icon-box'>
                                        <AddIcon className='icon' />
                                    </Box>
                                </Box>
                                <Typography className='title font-16'>Add Education</Typography>
                            </Box>
                        )}
                    </Grid>
                </Grid>
                {validationErrors.educationDetails && <Typography variant="body2" color="error">{validationErrors.educationDetails}</Typography>}
            </Box>

            {/* Language Section */}
            <Box className="proff-info-box search-lang" mt={3}>
                <Grid container spacing={2}>
                    <Grid item  xs={12} md={3}>
                        <Typography className='font-22 title'>
                            Languages
                        </Typography>
                        <Typography className='font-12 sub-title'>English is a must, but do you speak any other languages?</Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <TextField id="outlined-basic" label="Search languages" className="search-lang" InputLabelProps={{ shrink: true }} variant="outlined" placeholder='E.g. English' onChange={handleSearch} value={searchTerm} />
                        <Box mb={2}>
                            {searchTerm && (
                                <Box mt={2} className="search-list">
                                    {languagesData.length && languagesData
                                        .filter((language) => language.language_name
                                            .toLowerCase().includes(searchTerm.toLowerCase()))
                                        .filter((language) => language.isShow == true)
                                        .map((language) => 
                                            (<MenuItem className='onboarding-menuItem' key={language.id} onClick={() => handleSelectLanguage(language.language_name)}>
                                                <Typography>{language.language_name}</Typography>
                                            </MenuItem>)
                                        )}
                                </Box>
                            )}

                        </Box>
                        <Box mt={2} className="language-header">
                            <Box className="header">
                                <Box className="header-heading">
                                    <Typography className='font-14'>Language</Typography>
                                </Box>
                                <Box className="header-heading">
                                    <Typography className='font-14'>Proficiency</Typography>
                                </Box>
                                <Box className="header-heading action">
                                    <Typography className='font-14 '>Action</Typography>
                                </Box>
                            </Box>
                            <Box>
                                {selectedLanguages?.map((language, index) => (
                                    <LanguageRow
                                        key={`${language.language_name
                                            }-${index}`}
                                        language={language}
                                        index={index}
                                    />
                                ))}
                            </Box>

                        </Box>

                    </Grid>
                </Grid>
                {validationErrors.selectedLanguages && <Typography variant="body2" color="error">{validationErrors.selectedLanguages}</Typography>}
            </Box>

            {/* Category Section */}
            <Box className="proff-info-box " mt={3}>
                <Grid container spacing={2}>
                    <Grid item  xs={12} md={3}>
                        <Typography component="p" className='font-22'>Category</Typography>
                        <Typography component="p" className='font-16'>Current role category.</Typography>
                        <Typography component="p" className='font-12'>Your skill set will reflect according to the category selected.</Typography>
                    </Grid>
                    <Grid item  xs={12} md={9}>
                        {/* <Box className="select-box">
                            <FormControl>
                                <InputLabel id="select-label">Select Category</InputLabel>
                                <Select
                                    value={selectedCategory}
                                    onChange={handleCategoryChange}
                                    displayEmpty
                                    fullWidth
                                    id="demo-simple-select"
                                    label="Select Category"
                                    labelId="select-label"
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 200, // Set maximum height for the dropdown
                                            },
                                        },
                                    }}
                                >
                                    {categories?.map(category => (
                                        <MenuItem key={category.id} value={category.id}>{category.title}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box> */}
                        <Box className="select-input-box">
                          <TextField
                              id="outlined-select-basic"
                              value={selectedCategory}
                              onChange={handleCategoryChange}
                              displayEmpty
                              select
                              label="Select Category"
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              className="category-select"
                              SelectProps={{
                                MenuProps: {
                                  PaperProps: {
                                    style: {
                                      maxHeight: 200, // Limit height of the dropdown
                                      overflowY: 'auto',  // Enable scroll for overflowed content
                                    },
                                  },
                                }
                              }}
                          >
                              {categories?.map(category => (
                                  <MenuItem key={category.id} value={category.id}>{category.title}</MenuItem>
                              ))}
                          </TextField>
                        </Box>
                    </Grid>
                </Grid>
                {validationErrors.selectedCategory && <Typography variant="body2" color="error">{validationErrors.selectedCategory}</Typography>}
            </Box>

            {/* Skill Section */}
            <Box className="proff-info-box search-lang" mt={3}>
                <Grid container spacing={2}>
                    <Grid item  xs={12} md={3}>
                        <Typography className='font-22 title'>
                            Skills
                        </Typography>
                        <Typography className='font-12 sub-title'>Your skills show clients what you can offer, and help us choose which jobs to recommend to you</Typography>
                    </Grid>
                    <Grid item  xs={12} md={9}>
                        <Box className="input-box">
                            <TextField
                                id="outlined-basic"
                                label="Select skills"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                placeholder='E.g. Auditor needed for real estate project'
                                value={stepSkills}
                                // onChange={(e) => setStepSkills(e.target.value)}
                                // onChange={handleInputChange}
                                onChange={(e) => setStepSkills(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && e.target.value.length > 3) {
                                        handleSkillSelection({id: 0, skill_name: e.target.value});
                                    }
                                }}
                                disabled={selectedCategory ? false : true}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title={'After typing skill, please press enter'} arrow>
                                                <InfoOutlinedIcon style={{color: "#fff"}}/>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box className="button-div-bg-none skill-btn-badge" mt={2}>
                        {selectedSkills && selectedSkills.length > 0 &&
                            selectedSkills.map((skill, index) => (
                            <Badge 
                                badgeContent={<CloseIcon className="font-12"/>} 
                                color="error"
                                overlap="rectangular"
                                onClick={()=> {
                                let tempArray = [...selectedSkills];
                                tempArray = tempArray.filter((_, idx) => index !== idx);
                                setSelectedSkills(tempArray);
                                }}
                            >
                                <Button
                                key={skill.id}
                                className={"skill-button"}
                                variant="outlined"
                                startIcon={<AddIcon />}
                                onClick={() => handleSkillSelection(skill)}
                                >
                                {skill.skill_name}
                                </Button>
                            </Badge>
                        ))}
                        </Box>
                        <Box className="button-div-bg-none">
                        {selectedSkills && selectedSkills.length > 0 && <br />}
                            {selectedCategorySkillData &&
                                selectedCategorySkillData.filter(item => !new Set(selectedSkills.map(itm => itm.skill_name)).has(item.skill_name)).map((skill) => (
                                <Button
                                    key={skill.id}
                                    variant="outlined"
                                    startIcon={<AddIcon />}
                                    onClick={() => handleSkillSelection(skill)}
                                >
                                    {skill.skill_name}
                                </Button>
                            ))}
                        </Box>
                    </Grid>
                </Grid>
                {validationErrors.stepSkills && <Typography variant="body2" color="error">{validationErrors.stepSkills}</Typography>}
            </Box>


            {/* Associated Organization  Section */}
            <Box className="proff-info-box search-lang" mt={3}>
                <Grid container spacing={2}>
                    <Grid item  xs={12} md={3}>
                        <Typography className='font-22 title'>
                            Associated Organization
                        </Typography>
                        <Typography className='font-12 sub-title'>(Optional) If you are a part of an organization that is on RACE, you can link your profile to the organization’s profile.</Typography>
                    </Grid>
                    <Grid item  xs={12} md={9}>
                        <Tooltip title='After typing organization name.Please enter' placement='left' arrow>
                            <InfoOutlinedIcon style={{ float: "right" }} />
                        </Tooltip>
                        <Box className="input-box">
                            <TextField
                                id="outlined-basic"
                                label="Enter Organization Account "
                                placeholder='E.g. ABC Organization'
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                value={organizationValue}
                                onChange={handleChange}
                                onKeyDown={handleKeyPress}
                            />
                        </Box>
                        {items && items?.map((item, index) => (<Box className="data assoc-data">
                            <Box className="list-data list-wrap">

                                <Typography className='lang-name'>{item}</Typography>
                                <Box className="icons">
                                    <Box sx={{ width: "100%", display: 'flex' }}>
                                        <DeleteIcon className='data-icon' onClick={() => deleteItem(index)} />
                                    </Box>

                                </Box>
                            </Box>

                        </Box>
                        ))}
                    </Grid>
                </Grid>
            </Box>

            {/* Upload Document Section */}
            <Box className='proff-info-box' mt={3}>
                <Grid container spacing={2}>
                    <Grid item  xs={12} md={3}>
                        <Typography className='font-22 title'>
                            Upload Documents
                        </Typography>
                        <Typography className='font-12 sub-title'>Upload documents to highlight your track record and experience e.g. Portfolio, Resume <br /><br />
                            Supported Formats: PDF</Typography>
                    </Grid>
                    <Grid item  xs={12} md={3}>
                        <Box  {...getRootProps({
                            className: "dropzone",
                        })}>
                            <input {...getInputProps()} />
                            <Stack direction="row" spacing={0} flexWrap={'wrap'}>
                                <Box className='work-exper-box work-upload-doc' onClick={open}>
                                    <Box display='flex' justifyContent='end'>
                                        <Box className='add-icon-box'>
                                            <AddIcon className='icon' />
                                        </Box>
                                    </Box>
                                    <Typography className='title font-16'>Upload Document</Typography>
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        {files !== "" ? (
                            <Box
                                sx={{
                                    paddingBottom: "8px",
                                    overflow: "hidden",
                                }}
                            >
                                {files?.map((fileName, ind) => {
                                    return (
                                        <>  <Box alignItems={"center"} mt={1} mb={2} key={ind} sx={{ display: 'flex' }}>
                                            <Avatar
                                                src={CircleCheckIcon}
                                                alt="Icon"
                                                sx={{ width: 24, height: 24 }}

                                            />
                                            <Box
                                                className="dD-file-name text-black"
                                                ml={1}
                                                sx={{ color: '#fff' }}
                                            >
                                                {fileName}
                                            </Box>
                                        </Box>
                                        </>
                                    )
                                })
                                }
                                {isUploadedDocument && !isUploadDocuments && (
                                    <Typography variant='body2' style={{ color: '#d32f2f' }}>Upload document is required</Typography>
                                )}
                            </Box>
                        ) : (
                            ""
                        )}
                    </Grid>
                </Grid>
                {validationErrors.uploadedImage && <Typography variant="body2" color="error">{validationErrors.uploadedImage}</Typography>}
            </Box>

            <Box mt={5}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Box className='go-back-btn'>
                        <Button startIcon={<ArrowBackIcon />} onClick={onBack}>
                            Go Back
                        </Button>
                    </Box>
                    <Box className="continue-btn">
                        <Button
                            variant="contained"
                            startIcon={<ArrowForwardIcon />}
                            className='btn-rounded btn-large'
                            style={{ background: "var(--golden-gradient)" }}
                            onClick={handleNext}
                        >
                            Continue
                        </Button>
                    </Box>
                </Stack>
            </Box>

            {isPopupOpen && <AddPortfolioPopup open={isPopupOpen} handleClose={handleClosePopupExperience} onSave={handleSaveDetailsExprience} />}

            {isPopupOpenEducation && <AddEducationPopup open={isPopupOpenEducation} handleClose={handleClosePopupEducation} onSave={handleSaveDetailsEducation} educationDetails={educationDetails} />}

        </Box>
    )
}

export default OnboardingProfessionalLeft;
