import React, { useEffect, useState } from 'react';
import { styled } from "@mui/material/styles"
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Chip, Box, Paper, TableRow, TableHead, TableContainer, Table, TableBody, Stack, Grid, Modal, IconButton, Backdrop, CircularProgress } from '@mui/material';
import { ImageOutlined, RemoveRedEye } from '@mui/icons-material';
import { DOCUMENT_SIGN_STATUS, OFFER_STATUS } from '../../constants.js'
import { GetApi, GetApiParam } from '../../Api/Api.js';
import { useNavigate } from 'react-router-dom';
import '../Transactions/Transactions.css'

// Styles for table columns
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#05131B',
        color: '#94A3B8',
        borderBottom: '0px',
        '&:first-of-type': {
            borderRadius: '16px 0px 0px 16px',
        },
        '&:last-of-type': {
            borderRadius: '0px 16px 16px 0px',
        },
    },

    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: theme.palette.common.white,
        borderBottom: '0px'
    },
}));

// Styles for table rows
const StyledTableRow = styled(TableRow)(({ theme }) => ({
}));

// modal styles
const styleModal = { position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };

// Creating chips according to document status
const DOCUMENT_SIGN_STATUS_TEXT = {
    [DOCUMENT_SIGN_STATUS.PENDING]: <Chip sx={{ marginTop: 2, marginBottom: 2, color: "#13202D", bgcolor: "#F6CFB7", borderRadius: "8px", }} label={'Pending Signature'} />,
    [DOCUMENT_SIGN_STATUS.SIGNED]: <Chip sx={{ marginTop: 2, marginBottom: 2, color: "#13202D", bgcolor: "#34D399", borderRadius: "8px", }} label={'Uploaded'} />,
    [DOCUMENT_SIGN_STATUS.REJECTED]: <Chip sx={{ marginTop: 2, marginBottom: 2, color: "#13202D", bgcolor: "#FF0000", borderRadius: "8px", }} label={'Rejected'} />,
}

const TaxDocumentPanel = ({ toSearch, handleLoadingTexPanel, isUpdatedList }) => {
    // Initialize states
    const [documentsData, setDocumentsData] = useState([]);
    const [backupDocumentsData, SetBackupDocumentsData] = useState([]);
    const [view, setView] = useState(false);
    const [file, setFile] = useState("");
    const [type, setType] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);

    /**
     * format date
     */
    const formatDate = (inputDateString) => {
        // Parse the input date string
        const inputDate = new Date(inputDateString);

        // Format the date to your desired output
        const formattedDate = `${inputDate.getDate()}.${inputDate.getMonth() + 1}.${inputDate.getFullYear()}, ${inputDate.getHours()}:${inputDate.getMinutes() < 10 ? '0' : ''}${inputDate.getMinutes()}`;
        return formattedDate
    }


    // Here we are getting signed documents list
    useEffect(() => {
        let u = localStorage.getItem('user_data');
        let User = JSON.parse(u);
        const getSigningDocumentData = async () => {
            try {
                handleLoadingTexPanel(true)
                let res = await GetApi(`/proposer/getAssetReportDoc/${User.id}`);
                if (res?.data?.data.asset_reportDocument && res.data?.data?.asset_reportDocument?.length) {
                    // Update the state with the modified data
                    const filterData = res?.data?.data?.asset_reportDocument.filter(item =>
                        item.title.toLowerCase() === "text_document"
                    );
                    setDocumentsData(filterData);
                    SetBackupDocumentsData(filterData);
                }
                handleLoadingTexPanel(false)
            } catch (error) {
                console.log('error', error)
                handleLoadingTexPanel(false)
            }
        }
        if (User.id) {
            getSigningDocumentData();
        }
    }, [isUpdatedList])
    /**
     * open modal to display doc content
     * @param {Array} documentData 
     * @returns {void}
     */
    const viewDoc = async (documentData, mode, index) => {
        handleLoadingTexPanel(true)
        try {
            if (documentData?.images.path) {
                const fileName = await fetch(
                    `${process.env.REACT_APP_IMAGE_URL}${documentData.images.path ? documentData.images.path : ""
                    }`
                );
                const templateBuffer = await fileName.arrayBuffer();
                const mimeType = 'application/pdf'; // Since viewDoc is only for PDFs

                const templateBlob = new Blob([templateBuffer], { type: mimeType });
                const templateUrl = URL.createObjectURL(templateBlob);
                setFile(templateUrl);
                setType(documentData.images.type);
                setView(true);
                setOpenModal(true);
                handleLoadingTexPanel(false)
            }
        }
        catch (error) {
            handleLoadingTexPanel(false)
            console.log('error', error)
        }

    };

    /**
     * handle modal close
     */
    const handleClose = () => { setOpenModal(false); setView(false) };

    // Whenever category id changed, we are filter data according to category id selected 
    useEffect(() => {
        /**
         * searches in the data [title]
         * @param {string} toSearch 
         * @returns {void}
         */
        const searchInData = (search) => {
            if (search.length >= 2) {
                setDocumentsData(backupDocumentsData.filter(item => {
                    const assetTitle = item?.assetsData?.asset_title?.toLowerCase();
                    const originalName = item?.images?.original_name?.toLowerCase();
                    return (
                        assetTitle?.includes(search.toLowerCase()) ||
                        originalName?.includes(search.toLowerCase())
                    );
                }))
            } else if (search.length < 2) {
                setDocumentsData(backupDocumentsData);
            }
        }

        searchInData(toSearch);
    }, [toSearch]);

    return (
        <>
            <TableContainer component={Paper} className='TableContainer'>
                <Table aria-label="customized table">
                    <TableHead className='TableHeader'>
                        <TableRow>
                            <StyledTableCell>Document Name</StyledTableCell>
                            <StyledTableCell align="left">Project Name</StyledTableCell>
                            <StyledTableCell align="center">Category</StyledTableCell>
                            <StyledTableCell align="center">Date</StyledTableCell>
                            <StyledTableCell align="center">Status</StyledTableCell>
                            <StyledTableCell align="center" style={{ width: '120px' }}>Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documentsData?.map((row, i) => (
                            <StyledTableRow className='TableRow' key={`${row.assetsData.asset_title}${i}`}>
                                <StyledTableCell component="th" scope="row">{row.images.original_name}</StyledTableCell>
                                <StyledTableCell align="left" width={'30%'}>{row.assetsData.asset_title}</StyledTableCell>
                                <StyledTableCell align="center">
                                    <Chip
                                        sx={{ marginTop: 2, marginBottom: 2, color: "#FFFFFF", bgcolor: "#13202D", fontSize: "12px", borderRadius: "8px", }}
                                        label={row.assetsData.assets_category.title}
                                        icon={<ImageOutlined sx={{ color: "#FFFFFF !important" }} />}
                                    />
                                </StyledTableCell>
                                <StyledTableCell align="center">{formatDate(row.createdAt)}</StyledTableCell>
                                <StyledTableCell align="center">
                                    {DOCUMENT_SIGN_STATUS_TEXT[2]}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Stack
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="baseline"
                                        spacing={2}
                                    >
                                        <IconButton onClick={() => viewDoc(row, 'view-only', i)}>
                                            <RemoveRedEye style={{ color: "#FFFFFF" }} />
                                        </IconButton>
                                    </Stack>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer >
            {/* modal code start from here */}
            <Grid  >
                {
                    loading &&
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <CircularProgress color="primary" />
                    </Backdrop>
                }
                {
                    (view && !loading && type && file) && (
                        <Modal
                            className="projectDocumentsModal"
                            open={openModal}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            fullWidth={true}
                        >
                            <Box sx={styleModal}>
                                {/* <iframe
                title="PDF Viewer"
                src={file}
                style={{ width: "100%", height: "100%" }}
                className="pdf-viewer-modal"
              ></iframe> */}
                                <object
                                    data={file}
                                    type="application/pdf"
                                    style={{ width: '100%', height: '100%', borderRadius: '12px' }}
                                >
                                    <p style={{ textAlign: 'center' }}>
                                        Your browser does not support PDFs. <a href={file} target="_blank" style={{ color: 'lightgreen' }}>Download the PDF</a>.
                                    </p>
                                </object>
                            </Box>
                        </Modal>
                    )
                }
            </Grid >
            {/* modal code end from here */}
        </>
    )
}

export default TaxDocumentPanel