import React, { useState, useEffect } from "react";
import { Paper, Stack, Typography, Chip, Checkbox, FormControlLabel, CircularProgress, Box } from "@mui/material";
import { GetApi, postApi, updateApi } from "../../../Api/Api";

const EmailHandler = () => {
    const [checked, setChecked] = useState(false); // Default checked
    const [loading, setLoading] = useState(false);

    /**
     * Function to call api for checking the status for the current email notification handler
     */
    useEffect(() => {
        // Function to check KYC status using an API call
        const checkEmailNotification = async (url) => {
            try {
                let u = localStorage.getItem('user_data')
                u = JSON.parse(u)
                const res = await GetApi(`user/getUser/${u.id}`);
                if (res?.data && res?.data?.data?.is_live_email) {
                    setChecked(true);
                }
            } catch (error) {
                console.log(error)
            }
        }
        checkEmailNotification()
    }, [checked])

    /**
     * Onchange function to handle the email notification 
     * @param {*} event 
     */
    const handleChange = async (event) => {
        const newChecked = event.target.checked;
        setChecked(newChecked);
        setLoading(true);
        let user = JSON.parse(localStorage.getItem('user_data'));
        try {
            await updateApi("/user/updateUser/" + user?.id, {
                is_live_email: newChecked,
            });
        } catch (error) {
            console.error("Error updating notification:", error);
            setChecked(!newChecked); // Revert if API fails
        } finally {
            setLoading(false);
        }
    };

    return (
        <Paper className="rc-basic-container" id="email-notification" sx={{ p: 3, mb: 2 }}>
            {/* Header with Title & Status */}
            <Stack direction={{ xs: "column", md: "row" }} spacing={3} alignItems={"center"} mb={1.5} mt={2}>
                <Typography variant="h6" className="font-16">
                    Email Notifications
                </Typography>
                <Chip
                    sx={{
                        color: "#032744",
                        fontWeight: 500,
                        bgcolor: checked ? "#34D399" : "#ede4c5",
                        borderRadius: "8px",
                        width: { xs: "-webkit-fill-available", md: "auto" },
                    }}
                    label={checked ? "Enabled" : "Disabled"}
                />
            </Stack>

            {/* Description */}
            <Typography className="rc-small-secondary-text">
                Enable this setting to receive email notifications about new investment projects launched on the Race platform.
            </Typography>

            {/* Checkbox */}
            <Box mt={2} mb={2.5}>
                <FormControlLabel
                    control={<Checkbox checked={checked} onChange={handleChange} disabled={loading} />}
                    label={
                        <>
                            Email Notification Enabled {loading && <CircularProgress size={14} sx={{ ml: 1 }} />}
                        </>
                    }
                />
            </Box>
        </Paper>
    );
};

export default EmailHandler;
