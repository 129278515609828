import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";
import { tableCellClasses } from '@mui/material/TableCell';
import { Chip, TableCell, Button, Box, Paper, TableRow, TableHead, TableContainer, Table, TableBody, Typography } from '@mui/material';
import { CATEGORY_TYPE_ID, SUB_CATEGORY_TYPE_ID } from '../../constants.js'
import { postApi } from '../../Api/Api.js';
import TransactionProgressModal from '../../Components/SmartContract/TransactionProgressModal.jsx';
import { formatNumber } from '../../Components/Common/USFormat.js';
import './Transactions.css'

// Styled components for table cells and rows
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#05131B',
        color: '#94A3B8',
        borderBottom: '0px',
        '&:first-of-type': {
            borderRadius: '16px 0px 0px 16px',
        },
        '&:last-of-type': {
            borderRadius: '0px 16px 16px 0px',
        },
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: theme.palette.common.white,
        borderBottom: '0px',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({}));

const EscrowAmountWithdrawalPanel = ({ setLoading }) => {
    const navigate = useNavigate();
    const [offersData, setOffersData] = useState([])
    const [openModalWithdrawEscrow, setOpenModalWithdrawEscrow] = useState(false);
    const [propData, setPropData] = useState();

    const User = JSON.parse(localStorage.getItem('user_data') || '{}');

    /**
        * function to get offers
        */
    const getOffers = async (param) => {
        try {
            setLoading(true)
            let res = await postApi('/proposer/proposerFundWithdrawal', param);
            if (res?.data?.data?.length) {
                setOffersData(res.data.data);
                setLoading(false)
            } else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
        }
    }

    useEffect(() => {
        try {
            if (User.id) {
                getOffers({ investor_id: User.id });
            }
        } catch (error) {
            console.error(error)
        }
    }, [User.id])  // <-- Add User.id and getOffers as dependencies    

    /**
  * Function to handle modal close
  */
    const handleCloseWithdrawAmount = (data) => {
        if (data?.warning) {
            navigate('/user/transactions', { replace: true })
        }
        setOpenModalWithdrawEscrow(false);
    }

    /**
     * Function call after transaction confirm
     */
    const confirmWithdrawAmount = async () => {
        try {
            getOffers({ investor_id: User.id, category_id: CATEGORY_TYPE_ID.FUNDS });
        } catch (error) {

        }
    }

    /**
   * Function call after transaction confirm
   */
    const openFundConfirmWithdrawAmount = async () => {

        try {
            getOffers({ investor_id: User.id, category_id: CATEGORY_TYPE_ID.FUNDS });
        } catch (error) {
            console.error(error)
        }
    }


    /**
     * Function call after transaction confirm
     */
    const handleWithdrawAmount = (row) => {
        setPropData(row);
        setOpenModalWithdrawEscrow(true)
    }

    /**
     * function to handle status
     * @param {Object} row 
     * @returns 
     */
    const getStatusComponent = (row) => {
        return (
            <>
                {!row?.isPendingWithdraw ?
                    <>
                        <Chip label={'Withdrawn'} sx={{ color: '#13202D', bgcolor: '#34D399', borderRadius: '8px' }} />
                    </>
                    :
                    <Button
                        onClick={() => handleWithdrawAmount(row)}
                        className="status-button"
                        disabled={row?.disable_btn ? true : false}
                        sx={{ color: 'gray', bgcolor: 'lightgray', borderRadius: '8px' }} // Updated color and bgcolor to gray
                    >
                        Withdrawal
                    </Button>
                }
            </>
        );
    };

    return (
        <>
            <Box className="filter-panel" mt={4}>
                <Box className="fp-left">
                    <Box className="heading-left">
                        <Typography component="h6">Yield Withdrawal</Typography>
                    </Box>
                </Box>
            </Box>

            <TableContainer component={Paper} className='TableContainer'>
                <Table aria-label="customized table">
                    <TableHead className='TableHeader'>
                        <TableRow>
                            <StyledTableCell width="20%">Project Name</StyledTableCell>
                            <StyledTableCell width="16%" align="left">Fund Type</StyledTableCell>
                            <StyledTableCell width="16%" align="left">Fund Class</StyledTableCell>
                            <StyledTableCell width="16%" align="left">Investment Amount</StyledTableCell>
                            <StyledTableCell width="16%" align="left">Received Amount</StyledTableCell>
                            <StyledTableCell width={'16%'} align="center">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {offersData.map((row, index) => (
                            <StyledTableRow className='TableRow' key={index}>
                                <StyledTableCell component="th" scope="row">
                                    <Link to={`/project-details/${row.asset_id}`}>{row.asset_title}</Link>
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {row?.asset_sub_category_name || '-'}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {row?.class ? `Class ${parseFloat(row.class) === 1 ? 'A' : 'B'}` : '-'}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    ${formatNumber(parseFloat(row.balance_sum) || 0)}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    ${formatNumber(parseFloat(row.return_amount) || 0)}
                                </StyledTableCell>
                                <StyledTableCell align="center">{
                                    getStatusComponent(row)
                                }</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* code for pay remaining payment smart contract modal */}
            {openModalWithdrawEscrow ? <TransactionProgressModal identity={propData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? 'space-x-withdraw-escrow' : 'open-fund-withdraw-escrow'} confirmStake={propData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? confirmWithdrawAmount : openFundConfirmWithdrawAmount} propData={propData} handleModalClose={handleCloseWithdrawAmount} openTransactionModal={openModalWithdrawEscrow} /> : null}
        </>
    );
};

export default EscrowAmountWithdrawalPanel;