import React, { useEffect } from "react";
import { Grid, Box, Typography, Button, Avatar } from "@mui/material";
import { Email } from "@mui/icons-material";
import { robotFaceIcon, handIcon, dollarBagIcon, ProjectProposer, moreBookIcon, moreGraphIcon, atmCardIcon, handShakeIcon, threeDGlobIcon, lowerIcon, easyProcessImg, fractionalizeGoldenIcon, collateralizeGoldenIcon, parcelGoldenIcon, dollarGoldenIcon } from "../../Components/Images/Images";
import ExploreProjects from "../../Components/Common/ExploreProjects";
import GetStarted from "../../Components/Common/GetStarted";
import MoreBenefits from "../../Components/Common/MoreBenefits";
import MultipleFundraising from "../../Components/Common/MultipleFundraising";
import AssetsSliderProjectProposer from "../../Components/Common/AssetsSliderProjectProposer";
import "../Home/Home.css";

const Dashboard = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      {/* Explore Projects Sections */}
      <Box className="home-exploreBanner-section raceCapital-banner" pt={6} pb={8}>
        <Box className="container">
          <ExploreProjects
            title={"Capital Raising, Powered by the"}
            highlightText={"Blockchain"}
            subTitle={`Tokenize your producing asset through the power of the Blockchain, get access to investors around the world through RACE*.`}
            subTitle2={`*RACE is a technology and platform provider and facilitator. Users are responsible for following all regulations`}
            image={ProjectProposer}
          />
        </Box>
      </Box>

      {/* Democratizing Fundraising, Powered by Tech and Crypto & Fiat Friendly section*/}
      <Box className="rc-moreBenefits-section" py={8}>
        <Box className="container">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={2} direction="row">
                <Grid item xs={12} sm={6} md={4}>
                  <MoreBenefits
                    image={handIcon}
                    title={"Democratizing Fundraising"}
                    subtitle={
                      "Ease of investments, deal transparency, transparent ongoing monitoring, reduction of layers and friction costs, reduction of fees, and sheer collaborative digital momentum of moving investments onchain."
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <MoreBenefits
                    image={robotFaceIcon}
                    title={"Powered by Tech"}
                    subtitle={
                      "By utilizing a blend of best in breed technologies, including blockchain, AI, Data Analytics , RACE creates tremendous advantages for holding real estate, while earning cash flow."
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <MoreBenefits
                    image={dollarBagIcon}
                    title={"Crypto & Fiat Friendly"}
                    subtitle={
                      "By accepting both crypto and fiat payments, the RACE platform is increasing access to capital."
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Multiple Fundraising Processes You Can Pick From */}
      <Box className="rc-reachInvestors-section" pt={3.8} pb={8}>
        <Box className="container">
          <Grid container spacing={6} alignItems={'center'}>
            <Grid item xs={12} sm={12} md={6}>
              <Grid container spacing={2} direction="row">
                <Grid item xs={12} sm={6} md={6}>
                  <MultipleFundraising
                    image={<Avatar src={fractionalizeGoldenIcon} alt="Icon" sx={{ width: '48px', height: '48px' }}></Avatar>}
                    title={"Fractionalize"}
                    subtitle={
                      "Split up your asset so multiple investors can purchase a percentage of it."
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <MultipleFundraising
                    image={<Avatar src={collateralizeGoldenIcon} alt="Icon" sx={{ width: '48px', height: '48px' }}></Avatar>}
                    title={"Collateralize"}
                    subtitle={"Provide your asset as collateral for a loan."}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <MultipleFundraising
                    image={<Avatar src={parcelGoldenIcon} alt="Icon" sx={{ width: '48px', height: '48px' }}></Avatar>}
                    title={"Parcel"}
                    subtitle={
                      "Divide your asset so investors can select and purchase a single/multiple unique unit(s)."
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <MultipleFundraising
                    image={<Avatar src={dollarGoldenIcon} alt="Icon" sx={{ width: '48px', height: '48px' }}></Avatar>}
                    title={"Sell"}
                    subtitle={"Sell your entire asset to one single investor."}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box className="section-heading">
                <Typography className="title" variant="h3">Multiple Fundraising Processes You Can Pick From</Typography>
                <Typography className="subtitle">With multiple fundraising options, you have the flexibility to select how you want to list your project.</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box className="rc-myGoodAsset-section" pt={4}>
        <Box className="container">
          <Box className="bg-blue-800" p={8} borderRadius={3}>
            <Box className="section-heading" textAlign={"center"} pb={3} maxWidth={912} margin={'0 auto'}>
              <Typography className="title" variant="h3" textTransform={'capitalize'}>Is my asset a good fit?</Typography>
              <Typography className="subtitle">Our investors seek diversification across industries, stages and technologies, so we currently offer 6 asset types on the platform. If you would like to propose an asset type that’s not offered, feel free to reach out.</Typography>
            </Box>
            <Box className="rc-myGoodAsset-wrap" width={"100%"}>
              <AssetsSliderProjectProposer />
            </Box>
            <Box className='btn-wrap' mt={3} textAlign={'center'}>
              <Button startIcon={<Email />} className="btn-rounded btn-large btn-golden">
                I have an alternative asset{" "}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className="rc-easyProcess-section" py={8}>
        <Box className="container">
          <Box className="section-heading" textAlign={"center"} pb={7} maxWidth={800} margin={'0 auto'}>
            <Typography className="title" variant="h3">Easy Process</Typography>
            <Typography className="subtitle">We created a streamlined, user-friendly process that makes running an investment project easy and affordable.</Typography>
          </Box>
          <Box className="rc-easyProcess-wrap">
            <Avatar variant="square" alt="Image" src={easyProcessImg} sx={{ width: '100%', height: 'auto', maxWidth: '1000px', margin: '0 auto' }}></Avatar>
          </Box>
        </Box>
      </Box>

      {/* more benefits */}
      <Box className="rc-moreBenefits-section" py={8}>
        <Box className="container">
          <Box className="section-heading" textAlign={"center"} mb={4}>
            <Typography className="title" variant="h3">More Benefits</Typography>
          </Box>
          <Box className="rc-moreBenefits-wrap">
            <Grid container spacing={3} direction="row">
              <Grid item xs={12} sm={6} md={4} mb={1}>
                <MoreBenefits
                  image={lowerIcon}
                  title={"Lower transaction fees"}
                  subtitle={
                    "Traditional investment deals often include excessive charges for middlemen fees. We go direct to the investors to cut out those fees and pass on savings to you."
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} mb={1}>
                <MoreBenefits
                  image={threeDGlobIcon}
                  title={"Access to service providers and ecosystem"}
                  subtitle={
                    "RACE’s ecosystem ensures easy access to any services you need for your project on one platform."
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} mb={1}>
                <MoreBenefits
                  image={handShakeIcon}
                  title={"Simple investing process"}
                  subtitle={
                    "RACE ensures investing is fuss-free, accessible and user-friendly. We guide your investors so you can focus on your fundraising process."
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} mb={1}>
                <MoreBenefits
                  image={atmCardIcon}
                  title={"Investment by credit card"}
                  subtitle={
                    "Many investors prefer to invest via cards. RACE supports credit cards, debit cards, ACH and wire transfer."
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} mb={1}>
                <MoreBenefits
                  image={moreGraphIcon}
                  title={"Investor management tools"}
                  subtitle={
                    "Everything you need to manage your investors is included inside RACE’s platform, with the RACE team available to guide you along each and every step."
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} mb={1}>
                <MoreBenefits
                  image={moreBookIcon}
                  title={"Guidance when you need it"}
                  subtitle={
                    "Swift and dedicated customer service and a decentralized investment committee to vett your projects and ensure your success."
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {/* get started (mobile) */}
      <Box className="rc-vergeOfWorld-section" pt={2} pb={6.2}>
        <Box className="container">
          <GetStarted />
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
