import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { Close, Done, EditSharp, SwapVert, ViewComfy, Visibility, Search } from '@mui/icons-material';
import { Button, Box, Paper, TableRow, TableHead, TableContainer, Table, TableBody, Typography, Avatar, Modal, TextField, TableCell, FormControl, RadioGroup, FormControlLabel, Radio, InputBase, InputLabel, Select, MenuItem, ListItemText, ImageListItem } from '@mui/material';
import { GetApi, postApi } from '../../Api/Api';
import { CAPITAL_TYPE_ID, CATEGORY_TYPE_ID, SUB_CATEGORY_TYPE_ID } from '../../constants';
import { useSnackbar } from '../../Contexts/SnackbarContext';
import { NewLogo } from "../../Components/Images/Images";
import TransactionProgressModal from '../../Components/SmartContract/TransactionProgressModal';
import NumericFormatCustomDollar from '../../Components/Common/NumericFormatCustomDollar';
import { formatNumber } from '../../Components/Common/USFormat';
import './Transactions.css'
import PayExitTransactionProgressModal from '../../Components/SmartContract/PayExitTransactionProgressModal';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#05131B',
        color: '#94A3B8',
        borderBottom: '0px',
        '&:first-of-type': {
            borderRadius: '16px 0px 0px 16px',
        },
        '&:last-of-type': {
            borderRadius: '0px 16px 16px 0px',
        },
    },

    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: theme.palette.common.white,
        borderBottom: '0px'
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
}));

/**
 * due status for the due date i.e 
 * ```
 * if the due date is in future then the due status will be future
 * if the due date is near then the due status will be near
 * if the due date is passed then the due status will be past
 * if the payment is done then the due status will be paid
 * ```
    @enum {string}
 */
const DUE_STATUS = {
    FUTURE: 'FUTURE',
    NEAR: 'NEAR',
    PAST: 'PAST',
    PAID: 'PAID'
}

const sortByOptionsOffers = ['Project Name', 'Amount'];

const FundsDistributionPanel = ({ setValue, setLoading }) => {
    const [calculateEmiAmount, setCalculateEmiAmount] = useState([]);
    const [calculateEquityAmount, setCalculateEquityAmount] = useState([]);
    const [loanTableData, setLoanTableData] = useState([])
    const [openMakePayment, setOpenMakePayment] = useState(false)
    const [makePaymentData, setMakePaymentData] = useState('')
    const [searchString, setSearchString] = useState('');
    const [sortBy, setSortBy] = useState('Default');
    const [categoryData, setCategoryData] = useState(); //category data
    const [filterCategoryId, setFilterCategoryId] = useState(0);
    const [loansData, setLoansData] = useState([]);

    let u = localStorage.getItem('user_data');
    let User = JSON.parse(u);

    useEffect(() => {
        /**
        * Api to get Category Type Data
        */
        const getCategoryData = async () => {
            try {
                const res = await GetApi("/proposer/getCategory/");
                setCategoryData(res?.data?.data);
            } catch (error) {
                console.log(error);
            }
        }
        getCategoryData();

        /**
         * Fetch both Fund Distribution and Equity Distribution Data simultaneously
         */
        const fetchDistributionData = async () => {
            try {
                setLoading(true);

                // Fetch both APIs concurrently
                const [fundRes, equityRes] = await Promise.all([
                    postApi('/proposer/getFundDistributionData', { proposer_id: User.id }),
                    postApi('/proposer/getSPVDistributionData', { proposer_id: User.id }),
                ]);

                let fundData = fundRes?.data?.data || [];
                let equityData = equityRes?.data?.data || [];

                // Update states if data is present
                if (fundData.length) setCalculateEmiAmount(fundData);
                if (equityData.length) setCalculateEquityAmount(equityData);

                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };

        // Trigger API calls if User ID is present
        if (User.id) {
            setTimeout(() => {
                fetchDistributionData();
            }, 100);
        }
    }, [User.id]);

    /**
     * Combine and update loan table data whenever either EMI or Equity data changes
     */
    useEffect(() => {
        if (calculateEmiAmount.length || calculateEquityAmount.length) {
            let dataArray = [...calculateEmiAmount, ...calculateEquityAmount];

            // Sort data by asset_id in descending order
            dataArray.sort((a, b) => new Date(b.updated) - new Date(a.updated));
            // Map the data into the required table format
            const table = dataArray.map(details => ({
                project_name: details?.asset_title,
                capital_type_id: CAPITAL_TYPE_ID.EQUITY,
                total_amount: details?.balance_sum,
                ...details,
                listing_id: details?.listingId,
                category_name: details?.category_name,
                category_id: details?.category_id,
                asset_id: details?.asset_id,
                asset_sub_category: details?.asset_sub_category,
                asset_sub_category_name: details?.asset_sub_category_name,
            }));

            setLoanTableData(table);
            setLoansData(table);
        } else {
            setLoanTableData([]);
            setLoansData([]);
        }
    }, [calculateEmiAmount, calculateEquityAmount]);


    /**
     * opens the payment modal for making payment
     * @param {Object}  
     */
    const handleOpenMakePayment = (row, index) => {
        setMakePaymentData(row);
        setOpenMakePayment(true)
    }

    /**
     * closes the payment review modal
     */
    const handleCloseMakePayment = () => {
        setOpenMakePayment(false)
    }

    /**
     * searches in the data [title]
     * @param {string} toSearch 
     * @returns {void}
     */
    const searchInData = (toSearch) => {
        setSearchString(toSearch)
        if (toSearch.length > 2) {
            if (filterCategoryId > 0) {
                setLoanTableData(loansData.filter(o => o.project_name.toLowerCase().includes(toSearch.toLowerCase()) && o.category_id === filterCategoryId))
            } else {
                setLoanTableData(loansData.filter(o => o.project_name.toLowerCase().includes(toSearch.toLowerCase())))
            }
        } else if (toSearch.length <= 2) {
            if (filterCategoryId > 0) {
                // getLoans({ user_id: User.id, category_id: filterCategoryId });
                setLoanTableData(loansData.filter(o => o.category_id === filterCategoryId))
            } else {
                // getLoans({ user_id: User.id });
                setLoanTableData(loansData)
            }
        }
    }

    // Getting filter data on category id change
    useEffect(() => {
        if (filterCategoryId > 0) {
            // getLoans({ user_id: User.id, category_id: filterCategoryId });
            setLoanTableData(loansData.filter(o => o.category_id === filterCategoryId))
        } else {
            // getLoans({ user_id: User.id });
            setLoanTableData(loansData)
        }
    }, [filterCategoryId])

    /**
     * sorting data by amount and project name
     * @param {event} event 
     */
    const handleSortByChange = (event) => {
        setSortBy(event.target.value);
        let value = event.target.value;
        if (value === 'Project Name') {
            setLoanTableData([...loanTableData].sort((a, b) => a.project_name.localeCompare(b.project_name)))
        }
        else if (value === 'Amount') {
            setLoanTableData([...loanTableData].sort((a, b) => parseFloat(a.total_amount) - parseFloat(b.total_amount)))
        }
        else {
            setLoanTableData(loanTableData)
        }
    }

    /**
   * Handle change in category filter
   * @param {Event} event 
   */
    const handleChangeCategory = (event) => {
        setFilterCategoryId(event.target.value)
    };
    return (
        <>

            {/* <Box className="filter-panel" mt={4}>
                <Box className="fp-left">
                    <Box className="heading-left">
                        <Typography component={"h6"}>
                            Funds Received
                        </Typography>
                    </Box>
                </Box>
            </Box> */}

            <Box className="filter-panel" mt={4}>
                <Box className="fp-left">
                    <Box className="heading-left">
                        <Typography component={"h6"}>
                            Funds Received
                        </Typography>
                    </Box>
                </Box>

                <Box className="fp-right">
                    <Box className="filters-group-wrap">
                        <Box className="filters-group-wrap">
                            {/* Search bar filter */}
                            <Box className="filterSearch-form">
                                <Box component={"span"} className="search-icon">
                                    <Search />
                                </Box>
                                <InputBase
                                    // ref={searchBarRef}
                                    className="filterSearch"
                                    placeholder={"Search in loans"}
                                    value={searchString}
                                    onChange={(e) => searchInData(e.target.value)}
                                    inputProps={{ "aria-label": "search in transactions" }}
                                />
                            </Box>
                        </Box>

                        {/* Category filter */}
                        <Box className="select-group" ml={1.5}>
                            <FormControl className="select-rounded" sx={{ minWidth: '150px' }} size="small">
                                <InputLabel id="demo-controlled-open-select-label">
                                    Category
                                    <ViewComfy />
                                </InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    onChange={handleChangeCategory}
                                    value={filterCategoryId}
                                    label="Category"
                                    variant='outlined'
                                >
                                    <MenuItem value={0}>All</MenuItem>
                                    {
                                        categoryData?.length && categoryData?.map((value, ind) => {
                                            return (
                                                <MenuItem key={ind} value={value.id}>
                                                    <ListItemText>{value.title === "Asset" ? "Luxury Cars" : value.title}</ListItemText>
                                                    <Typography className="menu-icon">
                                                        <ImageListItem>
                                                            <img
                                                                src={`${process.env.REACT_APP_IMAGE_URL}${value.category_icon?.path}`}
                                                                srcSet={`${process.env.REACT_APP_IMAGE_URL}${value.category_icon?.path}`}
                                                                alt={value.category_icon?.original_name}
                                                                loading="lazy"
                                                            />
                                                        </ImageListItem>
                                                    </Typography>
                                                </MenuItem>
                                            );
                                        })

                                    }
                                </Select>
                            </FormControl>
                        </Box>


                        {/* Sorting  */}
                        <Box className="select-group" ml={1.5}>
                            <FormControl className="select-rounded" sx={{ minWidth: '150px' }} size="small">
                                <InputLabel id="demo-controlled-open-select-label">
                                    Sort By
                                    <SwapVert />
                                </InputLabel>
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    value={sortBy}
                                    onChange={handleSortByChange}
                                    label="Sort By"
                                    variant='outlined'
                                >
                                    <MenuItem value={'Default'}>Default</MenuItem>
                                    {
                                        sortByOptionsOffers?.map((value, ind) => {
                                            return (
                                                <MenuItem key={ind} value={value}>
                                                    <ListItemText>{value}</ListItemText>
                                                </MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>

                        </Box>
                    </Box>
                </Box>
            </Box>

            <TableContainer component={Paper} className='TableContainer'>
                <Table aria-label="customized table">
                    <TableHead className='TableHeader'>
                        <TableRow>
                            <StyledTableCell>Project Name</StyledTableCell>
                            <StyledTableCell align="left">Asset Type</StyledTableCell>
                            <StyledTableCell align="left">Fund Type</StyledTableCell>
                            <StyledTableCell align="left">Total Amount</StyledTableCell>
                            <StyledTableCell align="left">Distributed Amount</StyledTableCell>
                            {/* <StyledTableCell align="left">Equity</StyledTableCell> */}
                            <StyledTableCell align="left">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loanTableData?.map((row, i) => (
                            <StyledTableRow className='TableRow' key={`${row.name}${i}`}>
                                <StyledTableCell component="th" scope="row"><Link to={`/project-details/${row?.asset_id}`} >{row?.project_name}</Link></StyledTableCell>
                                <StyledTableCell align="left">{row?.category_name}</StyledTableCell>
                                <StyledTableCell align="left">{row?.asset_sub_category_name ? row?.asset_sub_category_name : '-'}</StyledTableCell>
                                <StyledTableCell align="left" className='payment-type-color' >${formatNumber(parseFloat(row?.total_amount))}</StyledTableCell>
                                <StyledTableCell align="left" className='payment-type-color' >${formatNumber(parseFloat(row?.distributed_amount ? row?.distributed_amount : 0))}</StyledTableCell>
                                {/* <StyledTableCell align="left" className='payment-type-color'>{parseFloat((row?.balance_sum ? row?.balance_sum : 0) / (ownership?.total_raise ? ownership?.total_raise : 1) * ownership?.equity).toFixed(2) + '%'}</StyledTableCell> */}
                                <StyledTableCell align="left">
                                    {
                                        row?.dueStatus === DUE_STATUS.PAID ?
                                            <Button onClick={() => { }} startIcon={<Visibility />} className='status-button'>View Transaction</Button>
                                            :
                                            <Button
                                                // disabled={true}
                                                onClick={() => handleOpenMakePayment(row, i)}
                                                disableFocusRipple
                                                disableRipple
                                                startIcon={<EditSharp />} className='status-button'>
                                                Make Payment
                                            </Button>
                                    }
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer >

            {openMakePayment
                ? <MakePayment payEMIData={makePaymentData} openMakePayment={openMakePayment} handleCloseMakePayment={handleCloseMakePayment} setValue={setValue} />
                : null
            }

        </>
    )
}

export default FundsDistributionPanel

function MakePayment({ payEMIData, openMakePayment, handleCloseMakePayment, setValue }) {
    const style = { position: "relative", boxShadow: 24, borderRadius: "24px", width: "1043px", };
    // const rowTextStyles = { color: "burlywood", fontWeight: '500', fontSize: '16px' }

    const [transactionModal, setTransactionModal] = useState({
        open: false,
        title: "Repayment",
        message: `is done successfully, For transaction see ` + <span style={{ textDecoration: 'underline' }}><Link to="/user/transactions" target="_blank" >My Transactions</Link></span> + ` page.`,
    })
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();
    const [openPrepayment, setOpenPrepayment] = useState(false);
    const [openPrepaymentOpenFund, setOpenPrepaymentOpenFund] = useState(false);
    const [openPrepaymentDividend, setOpenPrepaymentDividend] = useState(false);
    const [openPayExitDividend, setOpenPayExitDividend] = useState(false);
    const [propData, setPropData] = useState();
    const [payableAmount, setPayableAmount] = useState(payEMIData?.emi_amount ? payEMIData?.emi_amount : 0);
    const [selectedOption, setSelectedOption] = useState(payEMIData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? 'Distribute Exit Amount' : 'Distribute Amount');
    const [payDividendAmt, setDividendAmt] = useState(0);
    const [selectedCurrency, setCurrencyOption] = useState('USDT');

    /**
     * Handle change in payment type
     * @param {Event} event 
     */
    useEffect(() => {
        if (payDividendAmt) {
            setPayableAmount(parseInt(payDividendAmt));
        } else {
            if (!payDividendAmt) {
                setPayableAmount(0)
            }
        }
    }, [payDividendAmt])

    /**
     * handle pay button
     */
    const handleMakePayment = async () => {
        try {
            if (payEMIData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
                setOpenPrepayment(true);
            } else if (payEMIData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.OPEN_FUNDS) {
                setOpenPrepaymentOpenFund(true)
            } else if (selectedOption === 'Distribute Amount' && payEMIData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                setOpenPrepaymentDividend(true)
            } else if (selectedOption === 'Distribute Exit Amount' && payEMIData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                setOpenPayExitDividend(true)
            }
            setPropData({ ...payEMIData, amount: payableAmount, feeTokenType: selectedCurrency })
        } catch (error) {
            showSnackbar(error?.message, 'error')
        }
    }

    /**
    * Function to handle prepayment modal close
    */
    const handleClosePrepayment = () => {
        setOpenPrepayment(false);
        setOpenPrepaymentOpenFund(false)
        setOpenPrepaymentDividend(false)
        setOpenPayExitDividend(false)
    }

    /**
    * Function call after transaction confirm
    */
    const confirmStake = async () => {
        handleCloseMakePayment();
        setTransactionModal({ ...transactionModal, open: true, title: "Dividend" })
        if (setValue) {
            setValue(0)
        }
        navigate('/user/transactions')
    };

    /**
    * Function call after transaction confirm
    */
    const confirmStakeOpenFund = async () => {
        handleCloseMakePayment();
        setTransactionModal({ ...transactionModal, open: true, title: "Dividend" })
        if (setValue) {
            setValue(0)
        }
        navigate('/user/transactions')
    };

    /**
    * Function call after transaction confirm
    */
    const confirmStakeDividend = async () => {
        handleCloseMakePayment();
        setTransactionModal({ ...transactionModal, open: true, title: "Dividend" })
        if (setValue) {
            setValue(0)
        }
        navigate('/user/transactions')
    };

    /**
* Function call after transaction confirm
*/
    const confirmStakeExitDividend = async () => {
        handleCloseMakePayment();
        setTransactionModal({ ...transactionModal, open: true, title: "Dividend" })
        if (setValue) {
            setValue(0)
        }
        navigate('/user/transactions')
    };
    /**
   * Handle change in payment type
   * @param {Event} event 
   */
    const handleCurrencyChange = (event) => { setCurrencyOption(event.target.value) };
    /**
 * Handle change in payment type
 * @param {Event} event 
 */
    const handleOptionChange = (event) => { setSelectedOption(event.target.value) };

    return (
        <>
            {/* modal for pay emi, prepayment and minimum prepayment */}
            {openMakePayment ?
                <Modal
                    open={openMakePayment}
                    onClose={handleCloseMakePayment}
                    className="kyc-modal"
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Box className="modal-body" sx={style}>
                        <Box className="headContent">
                            <Box className="logo">
                                <Avatar
                                    alt="Logo"
                                    src={NewLogo}
                                    variant="square"
                                    sx={{ width: "100%", height: 90, objectFit: "contain" }}
                                />
                            </Box>
                        </Box>
                        <Box className="modalContentDone" >
                            {payEMIData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE ?
                                <Box className="options-container" my={2} px={4}>
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            aria-label="payment-method"
                                            name="payment-method"
                                            defaultValue="Distribute Amount"
                                            row
                                            value={selectedOption}
                                            onChange={handleOptionChange}
                                        >
                                            <FormControlLabel
                                                value="Distribute Amount"
                                                control={<Radio />}
                                                label="Distribute Amount"
                                            />
                                            <FormControlLabel
                                                value="Distribute Exit Amount"
                                                control={<Radio />}
                                                label="Distribute Exit Amount"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                                : null
                            }
                            <Box className="main-content loan " >
                                <Box className="votingContent">
                                    {payEMIData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE ? null :
                                        <Typography component="h1" mt={2} px={4} className="headText" textAlign={"center"}>{selectedOption}</Typography >
                                    }

                                    <Box className='minimum-prepayment'>
                                        <Typography>Enter Amount: </Typography>
                                        <TextField
                                            className='minimum-prepayment-text'
                                            style={{ width: '200px' }}
                                            placeholder='Enter Amount'
                                            onChange={(e) => setDividendAmt(parseInt(e.target.value))}
                                            InputProps={{
                                                inputComponent: NumericFormatCustomDollar,
                                                style: {
                                                    color: 'white'
                                                }
                                            }}
                                            value={payDividendAmt}
                                            onKeyDown={(event) => {
                                                if (event?.key === "-" || event?.key === "+" || event?.key === ".") {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />

                                    </Box>

                                    <Box className='currency-align'>
                                        <Box className='investor-select-currency-type'>
                                            <Typography className='pay-using font-14'>
                                                Pay using
                                            </Typography>
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    aria-label="payment-method"
                                                    name="payment-method"
                                                    defaultValue="USDT"
                                                    row
                                                    className='currency-options'
                                                    value={selectedCurrency}
                                                    onChange={handleCurrencyChange}
                                                >
                                                    {/* <FormControlLabel
                                                        value="RACE"
                                                        control={<Radio />}
                                                        label="RACE Token"
                                                    /> */}
                                                    <FormControlLabel
                                                        value="USDT"
                                                        control={<Radio />}
                                                        label="USDT"
                                                    />
                                                    <FormControlLabel
                                                        value="USDC"
                                                        control={<Radio />}
                                                        label="USDC"
                                                    />
                                                    <FormControlLabel
                                                        value="DAI"
                                                        control={<Radio />}
                                                        label="DAI"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Box>
                                    </Box>

                                    <Box className='vote-btn-box' mt={2}>
                                        <Button className="wallet-button appr-btn" disabled={payableAmount && parseInt(payableAmount) > 0 ? false : true} onClick={() => handleMakePayment(payableAmount)}> <Box className="btn-icon" ><Done />Pay ${payableAmount}</Box></Button>
                                        <Button className="wallet-button rjct-btn" onClick={handleCloseMakePayment}><Box className="btn-icon" ><Close />Cancel</Box></Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
                : null}

            {/* Transaction successful status modal */}
            {transactionModal.open ?
                <Modal
                    open={transactionModal.open}
                    onClose={() => { setTransactionModal({ ...transactionModal, open: false }) }}
                    className="kyc-modal"
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Box className="modal-body" sx={style}>
                        <Box className="headContent">
                            <Box className="logo">
                                <Avatar
                                    alt="Logo"
                                    src={NewLogo}
                                    variant="square"
                                    sx={{ width: 39, height: 90, objectFit: "contain" }}
                                />
                            </Box>
                            <Close className="close-btn" onClick={() => { setTransactionModal({ ...transactionModal, open: false }) }} />
                        </Box>
                        <Box className="modalContentDone" >
                            <Box className="main-content" >
                                <Box className="DoneIcon"><Done className="doneIconSvg" /></Box>
                                <Typography component="h1" className="headText">Payment Successful</Typography >
                                <Typography component="p" className="sub-headText">{transactionModal?.title} {transactionModal?.message}</Typography >
                                <Button onClick={() => { navigate('/user/transactions'); setTransactionModal({ ...transactionModal, open: false }); }} className="wallet-button">Return to Dashboard</Button>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
                : null}

            {openPrepayment
                ? <TransactionProgressModal identity={'space-x-funds-distribution'} confirmStake={confirmStake} propData={propData} handleModalClose={handleClosePrepayment} openTransactionModal={openPrepayment} />
                : null
            }
            {openPrepaymentOpenFund
                ? <TransactionProgressModal identity={'open-funds-distribution'} confirmStake={confirmStakeOpenFund} propData={propData} handleModalClose={handleClosePrepayment} openTransactionModal={openPrepaymentOpenFund} />
                : null
            }
            {openPrepaymentDividend
                ? <TransactionProgressModal identity={'pay-dividend'} confirmStake={confirmStakeDividend} propData={propData} handleModalClose={handleClosePrepayment} openTransactionModal={openPrepaymentDividend} />
                : null
            }
            {openPayExitDividend
                ? <PayExitTransactionProgressModal identity={'pay-exit-amount'} confirmStake={confirmStakeExitDividend} propData={propData} handleModalClose={handleClosePrepayment} openTransactionModal={openPayExitDividend} />
                : null
            }
        </>
    )

}