import React, { useCallback, useState, useEffect } from "react";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import { Box, Tab, Tabs, Grid, Typography, InputBase, Button, Modal, Stack, Avatar, InputLabel, MenuItem, FormControl, Select, IconButton, FormHelperText } from "@mui/material";
// import custom components
import PendingDocumentsPanel from "./PendingDocumentsPanel";
import TaxDocumentPanel from "./TaxDocumentPanel";
import ReportsPanel from "./ReportsPanel";
import "../Transactions/Transactions.css";
import { Close, Search } from "@mui/icons-material";
import CommonBackdropLoader from "../../Components/Common/CommonBackdropLoader";

import { useForm, Controller } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import Upload from "@mui/icons-material/Upload";
import Cancel from "@mui/icons-material/Cancel";
import { PROJECT_STATUS } from "../../constants";
import { GetApiParam, postApi } from "../../Api/Api";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import { NewLogo } from "../../Components/Images/Images";

/**
 * TabPanel functional component that conditionally renders its children based on the active tab.
 * @param {Object} props - The properties passed to the component.
 * @param {ReactNode} props.children - The content to be rendered within the TabPanel.
 * @param {number} props.value - The current active tab index.
 * @param {number} props.index - The index of the specific TabPanel.
 * @param {Object} other - Additional properties spread onto the component.
 * @returns {JSX.Element} - The rendered TabPanel component.
 */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

/**
 * Helper function to generate accessibility properties for tabs.
 * @param {number} index - The index of the tab.
 * @returns {Object} - Accessibility properties for the tab.
 */
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function Documents() {
  // Importing 'useTheme' hook from the Material-UI library
  const theme = useTheme();
  const { showSnackbar } = useSnackbar();
  // State to manage the current tab
  const [value, setValue] = useState(0);
  const [searchString, setSearchString] = useState('');
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [selectedDocType, setSelectedDocType] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [isUpdatedList, setIsUpdatedList] = useState(false);

  // const localData =
  const localData = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : ""; //Getting  user data from local storage

  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ProjectName: "",
    },
  });

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%", // Adjust as needed
    maxWidth: "500px", // Optional: limit max width
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2, // Optional: rounded corners
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getAssetData()
    }, 100);
    return () => clearTimeout(timer);
  }, [loading])


  const getAssetData = async () => {
    try {
      const data = {
        is_onboard: 1,
        is_publish: 1,
        user_id: localData.id
      };
      const res = await GetApiParam("/proposer/getAssetsList", data);
      setAssetData(res.data?.data);
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Function to handle changes in the tab, triggered by the TabPanel
   * @param {Number} newValue - index value of tab panel
   */
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /**
   * Function to handle changes in the index, used for tab navigation
   * @param {Number} index
   */
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  /**
   * Function to handle changes in the loader
   * @param {*} data 
   */
  const handleLoading = (data) => {
    setLoading(data)
  };

  /**
   * Function to handle changes in the Tax panel loading.
   * @param {*} data 
   */
  const handleLoadingTexPanel = (data) => {
    setLoading(data)
  }

  /**
   * FUnction to handle the loading on report panel
   * @param {*} data 
   */
  const handleLoadingReportPanel = (data) => {
    setLoading(data)
  }


  /**
   * Onclick Function for the Upload tax document 
   */
  const handleTexDocument = () => {
    setOpenModal(true)
    setSelectedDocType("text_Document")
  }

  /**
   * Onclick Function for the Upload Reports.
   */
  const handleReports = () => {
    setOpenModal(true)
    setSelectedDocType("reports")
  }


  /**
   * HandleClose Function for the Modal close
   */
  const handleCloseModal = () => {
    setOpenModal(false); // Close the modal
    setUploadedDocs([]); // Clear uploaded documents
    setSelectedDocType(""); // Clear selected document type
    reset({ ProjectName: "" }); // Reset form fields
    setValidationErrors({}); // Clear all validation errors
  }

  /**
  * Callback function triggered when files are dropped
  */
  const onDrop = useCallback(
    (acceptedFiles) => {
      // Filter files based on size (maximum size: 12 MB)
      const acceptedFiles1 = acceptedFiles?.filter((images) => {
        return images.size <= 12000000;
      });
      // Display a warning if some files exceed the size limit
      if (acceptedFiles1.length != acceptedFiles.length) {
        showSnackbar(
          "Please upload an pdf with a maximum size of 12 MB.",
          "error"
        );
      }
      // Update the state with the filtered files
      let dataDetail = [...uploadedDocs, ...acceptedFiles1];
      setUploadedDocs(dataDetail);
    },
    [uploadedDocs]
  );


  const { acceptedFiles, getRootProps, open, getInputProps } = useDropzone({
    // maxFiles: 3,
    noClick: true,
    noKeyboard: true,
    accept: {
      "application/pdf": [".pdf"],
    },
    uploadMultiple: true,
    onDrop,
  });


  /**
  * Handle delete documents
  * @param {Object} file - documents file
  * @param {Integer} index - index value of uploadedDoc array
  */
  const handleDeleteDocument = (file, index) => {
    const newUploadedImages = uploadedDocs.filter(
      (image, ind) => ind !== index
    );
    setUploadedDocs(newUploadedImages);
  };

  const files = uploadedDocs.map((file, index) => (
    <Box key={index} className="showUploadedDocuments" display="flex" alignItems="center" mt={1}>
      <Typography variant="body2" className="fileName" sx={{ marginRight: 2 }}>
        {file.path}
      </Typography>
      <Cancel
        onClick={() => handleDeleteDocument(file, index)}
        style={{ cursor: "pointer", color: "red" }}
      />
    </Box>
  ));


  /**
   * Onsubmit function
   * @param {*} data 
   * @returns 
   */
  const onSubmitExtraInfo = async (data) => {
    const errors = {}; // To track validation errors

    // Validation for Project Name
    if (!data.ProjectName) {
      errors.ProjectName = "Please select a project name.";
    }

    // Validation for Uploaded Documents
    if (uploadedDocs.length === 0) {
      errors.uploadedDocs = "Please upload at least one document.";
    }

    // If there are errors, set them to the state and exit
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    setOpenModal(false); // Close modal on successful submission

    // setLoading(true)
    if (uploadedDocs.length === 0) {
      showSnackbar("Please upload document.", "error");
      return;
    }

    if (data && uploadedDocs.length > 0) {
      let formData = new FormData();

      formData.append("asset_id", data.ProjectName);
      formData.append("user_id", localData?.id);
      // formData.append("type", selectedDocType);

      // Append documents here
      uploadedDocs?.forEach((val) => {
        formData.append(selectedDocType, val);
      });

      try {
        setLoading(true)
        const res = await postApi("/proposer/assetResources/", formData);
        if (res.data.code === 200) {
          setLoading(false)
          setUploadedDocs([]); // Clear uploaded documents
          setSelectedDocType(""); // Clear selected document type
          reset({ ProjectName: "" }); // Reset project name using `useForm`'s reset method
          setIsUpdatedList(item => !item)
        }
        if (res?.data.code === 201) {
          showSnackbar(res?.data?.error, "error");
          setLoading(false)
        }
      } catch (error) {
        setLoading(false)
        console.log(error);
      }
    }
    return;

  };


  return (
    <>
      <Box className="document-main-div mydocumentmain">
        <Grid className="transactions-list-head" item xs={10} sm={10} md={10}>
          <Box className="my-document-container">
            <Box className="heading-left">
              <Typography component={"h6"} ml={2} >
                My Documents
              </Typography>
            </Box>
            <Box className="filters-group-wrap">
              <Box className="button-group" sx={{ display: 'flex', gap: 2, marginBottom: 2, marginRight: 2 }}>
                {value == "1" && <Button variant="contained" className="btn-rounded btn-large btn-text-white btn-blue-600" onClick={() => handleTexDocument()}>
                  Upload Tax Document
                </Button>}
                {value == "2" && <Button variant="contained" className="btn-rounded btn-large btn-text-white btn-blue-600" onClick={() => handleReports()}>
                  Upload Report
                </Button>}
              </Box>
              <Box className="filterSearch-form">
                <Box component={"span"} className="search-icon">
                  <Search />
                </Box>
                <InputBase
                  // ref={searchBarRef}
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                  className="filterSearch"
                  placeholder={"Search documents"}
                  inputProps={{ "aria-label": "search in transactions" }}
                />
              </Box>
            </Box>

          </Box>
          <Box className="transactions-list-head-inside">
            <Box className="tab-div">
              <Tabs
                onChange={handleChange}
                value={value}
                aria-label="Tabs where each tab needs to be selected manually"
                sx={{ overflowX: "auto" }}
              >
                <Tab
                  label="Completed Documents"
                  className={
                    value === 0
                      ? "tabs transactions-active-button"
                      : " tabs transactions-inactive-button"
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  label="Tax Documents"
                  className={
                    value === 1
                      ? "tabs transactions-active-button"
                      : " tabs transactions-inactive-button"
                  }
                  {...a11yProps(1)}
                />
                <Tab
                  label="Reports"
                  className={
                    value === 2
                      ? "tabs transactions-active-button"
                      : " tabs transactions-inactive-button"
                  }
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0}>
                <PendingDocumentsPanel toSearch={searchString} handleLoading={handleLoading} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <TaxDocumentPanel toSearch={searchString} handleLoadingTexPanel={handleLoadingTexPanel} assetData={assetData} isUpdatedList={isUpdatedList} />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <ReportsPanel toSearch={searchString} handleLoadingReportPanel={handleLoadingReportPanel} isUpdatedList={isUpdatedList} />
              </TabPanel>
            </SwipeableViews>
          </Box>
        </Grid>
        <Box>
          <Modal
            className="tax-report-documents-modal"
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            fullWidth
          >
            <Box sx={styleModal}>
              {/* Header Section */}
              <Box className="headContent" sx={{ position: "relative", mb: 2 }}>
                <Box className="logo">
                  <Avatar
                    alt="Logo"
                    src={NewLogo}
                    variant="square"
                    sx={{ width: 39, height: 90, objectFit: "contain" }}
                  />
                </Box>

                {/* Close Button */}
                <IconButton
                  onClick={handleCloseModal}
                  sx={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    color: "white",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    '&:hover': {
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                    },
                  }}
                >
                  <Close className="close-btn" />
                </IconButton>
              </Box>

              {/* Title */}
              <Typography variant="h6" id="modal-title" mt={4} mb={2}>
                {selectedDocType === "text_Document" ? "Upload Tax Document" : "Upload Report"}
              </Typography>

              {/* Form */}
              <form onSubmit={handleSubmit(onSubmitExtraInfo)}>
                {/* Dropdown for Project Name */}
                <FormControl fullWidth margin="normal" error={!!validationErrors.ProjectName}>
                  <InputLabel id="project-name-label">Select Project Name</InputLabel>
                  <Controller
                    name="ProjectName"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="project-name-label"
                        label="Select Project Name"
                        className="tax-report-doc-select"
                        onChange={(event) => {
                          field.onChange(event);
                          setValidationErrors((prev) => ({
                            ...prev,
                            ProjectName: "", // Clear the error for ProjectName
                          }));
                        }}
                      >
                        <MenuItem value="" >Select Project Name</MenuItem>
                        {assetData?.length > 0 &&
                          assetData.map((val, ind) => (
                            <MenuItem key={ind} value={val.id}>
                              {val.asset_title}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                  <FormHelperText>{validationErrors.ProjectName}</FormHelperText>
                </FormControl>

                {/* File Upload */}
                <Box className="kyc-uploadDoc" mt={2}>
                  <Box
                    {...getRootProps({
                      className: "dropzone",
                    })}
                    sx={{
                      border: "2px dashed #ccc",
                      padding: 4,
                      textAlign: "center",
                      cursor: "pointer",
                      borderRadius: "8px",
                    }}
                  >
                    <input {...getInputProps()} onChange={(event) => {
                      getInputProps().onChange(event); // Original dropzone handler
                      setValidationErrors((prev) => ({
                        ...prev,
                        uploadedDocs: "", // Clear the error for uploadedDocs
                      }));
                    }} />
                    <Stack direction="row" alignItems="center" justifyContent="center">
                      {/* <Upload sx={{ fontSize: 40, color: "gray" }} /> */}
                      <Box ml={2}>
                        <Typography variant="body1">Drag & drop files here</Typography>
                        <Typography variant="body2">or click the button below</Typography>
                      </Box>
                    </Stack>
                  </Box>
                  <FormHelperText>{validationErrors.uploadedDocs}</FormHelperText>
                  <Button variant="contained" className="btn-rounded btn-large btn-text-white btn-blue-600" onClick={open} sx={{ mt: 2 }}>
                    Browse Files
                  </Button>
                </Box>

                {/* Display uploaded files */}
                <Box mt={2}>{files}</Box>

                {/* Submit Button */}
                <Box mt={4} textAlign="right">
                  <Button variant="contained" className="btn-rounded btn-large btn-text-white btn-blue-600" type="submit">
                    Submit
                  </Button>
                </Box>
              </form>
            </Box>
          </Modal>
        </Box>


        {/* Loader */}
        {loading ? <CommonBackdropLoader loading={loading} /> : null}
      </Box>
    </>
  );
}
