import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';

export default function HurdleRateDetails({ assetData }) {
    const termsList = assetData?.is_hurdle_rate && assetData?.spv_rate_of_return ? JSON.parse(assetData?.spv_rate_of_return) : [];
    const spv_partners = assetData?.is_hurdle_rate && assetData?.spv_partners ? JSON.parse(assetData?.spv_partners) : [];

    return (
        <Box className="pd-sidebar-dealTerms" id="scroll-to-dealterm">
            <Grid container spacing={2} className="pd-sidebar-heading" justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography className="title" component={'h4'}>Hurdle Rate</Typography>
                </Grid>
            </Grid>

            <Box className="pd-sidebar-detailsList">
                {
                    termsList && termsList?.length > 0 ?
                        <Box className='detailsList'>
                            <Grid container spacing={1}>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Typography className='title'>IRR Percentage</Typography>
                                </Grid>
                                <Grid item xs={3} sm={3} md={3}>
                                    <Typography className='title'>LP %</Typography>
                                </Grid>
                                <Grid item xs={3} sm={3} md={3}>
                                    <Typography className='title'>GP %</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Divider style={{ marginBottom: '8px' }} />
                                </Grid>
                            </Grid>
                            {
                                termsList.map((item, index) => (
                                    <Grid container spacing={1} key={index}>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography className=''>{item?.min}%-{item?.max}%</Typography>
                                        </Grid>
                                        <Grid item xs={3} sm={3} md={3}>
                                            <Typography className=''>{spv_partners[index]?.lp}%</Typography>
                                        </Grid>
                                        <Grid item xs={3} sm={3} md={3}>
                                            <Typography className=''>{spv_partners[index]?.gp}%</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Divider style={{ marginBottom: '8px' }} />
                                        </Grid>
                                    </Grid>
                                ))
                            }
                            {/* <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography className='font-15' style={{ fontWeight: '500', marginTop: '8px' }}>After Exit</Typography>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Typography className='title'>Carried Interest</Typography>
                                </Grid>
                                <Grid item xs={3} sm={3} md={3}>
                                    <Typography className='title'>LP %</Typography>
                                </Grid>
                                <Grid item xs={3} sm={3} md={3}>
                                    <Typography className='title'>GP %</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Typography className='title'>{parseFloat(assetData?.carried_interest)}%</Typography>
                                </Grid>
                                <Grid item xs={3} sm={3} md={3}>
                                    <Typography className='title'>{100 - parseFloat(assetData?.spv_lp_percentage)}%</Typography>
                                </Grid>
                                <Grid item xs={3} sm={3} md={3}>
                                    <Typography className='title'>{parseFloat(assetData?.spv_lp_percentage)}%</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Divider style={{ marginBottom: '8px' }} />
                                </Grid>
                            </Grid> */}
                        </Box>
                        :
                        <>
                            <Typography>No hurdle rate is available.</Typography>
                            <Divider style={{ marginTop: '8px' }} />
                        </>
                }
            </Box>
        </Box >
    );
}
