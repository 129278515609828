import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info';
import RaceProjectCard from '../ClientDashboard/ServiceProviderProfilePage/RaceProjectCard/RaceProjectCard';
import "./ViewSingleRequest.css"
import ListCard from '../Components/ListCard/ListCard';
import { Assignment } from '@mui/icons-material';

function RightSideRaceSection({ raceProject , raceActivity, documents}) {

  return (
    <Box p={3} className="race-activity-container">
      <Section title={'Race Activity'} icon={true}>
        <Stack direction="row" justifyContent={'space-between'} mb={2} >
          <Typography variant="subtitle2" className="font-12" >Total spent</Typography>
          <Typography variant="subtitle1" className="font-12 text-blueGray-500" >$ {raceActivity?.totalSpent}</Typography>
        </Stack>
        <Stack direction="row" justifyContent={'space-between'} mb={2} >
          <Typography variant="subtitle2" className="font-12" >Jobs Posted</Typography>
          <Typography variant="subtitle1" className="font-12 text-blueGray-500" >{raceActivity?.jobsPosted}</Typography>
        </Stack>
        <Stack direction="row" justifyContent={'space-between'} mb={2} >
          <Typography variant="subtitle2" className="font-12" >Hire Rate</Typography>
          <Typography variant="subtitle1" className="font-12 text-blueGray-500" >{raceActivity?.hireRate}%</Typography>
        </Stack>
        <Stack direction="row" justifyContent={'space-between'} mb={2} >
          <Typography variant="subtitle2" className="font-12" >Member since</Typography>
          <Typography variant="subtitle1" className="font-12 text-blueGray-500" >{raceActivity?.memberSince}</Typography>
        </Stack>
      </Section>
      <Typography variant="subtitle1" fontWeight={'500'} className='font-16' mb={2}>Race Project</Typography>
      <Box pb={2} >
        {raceProject ? <RaceProjectCard project={raceProject} /> :<Typography variant="subtitle1" className="font-12 text-blueGray-500">Not available</Typography>}
      </Box>
      <Box className="action-div">
        <Typography className="font-16">Documents</Typography>
      </Box>
      <Box pb={2}>
        {documents?.length > 0 ? documents?.map((val, ind) => {
          return <ListCard key={ind} listIcon={<Assignment />} bgColor={'#fff'} iconColor="#398CD1" type={'icon'} title={val?.original_name} subtitle1={val?.title} subtitle1Color='#398CD1' fileVal={val}/>
        })
        : "No Documents Available"
        }
      </Box>
    </Box>
  )
}

export default RightSideRaceSection

function Section({ children, title, icon }) {
  return (
    <Box>
      <Stack direction="row" justifyContent={'space-between'}>
        <Typography variant="subtitle1" fontWeight={'500'} className='font-16' mb={2}>{title}</Typography>
        {icon ? <InfoIcon className='info-icon' /> : null}
      </Stack>
      <Box p={2}>
        {children}
      </Box>
    </Box>
  )
}
