import React, { useState } from 'react';
import { Box, Tab, Tabs, Grid } from '@mui/material';
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles"
import SwipeableViews from "react-swipeable-views"
import OffersPanel from './OffersPanel.jsx';
import TransactionsPanel from './TransactionsPanel.jsx';
import FundsPanel from './FundsPanel.jsx';
import LoanFunded from './LoanFunded.jsx';
import FundWithdrawal from './FundWithdrawal.jsx';
import CommonBackdropLoader from '../../Components/Common/CommonBackdropLoader.jsx';
import './Transactions.css';
import EscrowAmountWithdrawalPanel from './EscrowAmountWithdrawalPanel.jsx';

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </div >
  )
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  }
}

export default function TransactionsPage() {

  // state to handle the tabs value
  const theme = useTheme()
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const tabId = location?.state?.tabId;
  const [value, setValue] = useState(tabId ? tabId : 0);


  // useEffect(() => {
  //   // Check if state.from is "notification"
  //   if (state && state.from === "notification") {
  //     // Set the tab to "My Offers" (index 1)
  //     setValue(1);
  //   }
  // }, [state]);

  /**
   * Handle the change in tabs
   * @param {*} event 
   * @param {*} newValue 
   */
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /**
   * Handle the change in index
   * @param {*} index 
   */
  const handleChangeIndex = index => {
    setValue(index)
  }

  const handleLoading = (data) => {
    setLoading(data)
  };

  return (
    <>
      <Grid className='transactions-list-head investor-transaction-list-head'>
        <Box className='transactions-list-head-inside'>
          <Box>
            <Tabs
              onChange={handleChange}
              value={value}
              aria-label="Tabs where each tab needs to be selected manually"
            >
              <Tab label="My Transactions" className={value === 0 ? 'tabs transactions-active-button' : ' tabs transactions-inactive-button'}  {...a11yProps(0)} />
              <Tab label="My Offers" className={value === 1 ? 'tabs transactions-active-button' : ' tabs transactions-inactive-button'}  {...a11yProps(1)} />
              <Tab label="My Investment" className={value === 2 ? 'tabs transactions-active-button' : ' tabs transactions-inactive-button'}  {...a11yProps(2)} />
              <Tab label="Loans Funded" className={value === 3 ? 'tabs transactions-active-button' : ' tabs transactions-inactive-button'}  {...a11yProps(3)} />
              <Tab label="Yield Withdrawal" className={value === 4 ? 'tabs transactions-active-button' : ' tabs transactions-inactive-button'}  {...a11yProps(4)} />
              {/* <Tab label="Withdraw Amount" className={value === 5 ? 'tabs transactions-active-button' : ' tabs transactions-inactive-button'}  {...a11yProps(5)} /> */}
            </Tabs>
          </Box>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} >
              <TransactionsPanel comingFrom={'investor'} setLoading={handleLoading} />
            </TabPanel>
            <TabPanel value={value} index={1} >
              <OffersPanel comingFrom={'investor'} setLoading={handleLoading} />
            </TabPanel>
            <TabPanel value={value} index={2} >
              <FundsPanel comingFrom={'investor'} setLoading={handleLoading} />
            </TabPanel>
            <TabPanel value={value} index={3} >
              <LoanFunded setLoading={handleLoading} />
            </TabPanel>
            <TabPanel value={value} index={4} >
              <FundWithdrawal setLoading={handleLoading} />
            </TabPanel>
            {/* <TabPanel value={value} index={5} >
              <EscrowAmountWithdrawalPanel setLoading={handleLoading} />
            </TabPanel> */}
          </SwipeableViews>
        </Box>

        {/* Loader */}
        {loading ? <CommonBackdropLoader loading={loading} /> : null}
      </Grid>
    </>
  );
}